@import 'src/styles/v2/all.scss';

.containerTimeSlot {
  display: flex;
  flex-wrap: wrap;
  margin-top: $gap--lg;
}

.title {
  font-size: $font-sz--sm;
  margin-top: $gap--lg;
  text-align: center;
  display: block;
}
