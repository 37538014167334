@import 'src/styles/modules/base.scss';

.component {
  font-size: 14px;
  position: fixed;
  z-index: 1;
  top: 150px;
  left: 0;
  width: 100%;
  padding: 12px;
  color: $white;
  background-color: $green-cookie;

  .inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 670px;
    margin: 0 auto;
    color: #f8f8f8;
  }

  .text {
    margin-bottom: 10px;
  }

  .button {
    font-family: 'ProximaNova-sb';
    font-size: 13px;
    min-width: 110px;
    margin: 4px 8px;
    padding: 6px;
    color: $dark-gray;
    border: none;
    border-radius: 3px;
    background-color: $white;
  }

  .link {
    font-family: 'ProximaNova-sb';
    font-weight: bold;
    color: white;
  }
}
