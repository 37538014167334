@import 'src/styles/v2/all.scss';

$bottom-padding: $footer--h + $gap--l2;

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--l3 $gap--md $bottom-padding;
  }

  .statusIcon {
    @include square($icon-sz--l3);
    background: $pink-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border--r--mx;
    margin: $gap--xl auto $gap--lg;
  }

  .title {
    color: $c-page--slick--fg;
    font-size: $font-sz--l4;
    font-family: $font-family--bold;
    text-align: center;
  }

  .subTitle {
    display: flex;
    align-items: center;
    margin-top: $gap--lg;
    margin-bottom: $gap--md;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .icon {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/calendar-dark.svg');
    background-repeat: no-repeat;
  }

  .small {
    font-size: $font-sz--sm;
    text-align: center;
    margin: $gap--md auto 0;
    max-width: 80%;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .title,
    .subtitle {
      color: $c-page--dark--fg;
    }

    .icon,
    .statusIcon img {
      filter: brightness(10);
    }

    .statusIcon {
      background: $purple;
    }
  }

  &.neutral {
    .statusIcon {
      background: $silver;
      img {
        filter: brightness(0.7);
      }
    }
  }
}
