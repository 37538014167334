@import 'src/styles/v2/all.scss';

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $gap--sm; // $gap--md
  flex-basis: calc(25% - #{$gap--md});
  border: $border--q--xs solid $c-item--slick--bd;
  background-color: $c-item--slick--bg;
  border-radius: $border--r--xl;
  padding: $gap--md;
  font-family: $font-family--semi-bold;

  &.selected {
    border: $border--q--sm solid $c-item--slick--selected--bd;
  }

  @include breakpoint(mobile) {
    flex-basis: calc(50% - #{$gap--md});
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: $font-sz--md;
    margin: $gap--s2 0;
    text-transform: lowercase;
  }

  .subTitle {
    color: $silver-primary;
    font-size: $font-sz--sm;
    margin: $gap--s2 0;
  }

  &.neutral {
    &.selected {
      border-color: $c-item--neutral--selected--bd;
    }
  }
  &.dark {
    background-color: $c-item--dark--bg;
    &.selected {
      border-color: $c-item--dark--selected--bd;
    }

    .title {
      color: $c-item--dark--fg;
    }
  }
}
