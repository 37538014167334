@import '../mixin/breakpoints.scss';
@import '../modules/base.scss';

.neutral {
  .form-input input,
  .search-input {
    border-color: $page-title;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    &:focus {
      border-color: $page-title;
    }
  }
  .extra-title,
  .link-more .show-more-text,
  .app-content.color-title .app-header .header-title,
  .divider,
  .form-input.password-field .show-hide,
  .form-input.password-field .password-link,
  .sms-password-message,
  .calendar-slider .slick-item::before,
  .note,
  .header-title-new-line,
  .breadcrumb,
  .header-breadcrumb,
  .pay-total,
  .area-note {
    color: $page-title;
  }
  .item-description {
    &.open {
      & .note-text {
        &:after {
          color: $page-title;
        }
      }
    }
  }
  .calendar-slider .slick-slide .activeClass .cyrcle {
    background-color: $page-title;
  }
  .calendar-slider .slick-item.disabled::before {
    color: #f73746;
  }
  // loading
  .load-spinner {
    border-left: 4px solid $page-title;
    border-right: 4px solid $page-title;
    border-top: 4px solid $page-title;
  }
  // buttons
  .btn.btn-primary,
  .btn.btn-border,
  .btn-search {
    background-color: $page-title;
    border-color: $page-title;
    color: $white;
  }
  .show-all-availability,
  .put-on-wait-list {
    .btn.btn-primary {
      background-color: $white;
      border: 2px solid $page-title;
      color: $page-title;
    }
  }
  #cardUpdateBtn {
    background-color: transparent;
    border-color: transparent;
    color: $page-title;
  }
  .divider:before {
    content: '';
    background-color: $page-title;
  }
  .banner.upcoming,
  .banner.history,
  .group-shape.select-category .count-selected-services {
    background-color: $page-title;
    color: $white;
  }

  .app-header .header-notes {
    color: #979797;
  }
  .app-header .header-notes a {
    color: blue;
  }
  // checkbox,
  input[type='checkbox']:checked + label span {
    filter: brightness(0.3) sepia(1) hue-rotate(152deg) saturate(2); // ~== #192340
  }
  input.checkbox-switch[type='checkbox']:checked + label span,
  input.checkbox-switch[type='radio']:checked + label span {
    border-bottom: 2px solid #192340;
    filter: brightness(0.3) sepia(1) hue-rotate(152deg) saturate(2); // ~== #192340
  }
  .simple-button,
  form .simple-button {
    border-color: $page-title;
    background-color: $page-title;
  }
  // button add-to-calendar
  .app-content .react-add-to-calendar__button {
    border: 2px solid $brand-blue !important;
    color: $brand-blue;
    background-color: $white !important;
    box-shadow: 0 1px 4px 0 rgba(83, 83, 83, 0.6);
    line-height: 38px;
  }
  .book-again {
    color: $white;
    background-color: $brand-blue !important;
    box-shadow: 0 1px 4px 0 rgba(83, 83, 83, 0.6);
  }
  .arr-icon img {
    filter: brightness(50%);
  }
}
