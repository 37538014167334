@mixin breakpoint($class) {
  @if $class == xs2 {
    @media screen and (max-width: 280px) {
      @content;
    }
  } @else if $class == xs {
    @media screen and (max-width: 320px) {
      @content;
    }
  } @else if $class == mobile-sm {
    @media screen and (max-width: 480px) {
      @content;
    }
  } @else if $class == mobile {
    @media screen and (max-width: 641px) {
      @content;
    }
  } @else if $class == mobile-large {
    @media screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  } @else if $class == SG-S3 {
    /* Samsung S3 tablet portrait*/
    @media only screen and (device-width: 700px) and (device-height: 1280px) and (orientation: portrait) {
      @content;
    }
  } @else if $class == SG-8 {
    /* Samsung G8  portrait*/
    @media screen and (device-width: 360px) and (device-height: 740px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
      @content;
    }
  } @else if $class == tablet-p {
    @media screen and (max-width: 800px) {
      @content;
    }
  } @else if $class == tablet-xinc {
    @media screen and (min-width: 810px) and (max-width: 1080px) {
      @content;
    }
  } @else if $class == tablet-5inc {
    @media screen and (min-width: 834px) and (max-width: 1024px) {
      @content;
    }
  } @else if $class == tablet-l {
    @media screen and (max-width: 1024px) {
      @content;
    }
  } @else if $class == sm-desktop {
    @media screen and (max-width: 1280px) and (max-height: 800px) and (min-height: 721px) {
      @content;
    }
  } @else if $class == md {
    @media screen and (max-width: 1366px), screen and (max-width: 1280px), screen and (max-width: 1366px) {
      @content;
    }
  } @else if $class == lg {
    @media screen and (min-width: 1540px) {
      @content;
    }
  } @else if $class == mh970 {
    @media screen and (max-height: 970px) {
      @content;
    }
  } @else if $class == mh750 {
    @media screen and (max-height: 750px) {
      @content;
    }
  } @else if $class == mh600 {
    @media screen and (max-height: 600px) {
      @content;
    }
  }
    /* iphone 5s ios  */
  @else if $class == ip5s {
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
      @content;
    }
  }
    /* iphone 6+, 6s+, 7+, 8+  */
  @else if $class == ip8Plus {
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  }
    /* iphone X */
  @else if $class == ipX {
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  }
    /* iphone XS Max */
  @else if $class == ipXsMax {
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  }
    /* iphone 11 Xла / ios 13.1.2, 13.2.2 */
  @else if $class == ipXR {
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
      @content;
    }
  }
    /* Android Pixel 2 XL */
  @else if $class == Pixel2XL {
    @media only screen and (min-device-width: 411px) and (max-device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) {
      @content;
    }
  }
    /* Android One + 6*/
  @else if $class == OnePlus6 {
    @media only screen and (min-device-width: 412px) and (max-device-height: 869px) and (-webkit-device-pixel-ratio: 2.625) {
      @content;
    }
  } @else if $class == SGs21Ultra {
    @media only screen and (-webkit-min-device-pixel-ratio: 3.75),
    only screen and (min--moz-device-pixel-ratio: 3.75),
    only screen and (-o-min-device-pixel-ratio: 3.75/1),
    only screen and (min-device-pixel-ratio: 3.75),
    only screen and (min-resolution: 515dpi),
    only screen and (min-resolution: 3.75dppx) {
      @content;
    }
  }
}
