//Set Icon font name.
$icon-font: 'icomoon';

// Brand colours
$brand-primary: #821bb8;
$brand-secondary: #933cc1;
// new brand
$purple: #3e0b6c;
$page-title: #192340;
/*821bb8*/

$violet: #933cc1;
$violet-mid: #dfb4f5;
$violet-light: #e7c6f6;
$purple-light: #e3bff5;
$brand-blue: #192340;
// d mode
$blue-dark: #26113b;

$dark-gray: #252629;
$mid-gray: #323232;
$gray: #4a4a4a;
$light-gray: #656565;
$dark-white: #afafaf;
$light-white: #e7e7e7;
$white: #ffffff;
$label: #f8f8f8;

$green-cookie: #1f4160;
//Colour
$col-1: rgba(74, 74, 74, 1); //Dark grey
//Colour
$col-2: rgba(136, 136, 136, 1); //Mid grey
//Colour
$col-3: rgba(221, 221, 221, 1); //light grey
//Key colour 1
$key-col-1: $brand-secondary;

//Body background
$body-bg: rgba(255, 255, 255, 1); //White

//Header bar background
$header-bg: rgba(10, 21, 20, 1); // Black;

//Header bar text
$header-text: $brand-primary;

//Header bar icon
$header-icon: rgba(255, 255, 255, 1); //White

//Info text head
$info-text-head: rgba(10, 21, 20, 1); //Black;

//Info text sub
$info-text-sub: rgba(10, 21, 20, 1); // Black;

//Simple button text
$button-text: rgba(255, 255, 255, 1); //White

//Simple button background
$button-bg: $key-col-1;

//Simple button border
$button-bdr: $key-col-1;

//Lesser button text
$lesser-button-text: rgba(255, 255, 255, 1); //White

//Lesser button background
$lesser-button-bg: $key-col-1;

//Lesser button border
$lesser-button-bdr: $key-col-1;

//Input text
$input-text: rgba(10, 21, 20, 1); //Black

//Input text background
$input-bg: transparent;

//Input text border
$input-bdr: rgba(240, 241, 241, 1);

//Service button text
$service-button-text: rgba(255, 255, 255, 1); //White

//Service button text
$service-button-text-date: rgba(255, 255, 255, 1); //White

//Service button background
$service-button-bg: $key-col-1;

//Service button border
$service-button-bdr: $key-col-1;

//Service button icon
$service-button-icon: rgba(255, 255, 255, 1); //White

//Service summary text
$service-summary-text: rgba(255, 255, 255, 1); //White
//Service summary full width background
$service-summary-bg: rgba(244, 122, 122, 0.9); //90% $brand-secondary;
//Service summary border
$service-summary-bdr: rgba(255, 255, 255, 1); //White
//Service summary icon
$service-summary-icon: rgba(255, 255, 255, 1); //White

//Info well full width background
$info-well-full-bg: transparent;

//Arrow button background
$arrow-button-bg: $key-col-1;

//Arrow button background
$arrow-button-bdr: $key-col-1;
//Arrow button icon
$arrow-button-icon: rgba(255, 255, 255, 1); //White

//Date text
$date-text: $col-1;
//Date text divider
$date-text-divider: $col-3;
//Date text fully booked message
$date-full: $col-2;

//Summary confirm full width background
$summary-confirm-bg: rgba(244, 122, 122, 0.9); //90% $brand-secondary;

//Summary confirm full width text
$summary-confirm-text: rgba(255, 255, 255, 1); //White

//Summary confirm full width text highlight
$summary-confirm-hi-text: rgba(255, 255, 255, 1); //White

//Supplemental text
$supplemental-text: $col-2;
//Supplemental text link
$supplemental-text-link: $brand-secondary;

//Footer info text
$footer-info-text: $brand-secondary;

//Success badge icon
$success-badge-icon: $brand-primary;

//Success badge border
$success-badge-bdr: $brand-primary;

// error, warning policy info
$warning: #e32e45;
$warning--dark: #ff6b7d;
