@function negative($value) {
  @return -1 * $value;
}

@function above($value) {
  @return $value + 1 ;
}

@function under($value) {
  @return $value - 1 ;
}


