@import 'src/styles/v2/all.scss';

$image--slick: linear-gradient(103deg, #af6dd1 -2%, #8e06bf);
$image--neutral: linear-gradient(103deg, #505773 0%, #192340 100%);
$image--dark: linear-gradient(103deg, #821bb8 -9%, #370861 100%);

.component {
  .title {
    color: $c-page--slick--fg;
    font-family: $font-family--bold;
    font-size: $font-sz--l4;
    margin-top: $gap--lg;
    display: block;
    text-align: center;
  }

  .panel {
    background-color: $c-header--slick--bd;
    box-shadow: $box-shadow--panel;
    border-radius: $border--r--xl;
    padding: $gap--lg;
    position: relative;
    margin-top: negative($gap--l2);
  }

  .gradientContainer {
    background-image: $image--slick;
    height: $header-gradient--h;
    width: 100vw;
    top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
  }

  .salonLogo {
    display: block;
    max-width: 50%;
    height: $header-logo--h;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include breakpoint(mobile) {
      max-width: 60%;
      height: $header-logo--h--min;
    }
  }

  .logoText {
    font-family: $font-family--semi-bold;
    font-size: $font-sz--xs;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-text--fg;
    margin-top: $gap--lg;

    @include breakpoint(mobile) {
      margin-top: $gap--sm;
    }
  }

  .slickLogo {
    height: $icon-sz--lg;
  }

  &.neutral {
    .gradientContainer {
      background-image: $image--neutral;
    }

    .title {
      color: $c-text--neutral--fg;
    }
  }
  &.dark {
    .gradientContainer {
      background-image: $image--dark;
    }

    .title {
      color: $c-page--dark--fg;
    }

    .logoText {
      filter: brightness(50);
    }

    .panel {
      background-color: $c-header--dark--bd;
      box-shadow: $box-shadow--panel--dark;
    }
  }
}
