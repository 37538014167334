@import 'src/styles/v2/all.scss';

.component {
  $width: 90px;

  font-family: $font-family--normal;
  font-size: $font-sz--md;
  height: $button--h--md;
  min-height: $button--h--md;
  min-width: $width;
  display: inline;
  position: relative;
  margin: $gap--xs;
  width: auto;
  padding: 0 $gap--sm;
  border-radius: $border--r--md;
  text-align: center;
  text-decoration: none;
  outline: 0 none;
  border: none;
  cursor: pointer;

  &.primaryVariant {
    border: solid $border--q--xs $c-button--primary--bd;
    background-color: $c-button--primary--bg;
    color: $c-button--primary--fg;
    &:disabled {
      border-color: $c-button--primary--disabled--bd;
      background-color: $c-button--primary--disabled--bg;
      color: $c-button--primary--disabled--fg;
    }
  }

  &.secondaryVariant {
    border: solid $border--q--xs $c-button--secondary--bd;
    color: $c-button--secondary--fg;
    background-color: $c-button--secondary--bg;
    &:disabled {
      border-color: $c-button--secondary--disabled--bd;
      background-color: $c-button--secondary--disabled--bg;
      color: $c-button--secondary--disabled--fg;
    }
  }

  &.tertiaryVariant {
    border: none;
    background-color: $c-button--tertiary--bg;
    color: $c-button--tertiary--fg;
    &:disabled {
      background-color: $c-button--tertiary--disabled--bg;
      color: $c-button--tertiary--disabled--fg;
    }
  }

  &.quaternaryVariant {
    border: solid $border--q--xs $c-button--quaternary--bd;
    background-color: $c-button--quaternary--bg;
    color: $c-button--quaternary--fg;
  }

  &.warningVariant {
    border: none;
    background-color: transparent;
    color: $c-button--warning--fg;
  }

  &.errorVariant {
    background-color: $c-button--error--bg;
    border-color: $c-button--error--bd;
    color: $c-button--error--fg;
  }

  &.emptyVariant {
    color: transparent;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    min-width: $button--w--xs;
    margin: 0;
    padding: $gap--xs;
    min-height: $button--w--xs;
    height: $button--h--xs;
  }

  &.fixedWidth {
    min-width: $button--w--min;
    height: $button--h--lg;
    min-height: $button--h--lg;
    @include breakpoint(mobile) {
      min-width: 100%;
    }
  }

  &.smallWidth {
    height: $button--h--sm;
    min-height: $button--h--sm;
    min-width: $button--w--sm;
    line-height: $line-h--normal;
  }

  &.middleHeight {
    height: $button--h--md;
    min-height: $button--h--md;
  }

  &.neutral {
    &.primaryVariant {
      border-color: $c-button--primary--neutral--bd;
      background-color: $c-button--primary--neutral--bg;
      color: $c-button--primary--neutral--fg;
      &:disabled {
        opacity: 0.2;
      }
    }

    &.secondaryVariant {
      border-color: $c-button--secondary--neutral--bd;
      background-color: $c-button--secondary--neutral--bg;
      color: $c-button--secondary--neutral--fg;
      &:disabled {
        border-color: $c-button--secondary--disabled--bd;
        background-color: $c-button--secondary--disabled--bg;
        color: $c-button--secondary--disabled--fg;
      }
    }
  }
  &.dark {
    &.primaryVariant {
      &:disabled {
        border-color: $c-button--primary--disabled--dark--bd;
        background-color: $c-button--primary--disabled--dark--bg;
        color: $c-button--primary--disabled--dark--fg;
      }
    }
    &.secondaryVariant {
      border-color: $c-button--secondary--dark--bd;
      background-color: $c-button--secondary--dark--bg;
      color: $c-button--secondary--dark--fg;
      &:disabled {
        border-color: $c-button--secondary--disabled--bd;
        background-color: $c-button--secondary--disabled--bg;
        color: $c-button--secondary--disabled--fg;
      }
    }

    &.tertiaryVariant {
      color: $c-button--tertiary--dark--fg;
    }

    &.warningVariant {
      color: $c-button--warning--dark--fg;
    }

    &.quaternaryVariant {
      border-color: $c-button--quaternary--dark--bd;
      background-color: $c-button--quaternary--dark--bg;
      color: $c-button--quaternary--dark--fg;
    }
  }
}
