@import 'src/styles/v2/all.scss';

.component {
  position: fixed;
  top: $navbar--h;
  background: $c-page--slick--bg;
  bottom: 0;
  left: 0;
  right: 0;
  color: $c-page--slick--fg;
  z-index: $z-cancel-appointment;
  overflow: auto;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--md;
    @include breakpoint(mobile) {
      min-height: $page--h;
      padding-bottom: $footer--h;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .container {
    text-align: center;
  }

  .title {
    font-family: $font-family--bold;
    font-size: $font-sz--l4;
    line-height: $line-h--l6;
    padding: 0;
    margin: 0 auto $gap--md;
  }

  .subTitle {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    font-family: $font-family--regular;
    margin-bottom: $gap--lg;
  }

  .gap {
    margin: $gap--md;
  }

  .text {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    font-family: $font-family--regular;
    margin-bottom: $gap--lg;
  }

  .footerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }

  .link {
    @include breakpoint(mobile) {
      width: 100%;
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;
  }
}
