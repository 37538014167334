@import 'src/styles/v2/all.scss';

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: 0 $gap--md;
    padding-bottom: $footer--h;
  }

  .pageTitle {
    font-size: $font-sz--l4;
    font-family: $font-family--bold;
    text-align: center;
    padding-bottom: $gap--sm;
  }

  .subTitle {
    display: flex;
    align-items: center;
    margin-top: $gap--lg;
    margin-bottom: $gap--lg;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .center {
    text-align: center;
  }

  .input,
  .phoneContainer input {
    padding: $gap--md;
    border: $border--q--sm solid $c-input--slick--bd;
    background-color: $c-input--slick--bg;
    width: 100%;
    border-radius: $border--r--md;
    &:focus {
      border-color: $c-input--slick--focus--bd;
      background-color: $c-input--slick--focus--bg;
    }
    &.hasError {
      border-color: $c-input--slick--error--bd;
    }
    &::placeholder {
      font-size: $font-sz--md;
    }
  }

  .inputContainer,
  .phoneContainer {
    margin-top: $gap--md;
    &.hasError {
      input {
        border-color: $c-input--slick--error--bd;
      }
    }
  }

  .error {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin: $gap--sm 0;
    display: inline-block;
  }

  .inputLabel {
    display: block;
    margin-bottom: $gap--sm;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .buttonContainer {
    margin-top: $gap--xl;
    text-align: center;
    font-size: $font-sz--md;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .passwordContainer {
    position: relative;
  }

  .eyeContainer {
    position: absolute;
    right: $gap--md;
    top: $gap--md;
    margin-top: $gap--s2;
  }

  .eyeOpen,
  .eyeClose {
    @include square(20px);
    display: block;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  .eyeOpen {
    background-image: url('~assets/images/icons/eye-open.svg');
  }

  .eyeClose {
    background-image: url('~assets/images/icons/eye-close.svg');
  }

  .footerContainer {
    display: flex;
    flex-direction: column;
  }

  .forgotLinkContainer {
    text-align: right;
    margin-top: $gap--sm;
  }

  .forgotLink {
    color: $c-text--fg;
    font-size: $font-sz--sm;
    text-decoration: underline;
  }

  .underline {
    text-decoration: underline;
  }

  &.neutral {
    .commonError {
      color: $c-alert--neutral--fg;
    }

    .input,
    .phoneContainer input {
      &:focus {
        border-color: $c-input--neutral--focus--bd;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .forgotLink {
      color: $c-text--dark--fg;
    }

    .eyeOpen,
    .eyeClose {
      filter: invert(1);
    }

    .commonError,
    .error {
      color: $c-alert--dark--fg;
    }

    .phoneContainer {
      &.hasError {
        input {
          border-color: $c-input--slick--error--bd;
        }
      }
    }

    .input,
    .phoneContainer input {
      border-color: $c-input--dark--bd;
      background-color: $c-input--dark--bg;
      color: $c-input--dark--fg;

      &:focus {
        border-color: $c-input--dark--focus--bd;
        background-color: $c-input--dark--focus--bg;
      }

      &.hasError {
        border-color: $c-input--dark--error--bd;
      }
    }
  }
}

.modalContent {
  text-align: center;
}

.title {
  font-family: $font-family--bold;
  font-size: $font-sz--l3;
  line-height: $line-h--l6;
  padding: $gap--lg 0;
  max-width: 80%;
  margin: 0 auto;
}

.subTitle {
  font-size: $font-sz--md;
  line-height: $line-h--l3;
  font-family: $font-family--semi-bold;
  margin-bottom: $gap--lg;
}
