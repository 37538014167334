@import 'src/styles/v2/all.scss';

$text--h: 44px;
$label--w: 92px;

.component {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-basis: 50%;
  min-height: $ncob-item--w;
  position: relative;
  margin-bottom: $gap--md;
  padding: $gap--md;

  background-color: $c-item--slick--bg;
  border-bottom: $border--q--xs solid $c-item--slick--bd;
  cursor: pointer;

  @include breakpoint(mobile) {
    flex-basis: 100%;
    &:last-child {
      border-bottom: none;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .title {
    margin-bottom: $gap--xs;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .duration {
    margin: $gap--s2 0;
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
    color: $silver-primary;
  }

  .price {
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
    color: $c-text--fg;
  }

  .description {
    display: block;
    word-break: break-word;
    position: relative;
    margin-bottom: $gap--md;
    color: $c-text-tertiary--slick--fg;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;

    &.clip {
      overflow: hidden;
      max-height: $text--h;
      padding-top: $gap--s2;
      text-overflow: clip;
    }
  }

  .showMore {
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: $label--w;
    background-color: $c-page--slick--bg;
    color: $c-text--fg;
    &.isExpanded {
      line-height: $line-h--lg;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }

  &.neutral {
    .price,
    .title {
      color: $c-text--neutral--fg;
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .price,
    .title,
    .description {
      color: $c-page--dark--fg;
    }

    .showMore {
      color: $c-text--dark--fg;
      background-color: $c-page--dark--bg;
    }
  }
}
