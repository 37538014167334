@import 'src/styles/v2/all.scss';

.component {
  position: relative;
  margin: $gap--lg auto $gap--md;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $icon-sz--l3;
}

.circle {
  @include square($icon-sz--l3);
  border-radius: $border--r--mx;
  display: flex;
  align-items: center;
  justify-content: center;

  &.successVariant {
    background-color: $c-modal-success-icon--bg;
  }

  &.alertVariant {
    background-color: $c-modal-alert-icon--bg;
  }

  &.infoVariant {
    background-color: $c-modal-info-icon--bg;
  }

  &.warningVariant {
    background-color: $c-modal-warning-icon--bg;
  }

  &.inactiveVariant {
    background-color: $c-modal-inactive-icon--bg;
  }
}
