@import 'src/styles/v2/all.scss';

$logo--h: 90px;

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;
  min-height: $page--h;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--l3 $gap--md $footer--h;
  }

  .footerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .link {
    width: 100%;
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $gap--md 0;
  }

  .statusIcon {
    @include square($icon-sz--l3);
    background: $pink-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border--r--mx;
    margin: $gap--xl auto $gap--lg;
  }

  .title {
    color: $c-page--slick--fg;
    font-size: $font-sz--l4;
    font-family: $font-family--bold;
    text-align: center;
  }

  .subTitle {
    display: flex;
    align-items: center;
    margin-top: $gap--lg;
    margin-bottom: $gap--sm;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .icon {
    margin-right: $gap--sm;
  }

  .small {
    font-size: $font-sz--sm;
    text-align: center;
    margin-top: $gap--md;
  }

  .link {
    color: $c-page--slick--bg;
    padding: 0 $gap--sm;
  }

  .container {
    display: flex;
    flex-wrap: wrap;

    margin-left: negative($gap--sm);
    margin-right: negative($gap--sm);
  }

  .salonItem {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: $gap--sm;
    flex-basis: calc(50% - #{$gap--md});
    border: $border--q--xs solid $c-item--slick--bd;
    border-radius: $border--r--xl;
    padding: $gap--md;
    font-family: $font-family--semi-bold;
  }

  .logoContainer {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: $logo--h;
  }

  .salonLogo {
    display: block;
  }

  .salonName {
    font-family: $font-family--semi-bold;
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    text-align: center;
    color: $c-page--slick--fg;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .title,
    .subtitle,
    .salonName {
      color: $c-page--dark--fg;
    }

    .icon,
    .statusIcon img {
      filter: brightness(10);
    }

    .statusIcon {
      background: $purple;
    }
  }

  &.neutral {
    .statusIcon {
      background: $silver;
      img {
        filter: brightness(0.7);
      }
    }
  }
}
