@import 'src/styles/v2/all.scss';

$logo-pad: $navbar--h + $gap--xl;

$image--slick: linear-gradient(103deg, #af6dd1 -2%, #8e06bf);
$image--neutral: linear-gradient(103deg, #505773 0%, #192340 100%);
$image--dark: linear-gradient(103deg, #821bb8 -9%, #370861 100%);

.component {
  .title {
    color: $c-title--fg;
    font-family: $font-family--bold;
    font-size: $font-sz--l4;
    margin-top: $gap--lg;
  }

  .logoPanel {
    background-color: $c-header--slick--bd;
    box-shadow: $box-shadow--panel;
    border-radius: $border--r--xl;
    margin-top: $logo-pad;
    padding: $gap--lg;
    position: relative;
  }

  .gradientContainer {
    background-image: $image--slick;
    height: $header-gradient--h;
    width: 100vw;
    top: negative($gap--xl);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
  }

  .salonLogo {
    display: block;
    max-width: 50%;
    height: $header-logo--h;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include breakpoint(mobile) {
      max-width: 60%;
      height: $header-logo--h--min;
    }
  }

  .logoText {
    font-family: $font-family--semi-bold;
    font-size: $font-sz--xs;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-text--fg;
    margin-top: $gap--lg;

    @include breakpoint(mobile) {
      margin-top: $gap--sm;
    }
  }

  .slickLogo {
    height: $icon-sz--lg;
  }

  .arrowGoBack {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  // nav
  .navigation {
    height: $navbar--h;
    background-color: $c-nav--slick--bg;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    z-index: $z-navigation;
  }

  .navItem {
    flex: 0 0 33.33%;
    text-align: center;
    list-style-type: none;
    display: flex;
    height: 100%;
  }

  .navLink {
    color: $c-nav--slick--fg;
    width: 100%;
    cursor: pointer;
    &.selected {
      border-bottom: $border--q--lg solid $c-nav--slick--focus--bd;
      .iconProfile {
        background-image: url('~assets/images/icons/user-circle.svg');
      }
      .iconBooking {
        background-image: url('~assets/images/icons/calendar-white-selected.svg');
      }
      .iconPound {
        background-image: url('~assets/images/icons/currency-pound-selected.svg');
      }
    }
  }

  .navContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $font-sz--xs;
    font-family: $font-family--semi-bold;
    padding-top: $gap--s2;
  }

  .iconProfile,
  .iconBooking,
  .iconPound {
    margin-top: $gap--xs;
    background-repeat: no-repeat;
    background-position: center;
    @include square($icon-sz--xl);
  }

  .iconProfile {
    background-image: url('~assets/images/icons/user-circle-white.svg');
  }

  .iconBooking {
    background-image: url('~assets/images/icons/calendar-white.svg');
  }

  .iconPound {
    background-image: url('~assets/images/icons/currency-pound.svg');
  }

  .iconContainer {
    position: relative;
  }

  &.neutral {
    .navigation {
      background-color: $c-nav--neutral--bg;
    }
    .gradientContainer {
      background-image: $image--neutral;
    }

    .title {
      color: $c-text--neutral--fg;
    }
  }
  &.dark {
    .navigation {
      background-color: $c-nav--dark--bg;
    }

    .navLink {
      &.selected {
        border-bottom-color: $c-nav--dark--focus--bd;
      }
    }

    .gradientContainer {
      background-image: $image--dark;
    }

    .title {
      color: $c-page--dark--fg;
    }

    .logoText,
    .arrowImg {
      filter: brightness(50);
    }

    .logoPanel {
      background-color: $c-header--dark--bd;
      box-shadow: $box-shadow--panel--dark;
    }

    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }
  }
}
