@import 'src/styles/v2/all.scss';

$image--slick: linear-gradient(101deg, #af6dd1 -38%, #821bb8 100%);
$image--dark: linear-gradient(101deg, #3e0869 0%, #821bb8 100%);
$image--neutral: linear-gradient(105deg, #192340 0%, #505773 100%);

.component {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-basis: calc(50% - #{$gap--s2});
  border-radius: $border--r--xl;
  margin-bottom: $gap--xs;
  padding: $gap--sm $gap--md;
  position: relative;
  background-image: $image--slick;
  cursor: pointer;

  @include breakpoint(mobile) {
    flex-basis: 100%;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: $gap--sm 0;
  }

  .info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .title {
    margin-bottom: $gap--xs;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
    color: $white;
  }

  .price {
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
    color: $c-text--dark--fg;
  }

  .row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }

  &.neutral {
    background-image: $image--neutral;
    .price {
      color: $white;
    }
  }

  &.dark {
    background-image: $image--dark;
    color: $c-page--dark--fg;

    .price {
      color: #fff;
    }
  }
}
