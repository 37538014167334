@import 'src/styles/v2/all.scss';

$textarea--h: 180px;
$image-container--h: 100px;

.component {
  padding-top: $gap--xl;
  max-width: $card--w--max;
  margin: 0 auto;
  min-height: $page--h;

  .input,
  .area {
    padding: $gap--md;
    border: $border--q--sm solid $c-input--slick--bd;
    background-color: $c-input--slick--bg;
    margin-bottom: $gap--md;
    width: 100%;
    border-radius: $border--r--md;
    &:focus {
      border-color: $c-input--slick--focus--bd;
      background-color: $c-input--slick--focus--bg;
    }
    &.hasError {
      border-color: $c-input--slick--error--bd;
    }
  }

  .area {
    min-height: $textarea--h;
  }

  .statusIcon {
    @include square($icon-sz--l3);
    background: $pink-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border--r--mx;
    margin: $gap--xl auto $gap--lg;
  }

  .title {
    color: $c-page--slick--fg;
    font-size: $font-sz--l4;
    font-family: $font-family--bold;
    text-align: center;
  }

  .details {
    margin-top: $gap--xl;
  }

  .icon {
    margin-right: $gap--md;
  }

  .rowTitle {
    margin-bottom: $gap--sm;
    color: $c-page--slick--fg;
    font-size: $font-sz--md;
    font-family: $font-family--semi-bold;
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: $c-page--slick--fg;
    margin: $gap--sm 0 $gap--md;

    &:last-child {
      border-bottom: none;
    }
  }

  .footerInnerContainer {
    display: flex;
    flex-direction: column;
  }

  .charCount {
    color: $c-page--slick--fg;
    opacity: 0.5;
    font-size: $line-h--sm;
  }

  .commonError {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
  }

  .inputError {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin-bottom: $gap--xs;
  }

  .imagesGroup {
    position: relative;
    width: 100%;
    height: $image-container--h;
    margin: $gap--l2 0;

    .sparkleTop {
      position: absolute;
      left: 30%;
      @include square($icon-sz--lg);
      filter: brightness(0.3);
    }

    .sparkleBottom {
      position: absolute;
      @include square($icon-sz--l1);
      right: 40%;
      bottom: 0;
      filter: brightness(0.3);
    }
  }

  &.dark {
    .sparkleTop,
    .sparkleBottom {
      filter: none;
    }
    .commonError {
      color: $c-alert--dark--fg;
    }
    .input,
    .area {
      border-color: $c-input--dark--bd;
      background-color: $c-input--dark--bg;
      color: $c-input--dark--fg;
      &:focus {
        border-color: $c-input--dark--focus--bd;
        background-color: $c-input--dark--focus--bg;
      }

      &.hasError {
        border-color: $c-input--dark--error--bd;
      }
    }

    .title,
    .subtitle,
    .row,
    .rowTitle,
    .charCount {
      color: $c-page--dark--fg;
    }

    .icon,
    .statusIcon img {
      filter: brightness(10);
    }

    .statusIcon {
      background: $purple;
    }
  }

  &.neutral {
    .commonError {
      color: $c-alert--neutral--fg;
    }
    .input,
    .area {
      &:focus {
        border: 2px solid $c-input--neutral--focus--bd;
      }
    }
    .value {
      color: $c-text--neutral--fg;
    }
    .statusIcon {
      background: $silver;
      img {
        filter: brightness(0.7);
      }
    }
  }
}
