@import 'src/styles/v2/all.scss';

.component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $gap--sm 0;

  .inline {
    display: inline;
  }

  .boldText {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .textSecondary {
    color: $c-text-secondary--slick--fg;
    font-size: $font-sz--sm;
    font-family: $font-family--regular;
    padding: $gap--xs 0;
  }

  .rowValue {
    color: $c-text--fg;
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .rowValue {
      color: $c-text--dark--fg;
    }
    .textSecondary {
      color: $c-text-secondary--dark--fg;
    }
  }
}
