@import 'src/styles/v2/all.scss';

.component {
  border-bottom: $border--q--md solid $c-tab--slick--bd;
  flex: 0 0 50%;

  &.isActive {
    border-bottom-color: $c-tab--slick--selected--bd;
  }

  .tabButton {
    color: $c-tab--slick--fg;
    background-color: $c-tab--slick--bg;
    border-radius: $border--r--xl;
    margin-bottom: $gap--sm;
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $gap--md;
    cursor: pointer;
    &.isActive {
      background-color: $c-tab--slick--selected--bg;
    }
  }

  &.neutral {
    &.isActive {
      border-bottom-color: $c-tab--neutral--selected--bd;
    }
    .tabButton {
      &.isActive {
        background-color: $c-tab--neutral--selected--bg;
      }
    }
  }

  &.dark {
    border-bottom-color: $c-tab--dark--bd;
    &.isActive {
      border-bottom-color: $c-tab--slick--selected--bd;
    }
    .tabButton {
      color: $c-tab--dark--fg;
      background-color: $c-tab--dark--bg;

      &.isActive {
        background-color: $c-tab--dark--selected--bg;
      }
    }
  }
}
