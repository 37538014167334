@import 'src/styles/v2/all.scss';

.add-to-calendar {
  @include breakpoint(mobile) {
    min-width: 100%;
    margin-left: negative($gap--sm); // inner button margin 2 x 4px
  }
}

.react-add-to-calendar {
  position: relative;
}

.react-add-to-calendar__button {
  color: $c-button--secondary--fg;

  span {
    display: block;
    text-align: center;
  }

  .react-add-to-calendar__icon--right {
    display: none;
  }
}

.react-add-to-calendar__dropdown {
  position: absolute;
  bottom: $button--h--lg;
  width: 100%;
  background-color: $c-page--slick--bg;
  box-shadow: $box-shadow--panel;
}

.react-add-to-calendar__dropdown ul {
  padding: 0;
}

.react-add-to-calendar__dropdown ul li {
  padding: $gap--sm $gap--md;
  list-style-type: none;
}

.react-add-to-calendar__dropdown ul li a {
  font-family: $font-family--semi-bold;
  display: block;
  color: $c-page--slick--fg;
}
