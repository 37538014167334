// Icon font include. www.icomoon to generate (unicode chars - \a0001-\a0011).

@font-face {
  font-family: 'icomoon';
  src: url(~assets/fonts/icomoon.eot) format('embedded-opentype'),
  url(~assets/fonts/icomoon.ttf) format('truetype'), url(~assets/fonts/icomoon.woff) format('woff'),
  url(~assets/fonts/icomoon.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

// Icon font (category and state) Styles.

.ico-beauty::before {
  content: '\a0001'; //Beauty.(cal-cat-1)
}

.ico-technical::before {
  content: '\a0002'; //Technincal.(cal-cat-2)
}

.ico-colour::before {
  content: '\a0003'; //Colour.(cal-cat-3)
}

.ico-style::before {
  content: '\a0004'; //Style.(cal-cat-4)
}

.ico-cut::before {
  content: '\a0005'; //Cut.(cal-cat-5)
}

.ico-other::before {
  content: '\a0011'; //Other.(cal-cat-7)
}

.ico-confirm::before {
  content: '\a0006'; //Confirm.(cal-ico-confirm)
}

.ico-arrived::before {
  content: '\a0007'; //Arrived.(cal-ico-arrived)
}

.ico-paid::before {
  content: '\a0008'; //Paid.(cal-ico-paid)
}

.ico-pling::before {
  content: '\a0009'; //Cancel & Error.(cal-ico-pling)
}

.ico-recur::before {
  content: '\a0010'; //Recur used in calendarentry.scss (.cal-name-recur)
}

.ico-menu::before {
  content: '\a0012';
}

.ico-event::before {
  content: '\a0013';
}

.ico-chevron-left::before {
  content: '\a0014';
}

.ico-chevron-right::before {
  content: '\a0015';
}

.ico-phone::before {
  content: '\a0016'; //Phone icon
}

.ico-stylist::before {
  content: '\a0017'; //Person icon
}

.ico-success::before {
  content: '\a0018';
}

//SVG
.ico_arrow_forward_white {
  background-image: url('~assets/images/ic_arrow_forward_white_48px.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.ico-svg-awesome {
  background-image: url('~assets/images/awesome.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.ico-svg-book-by-phone {
  background-image: url('~assets/images/book-by-phone.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.ico-svg-calendar {
  background-image: url('~assets/images/calendar.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.ico-svg-duration {
  background-image: url('~assets/images/duration.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.ico-svg-services {
  background-image: url('~assets/images/services.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.ico-svg-stylist {
  background-image: url('~assets/images/stylist.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.img_error {
  background-image: url('~assets/images/error.png'); //Replace with SVG
  background-repeat: no-repeat;
  background-size: 100%;
}
