@import '../mixin/breakpoints.scss';
@import '../modules/base.scss';

.profile-wrap {
  margin-top: 64px;
  padding-top: 25px;

  & .app-content {
    margin: 0;
  }
}

.request {
  font-family: 'ProximaNova-sb';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  text-align: center;

  &.empty {
    img {
      display: block;
      max-height: 28vh;
      margin: 20px auto;
    }

    .btn {
      @include breakpoint(mobile) {
        font-size: 14px;
        display: block;
        width: 200px;
        min-width: 200px;
        margin: 0 auto;
      }
    }
  }

  > p {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.request-container {
  width: 314px;
  margin: 30px auto;
}

.salon-request-container {
  padding-top: 5px;
}

.request-salon-name {
  font-family: 'ProximaNova-sb';
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  min-height: 39px;
  text-align: center;
  color: #3e0b6c;
  background-color: #f8eaff;
  box-shadow: 1px 1px 5px 0 rgba(155, 155, 155, 0.73);

  > div {
    padding: 11px 15px;
  }
}

.salon-request-item {
  position: relative;
  min-height: 200px;
  margin-bottom: 30px;
  border-radius: 13px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(155, 155, 155, 0.41), 0 0 8px 0 rgba(155, 155, 155, 0.38);

  &:last-child {
    margin-bottom: 0;
  }

  .image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 78px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: #5f1486;
  }

  .item-container {
    margin-left: 78px;
    padding: 12px 18px 12px 21px;

    h3 {
      font-family: 'ProximaNova-sb';
      font-size: 18px;
      line-height: 1.13;
      margin: 3px 0 6px 0;
    }

    .request-time {
      padding-left: 25px;
      background-image: url('~assets/images/calIconEvent.svg');
      background-repeat: no-repeat;
      background-position: 0px 6px;
      background-size: 12px;

      > strong {
        font-family: 'ProximaNova-sb';
        font-size: 14px;
        line-height: 1.27;
        display: block;
      }

      > span {
        font-family: 'ProximaNova-r';
        font-size: 11px;
        line-height: 1.2;
        display: block;
        text-transform: lowercase;
        color: #979797;
      }
    }

    .service-list {
      margin: 5px 0;
      padding: 0;

      > li {
        position: relative;
        margin: 7px 0;
        padding: 0 0 0 25px;
        list-style-type: none;

        .service-name {
          font-family: 'ProximaNova-sb';
          font-size: 14px;
          line-height: 1.27;
          display: flex;
          justify-content: space-between;

          & .text {
            flex: 1;
            margin-right: 12px;
          }
        }

        .stylist-name,
        .service-price {
          font-family: 'ProximaNova-r';
          font-size: 11px;
          line-height: 1.2;
          color: #979797;
        }

        .service-price {
          margin-top: 2px;
        }

        & .color-dot {
          position: absolute;
          top: 50%;
          left: 0;
          width: 10px;
          height: 9.5px;
          margin-top: -5px;
          border-radius: 13px;
        }

        &:nth-child(1n) .color-dot {
          background-color: #c7349a;
        }

        &:nth-child(2n) .color-dot {
          background-color: #00b8a8;
        }

        &:nth-child(3n) .color-dot {
          background-color: #5c8fff;
        }

        &:nth-child(4n) .color-dot {
          background-color: #f4a637;
        }

        &:nth-child(5n) .color-dot {
          background-color: #af6dd1;
        }
      }
    }

    .total {
      font-family: 'ProximaNova-sb';
      font-size: 13px;
      line-height: 1.7;
      margin-bottom: 10px;

      > div {
        display: block;
      }
    }

    .btn-decline {
      font-family: 'ProximaNova-sb';
      font-size: 10px;
      line-height: 1.63;
      display: block;
      width: 160px;
      padding: 10px 10px 6px 10px;
      cursor: pointer;
      text-align: center;
      color: #e32e45;
    }

    .item-footer {
      display: flex;
      justify-content: space-between;

      .btn {
        font-family: 'ProximaNova-sb';
        font-size: 10px;
        min-width: 48%;
        height: 31px;
        padding: 0;
        text-transform: uppercase;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

        &.confirm-card {
          width: 180px;
        }
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 78px;
    margin: auto;
  }
}

.text-violet {
  color: $brand-primary;
}

.profile-header {
  font-family: 'ProximaNova-sb';
  font-size: 16px;
  position: relative;
  width: 160px;
  margin: 0 auto;
  margin-bottom: 48px;
  color: #630396;

  img {
    position: absolute;
    top: 2px;
    left: 82px;
    width: 64px;
  }

  span {
    position: absolute;
    top: 10px;
  }
}

#myProfile {
  padding-top: 20px;

  .cardInfo {
    margin-top: 0;
  }

  h3,
  h2 {
    font-family: 'ProximaNova-sb';
    font-size: 20px;
    line-height: 27px;
    display: block;
    clear: both;
    min-height: 25px;
    margin: 0 15pt 20px;
    text-align: center;
  }

  // sub nav tabs
  .sub-tabs {
    display: flex;

    & .tab {
      font-family: 'ProximaNova-sb';
      font-size: 14px;
      width: 100%;
      padding: 13px 0;
      text-align: center;
      color: $brand-blue;
      border: solid 1px $brand-blue;
      background-color: $white;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        color: $white;
        background-color: $brand-blue;
      }
    }
  }

  .salon-list {
    margin: 0 0;
  }

  .btn.btn-primary {
    font-size: 20px;
  }

  .btn.btn-link {
    font-size: 14px;
    display: block;
    margin: 0 auto;
    padding: 5px 0 0 0;
  }

  .btn.btn-red {
    font-size: 16px;
    display: block;
    margin: 20px auto;
    text-align: center;
    color: #f73746;
    background-color: transparent;
  }

  .img-overflow-hidden {
    position: relative;
    overflow: hidden;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    border-radius: 50%;

    .modal-icon {
      position: absolute;
      top: -1px;
      left: -2px;
      width: 96px;
    }
  }

  .modal-icon {
    display: block;
    width: 95px;
    margin: 0 auto;
  }

  .payment-info {
    font-family: 'ProximaNova-r';
    font-size: 12px;
    line-height: 20px;
    display: block;
    margin-top: 20px;
    color: #192340;
    @include breakpoint(mobile) {
      margin: 20px 28px;
    }
  }

  .cb-square label > div {
    font-family: 'ProximaNova-r';
    font-size: 12px;
    font-weight: 100;
    display: inline-block;
    width: calc(100%);
    padding-left: 0;
  }

  @include breakpoint(mobile) {
    padding-bottom: 40px;
  }
  @include breakpoint(ip8Plus) {
    padding-bottom: 40px;
  }
}

#myBook {
  padding-top: 30px;
  padding-bottom: 90px;

  h3,
  h2 {
    font-family: 'ProximaNova-sb';
    font-size: 20px;
    line-height: 27px;
    display: block;
    clear: both;
    min-height: 25px;
    margin: 0 15pt 20px;
    text-align: center;
  }

  .salon-list {
    margin: 0 15pt;
  }

  & .link {
    font-family: 'ProximaNova-sb';
    font-size: 14px;
    display: block;
    width: 200px;
    min-width: 120px;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
  }

  .upcoming-item {
    position: relative;
    min-height: 200px;
    margin: 0 15pt;
    margin-bottom: 30px;
    border-radius: 13px;
    background-color: white;
    box-shadow: 0 0 8px 0 rgba(155, 155, 155, 0.41), 0 0 8px 0 rgba(155, 155, 155, 0.38);

    .booking-status-confirmed,
    .booking-status-not-confirmed {
      font-family: 'ProximaNova-b';
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }

    .booking-status-confirmed {
      background-color: #3a8463;
    }

    .booking-status-not-confirmed {
      background-color: #d90368;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .image-container {
      position: absolute;
      top: 40px;
      bottom: 0;
      left: 0;
      overflow: hidden;
      width: 78px;
      border-bottom-left-radius: 9px;
      background-color: #5f1486;
    }

    .item-container {
      margin-left: 78px;
      padding: 12px 25px 12px 25px;

      h3 {
        font-family: 'ProximaNova-sb';
        font-size: 16px;
        line-height: 1.13;
        margin: 0 0 6px 0;
        text-align: left;
      }

      .request-time {
        padding-left: 25px;
        background-image: url('~assets/images/calIconEvent.svg');
        background-repeat: no-repeat;
        background-position: 0px 3px;
        background-size: 14px;

        > strong {
          font-family: 'ProximaNova-sb';
          font-size: 14px;
          line-height: 1.27;
          display: block;
        }

        > span {
          font-family: 'ProximaNova-r';
          font-size: 11px;
          line-height: 1.2;
          display: block;
          color: #979797;
        }
      }

      .service-list {
        margin: 5px 0;
        padding: 0;

        > li {
          position: relative;
          margin: 7px 0;
          padding: 0 0 0 25px;
          list-style-type: none;

          .service-name {
            font-family: 'ProximaNova-sb';
            font-size: 14px;
            line-height: 1.27;
            display: flex;

            & .text {
              margin-right: 8px;
            }
          }

          .stylist-name,
          .service-price {
            font-family: 'ProximaNova-r';
            font-size: 11px;
            line-height: 1.2;
            color: #979797;
          }

          .service-price {
            margin-top: 2px;
          }

          & .color-dot {
            position: absolute;
            top: 50%;
            left: 0;
            width: 10px;
            height: 9.5px;
            margin-top: -5px;
            border-radius: 13px;
          }

          &:nth-child(1n) .color-dot {
            background-color: #c7349a;
          }

          &:nth-child(2n) .color-dot {
            background-color: #00b8a8;
          }

          &:nth-child(3n) .color-dot {
            background-color: #5c8fff;
          }

          &:nth-child(4n) .color-dot {
            background-color: #f4a637;
          }

          &:nth-child(5n) .color-dot {
            background-color: #af6dd1;
          }
        }
      }

      .patch-date-time {
        font-family: 'ProximaNova-sb';
        font-size: 13px;
        margin: 0 -25px 10px;
        padding: 8px 0;
        text-align: center;
        color: #933cc1;
        background-color: rgba(199, 177, 226, 0.5);
        @include breakpoint(mobile) {
          line-height: 17px;
          padding: 5px 25px;
        }
      }

      .total {
        font-family: 'ProximaNova-sb';
        font-size: 13px;
        line-height: 1.7;
        margin-bottom: 10px;

        > div {
          display: block;
        }
      }

      .item-footer {
        display: flex;
        justify-content: center;

        .btn-red {
          color: white;
          background-color: #9e034c;
        }

        .btn {
          font-family: 'ProximaNova-sb';
          font-size: 11px;
          line-height: 28px;
          min-width: 48%;
          height: 28px;
          padding: 0;
          text-transform: uppercase;
          border-radius: 4px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
            0 1px 1px 0 rgba(0, 0, 0, 0.14);
          @include breakpoint(mobile) {
            padding-right: 8px;
            padding-left: 8px;
          }
        }
      }
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 78px;
      margin: auto;
    }
  }
}

#myFeeds {
  padding-top: 20px;
}

.salon-location-item {
  position: relative;
  min-height: 71px;
  margin-bottom: 21px;
  cursor: pointer;
  border-radius: 13px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(155, 155, 155, 0.41), 0 0 8px 0 rgba(155, 155, 155, 0.38);

  .item-content {
    font-family: 'ProximaNova-r';
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 71px;
    margin-left: 68px;
    padding: 0 25px 0 25px;
    text-align: left;
  }

  .item-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 68px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: #f8eaff;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 68px;
      margin: auto;
    }
  }
}

.history-item {
  position: relative;
  min-height: 62px;
  margin: 0 15pt;
  margin-bottom: 21px;
  border-radius: 13px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(155, 155, 155, 0.41), 0 0 8px 0 rgba(155, 155, 155, 0.38);

  .item-content {
    font-family: 'ProximaNova-r';
    font-size: 14px;
    line-height: 19px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    min-height: 71px;
    margin-left: 68px;
    text-align: left;
    @include breakpoint(mobile) {
      margin-left: 44px;
    }

    .service-list {
      margin: 0;
      list-style-type: none;
      @include breakpoint(mobile) {
        padding: 0 0 0 15px;
      }

      .color-dot {
        position: absolute;
        left: 0;
        width: 8px;
        height: 8px;
        margin-top: 15px;
        border-radius: 13px;
        background-color: #979797;
      }

      .service-name {
        @include breakpoint(mobile) {
          max-width: 130px;
        }
      }
    }

    .stylist {
      margin-bottom: 4px;
      color: #979797;
    }

    > span {
      display: block;
      width: calc(100% - 102px);

      &.stylist {
        margin-bottom: 0px;
        color: #979797;
      }
    }

    > div {
      display: block;
      width: calc(100% - 102px);
      margin-top: 8px;
    }

    & .btn.btn-primary {
      font-size: 12px;
      line-height: 36px;
      position: absolute;
      top: 50%;
      right: 20px;
      width: 76px;
      min-width: 76px;
      height: 36px;
      margin-top: -18px;
      padding: 0;
      text-transform: uppercase;
      box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.46), 0 1px 4px 0 rgba(0, 0, 0, 0.45);
    }
  }

  .item-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 68px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    @include breakpoint(mobile) {
      width: 48px;
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 26px;
      display: block;
      width: 20px;
      margin: auto;
      @include breakpoint(mobile) {
        left: 12px;
      }
    }
  }

  .not-bookable {
    font-family: 'ProximaNova-r';
    font-size: 12px;
    line-height: 15px;
    position: absolute;
    right: 20px;
    width: 60px !important;
    margin-top: -18px;
    text-align: center;
  }
}

.ico_arrow_forward_white {
  display: block;
  float: left;
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(390deg) brightness(40%) contrast(50%);
}

.banner {
  font-family: 'ProximaNova-sb';
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 39px;
  margin: 30px 0;
  padding: 0 15px;
  text-align: center;
  color: white;
  box-shadow: 1px 1px 5px 0 rgba(155, 155, 155, 0.73);

  &.upcoming {
    background-color: #af6dd1;
  }

  &.history {
    background-color: #630396;
  }
}
