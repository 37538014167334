@import 'src/styles/v2/all.scss';

.component {
  font-size: $font-sz--sm;
  line-height: $line-h--l2;
  font-family: $font-family--regular;

  .text {
    margin-bottom: $gap--md;
    margin-top: 0;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;
  }
}
