@import 'src/styles/v2/all.scss';

$button--h: 60px;

.component {
  border: $border--q--xs solid $c-item--slick--bd;
  border-radius: $border--r--xl;
  margin-bottom: $gap--md;

  &.isOpen {
    border: $border--q--sm solid $c-item--slick--selected--bd;
  }

  .countLabel {
    display: flex;
    justify-content: space-between;
    font-size: $font-sz--xs;
    padding: $gap--xs;
    margin-right: $gap--md;
    border-radius: $border--r--md;
    background-color: $c-label--slick--bg;
    color: $c-label--slick--fg;
  }

  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $gap--md;
    min-height: $button--h;
    cursor: pointer;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .catName {
    font-family: $font-family--semi-bold;
    font-size: $font-sz--lg;
    color: $c-item--slick--fg;
  }

  .arrowContainer {
    display: flex;
    justify-content: space-between;
  }

  .arrow {
    &.isOpen {
      transform: rotate(90deg);
    }
  }

  &.neutral {
    &.isOpen {
      border: $border--q--sm solid $c-item--neutral--selected--bd;
    }
    .countLabel {
      background-color: $c-label--neutral--bg;
      color: $c-label--neutral--fg;
    }
  }

  &.dark {
    border: $border--q--xs solid $c-item--dark--bd;
    .countLabel {
      background-color: $c-label--dark--bg;
      color: $c-label--dark--fg;
    }

    .catName {
      color: $c-item--dark--fg;
    }

    &.isOpen {
      border: $border--q--sm solid $c-item--dark--selected--bd;
      .countLabel {
        background-color: $c-label--dark--selected--bg;
        color: $c-label--dark--selected--fg;
      }
    }

    .arrow {
      filter: brightness(10);
    }
  }
}
