@import 'src/styles/v2/all.scss';

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;
  padding-bottom: $footer--h;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: 0 $gap--md;
  }

  .title {
    font-size: $font-sz--l4;
    font-family: $font-family--bold;
    text-align: center;
  }

  .subTitle {
    display: flex;
    align-items: center;
    margin-bottom: $gap--sm;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .small {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    margin: $gap--sm;
    text-align: center;
  }

  .titleNote {
    font-size: $font-sz--sm;
    font-family: $font-family--regular;
    color: $c-page-focused-fg;
    text-align: center;
    display: block;
    margin-bottom: $gap--md;
  }

  .bold {
    font-family: $font-family--semi-bold;
    line-height: $line-h--l2;
    margin: $gap--xs 0;
    font-size: $font-sz--sm;
  }

  .error {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin: $gap--sm 0;
    display: inline-block;
  }

  .icon {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/calendar-dark.svg');
    background-repeat: no-repeat;
  }

  .bookingValue {
    font-family: $font-family--bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $gap--md;
  }

  .total {
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
    color: $c-text--fg;
  }

  .totalToday {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
    color: $c-text--fg;
  }

  .policyContainer {
    margin-top: $gap--md;
  }

  .policyText {
    padding: $gap--md;
    font-size: $font-sz--md;
    line-height: $line-h--l2;
    font-family: $font-family--regular;
    color: $c-item--slick--fg;
  }

  &.neutral {
    .policyText,
    .totalToday,
    .total {
      color: $c-item--neutral--fg;
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .policyText {
      color: $c-item--dark--fg;
    }

    .totalToday,
    .total {
      color: $c-text--dark--fg;
    }

    .icon {
      filter: brightness(10);
    }

    .error {
      color: $c-alert--dark--fg;
    }
  }
}
