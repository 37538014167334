@import 'src/styles/v2/all.scss';

.component {
  .cardNumberLabel {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
    color: $c-page--slick--fg;
    margin-bottom: $gap--sm;
    display: block;
  }

  .buttonContainer {
    text-align: left;
    margin: $gap--xs 0 $gap--xs negative(12px);
  }

  .textUnderline {
    text-decoration: underline;
  }

  .linkToProfile {
    font-family: $font-family--semi-bold;
    padding: $gap--md;
    display: inline-block;
    text-decoration: underline;
    color: $c-text--fg;
    &:hover,
    &:visited {
      color: $c-text--fg;
    }
  }

  .error {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin: $gap--sm 0;
    display: inline-block;
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .brandImg {
    max-width: $gap--xl;
    height: auto;
    display: block;
  }

  &.dark {
    .cardNumberLabel {
      color: $c-page--dark--fg;
    }

    .error {
      color: $c-alert--dark--fg;
    }

    .brandImg {
      filter: brightness(2);
    }

    .linkToProfile {
      color: $c-page--dark--fg;
    }
  }
}
