@import 'src/styles/v2/all.scss';

$policy--h: 40px;
$policy--h--mobile: 60px;
$image--slick: linear-gradient(109deg, #f5e2fe -48%, #faf5fc 99%);
$image--dark: linear-gradient(108deg, #3e0869 0%, #821bb8 99%);

.component {
  .gradientWaitlist {
    padding: $gap--md;
    text-align: center;
    border-radius: $border--r--xl;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
    font-family: $font-family--semi-bold;
    background-image: $image--slick;
    margin-top: $gap--md;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $gap--sm 0;
  }

  .divider {
    width: 100%;
    border-top: $border--q--xs solid $c-page--slick--bd;
  }

  .boldText {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .inline {
    display: inline;
  }

  .textSecondary {
    color: $c-text-secondary--slick--fg;
    font-size: $font-sz--sm;
    font-family: $font-family--regular;
  }

  .rowValue {
    color: $c-text--fg;
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
  }

  .rowValueTotal {
    color: $c-text--fg;
    font-size: $line-h--md;
    font-family: $font-family--semi-bold;
  }

  .servicesContainer,
  .totalContainer {
    padding: $gap--sm 0;
  }

  .addressContainer {
    margin-top: $gap--md;
  }

  .policyContainer {
    margin-top: $gap--md;
    position: relative;
  }

  .policy {
    max-height: $policy--h;
    overflow: hidden;
    margin-bottom: 0;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
    &.isOpen {
      max-height: 100%;
      overflow: auto;
    }

    @include breakpoint(mobile) {
      max-height: $policy--h--mobile;
    }
  }

  .buttonMore {
    font-size: $font-sz--sm;
    background: $c-page--slick--bg;
    color: $c-text--fg;
    border: none;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .policyTitle,
  .subTitle {
    display: flex;
    align-items: center;
    margin-top: $gap--lg;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .policyAgree {
    color: $c-text--fg;
    font-family: $font-family--semi-bold;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
    margin-top: $gap--md;

    display: flex;
    align-items: center;
  }

  .agreeText {
    margin-left: $gap--md;
  }

  .subTitle {
    margin-bottom: $gap--md;
  }

  .icon {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/policy-dark.svg');
    background-repeat: no-repeat;
  }

  .iconAppointment {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/calendar-dark.svg');
    background-repeat: no-repeat;
  }

  .noteTitle {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
    margin-bottom: $gap--sm;
    margin-top: $gap--lg;
  }

  .textarea {
    height: $textarea--h--min;
    min-height: $textarea--h--min;
    transition: height 0.3s ease;
    background-color: $c-input--slick--bg;
    border: $border--r--xs solid $c-input--slick--bd;
    color: $c-input--slick--fg;
    &:focus,
    &:active {
      height: $textarea--h--max;
      border-color: $c-input--slick--focus--bd;
      background-color: $c-input--slick--focus--bg;
    }
  }

  .variablePricingNote {
    font-size: $font-sz--xs;
    padding-top: $gap--md;
  }
  .policyText {
    padding: 0 $gap--md $gap--md;
    font-size: $font-sz--md;
    line-height: $line-h--l2;
    font-family: $font-family--regular;
    color: $c-item--slick--fg;
  }

  .link {
    color: $c-text--fg;
    text-decoration: underline;
  }

  .addressText {
    padding: 0 $gap--md $gap--md;
    font-size: $font-sz--md;
    line-height: $line-h--l2;
    font-family: $font-family--regular;
    color: $c-text--fg;
    text-decoration: underline;
  }

  &.neutral {
    .textarea {
      background-color: $c-input--neutral--bd;
      border-color: $c-input--neutral--bd;
      color: $c-input--neutral--fg;
      &:focus {
        border-color: $c-input--neutral--focus--bd;
        background-color: $c-input--neutral--focus--bg;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .gradientWaitlist {
      background-image: $image--dark;
      color: $c-page--dark--fg;
    }

    .policyText {
      color: $c-item--dark--fg;
    }

    .addressText,
    .link {
      color: $c-text--dark--fg;
    }

    .textarea {
      background-color: $c-input--dark--bd;
      border-color: $c-input--dark--bd;
      color: $c-input--dark--fg;
      &:focus {
        border-color: $c-input--dark--focus--bd;
        background-color: $c-input--dark--focus--bg;
      }
    }

    .divider {
      border-color: $c-page--dark--bd;
    }

    .icon,
    .iconAppointment {
      filter: brightness(10);
    }

    .rowValue,
    .rowValueTotal {
      color: $c-text--dark--fg;
    }

    .textSecondary {
      color: $c-text-secondary--dark--fg;
    }

    .buttonMore {
      background: $c-page--dark--bg;
      color: $c-text--dark--fg;
    }
    .policyAgree {
      color: $c-text--dark--fg;
    }
  }
}
