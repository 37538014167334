@import 'src/styles/v2/all.scss';

$c-avatar--off--bg: linear-gradient(132deg, $white-secondary -32%, $silver-tertiary 100%);
$c-avatar--off--bd: $silver-tertiary;

$c-card--w: 343px;
$c-card--h: 180px;

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;
  min-height: 100vh;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $navbar--h $gap--md $footer--h;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: $gap--md 0;
  }

  .headerButtons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .arrowGoBack {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: $gap--md;
  }

  .headerStatus {
    @include square($icon-sz--l3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border--r--mx;
    background-color: $silver;
    margin: $gap--xl auto;
  }

  .statusIcon {
    @include square($icon-sz--xl);
    background-size: $icon-sz--xl;
    background-image: url('~assets/images/icons/credit-card-dark.svg');
    opacity: 0.5;
  }

  .title,
  .headerTitle {
    font-size: $font-sz--l4;
    font-family: $font-family--bold;
    text-align: center;
    margin-bottom: $gap--lg;
  }

  .subTitle {
    display: flex;
    align-items: center;
    margin-bottom: $gap--lg;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .message {
    text-align: center;
    margin: $gap--lg 0;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .buttonLogout {
    font-size: $font-sz--sm;
    padding: 0 $gap--sm;
  }

  .linkContainer {
    display: flex;
  }

  .linkToLocations {
    @include square($icon-sz--lg);
    background-size: $icon-sz--lg;
    background-image: url('~assets/images/icons/building-dark.svg');
    display: block;
    margin: 0 $gap--xs 0 0;
    background-repeat: no-repeat;
    background-position: center;
  }

  .avatar {
    @include square($icon-sz--l3);
    min-width: $icon-sz--l3;
    background-image: $c-avatar--slick--bg;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: $border--r--mx;
    margin-right: $gap--md;
    box-shadow: inset 0 0 0 $border--q--sm $c-page--slick--bg;
    border: $border--q--sm solid $c-avatar--slick--bd;
    color: $c-avatar--slick--fg;
    font-size: $font-sz--l2;
    font-family: $font-family--semi-bold;
    &.isOff {
      background-image: $c-avatar--off--bg;
      border-color: $c-avatar--off--bd;
    }
  }

  .iconPayment,
  .iconUser {
    display: block;
    margin-right: $gap--sm;
    background-repeat: no-repeat;
  }

  .iconPayment {
    @include square($icon-sz--lg);
    background-size: $icon-sz--lg;
    background-image: url('~assets/images/icons/credit-card-dark.svg');
  }

  .iconUser {
    @include square(20px);
    background-size: 20px;
    background-image: url('~assets/images/icons/user-circle-dark.svg');
  }

  .input,
  .phoneContainer input {
    padding: $gap--md;
    border: $border--q--sm solid $c-input--slick--bd;
    background-color: $c-input--slick--bg;
    width: 100%;
    border-radius: $border--r--md;
    &:focus {
      border-color: $c-input--slick--focus--bd;
      background-color: $c-input--slick--focus--bg;
    }
    &.hasError {
      border-color: $c-input--slick--error--bd;
    }
    &::placeholder {
      font-size: $font-sz--md;
    }
  }

  .inputContainer,
  .phoneContainer {
    margin-top: $gap--md;
    &.hasError {
      input {
        border-color: $c-input--slick--error--bd;
      }
    }
  }

  .error {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin: $gap--sm 0;
    display: inline-block;
  }

  .underline {
    text-decoration: underline;
  }

  .inputLabel {
    display: block;
    margin-bottom: $gap--sm;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .passwordContainer {
    position: relative;
  }

  .cardIllustration {
    color: $c-page--slick--bg;
    width: $c-card--w;
    height: $c-card--h;
    border-radius: $border--r--l2;
    background-image: linear-gradient(118deg, #af6dd1 0%, #821bb8 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    @include breakpoint(mobile) {
      width: 100%;
    }

    .top,
    .middle,
    .bottom {
      padding: $gap--md;
    }

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .middle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: $gap--xs;
    }

    .bottom {
      background-color: $purple;
      font-size: $font-sz--sm;
      font-family: $font-family--bold;
    }

    .digits {
      font-size: $font-sz--l4;
      font-family: $font-family--semi-bold;
    }

    .starContainer {
      display: flex;
      flex-direction: row;
    }

    .star {
      display: block;
      margin: 0 $gap--xs;
      &:before {
        content: '✭✭✭✭';
      }
    }

    .imgChip {
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 32px;
      background-image: url('~assets/images/icons/card-chip.svg');
    }

    .visaLogo {
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('~assets/images/icons/logos-visa.svg');
      width: 50px;
      height: 16px;
    }

    .cardBottom {
      background-color: $purple;
    }
  }

  .removeCardContainer {
    margin-top: $gap--sm;
    margin-left: negative($gap--sm);
  }

  &.neutral {
    .error {
      color: $c-alert--neutral--fg;
    }

    .input,
    .phoneContainer input {
      &:focus {
        border-color: $c-input--neutral--focus--bd;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .avatar {
      background-image: $c-avatar--dark--bg;
      box-shadow: inset 0 0 0 $border--q--sm $c-page--dark--bg;
      border: $border--q--sm solid $c-avatar--dark--bd;
      color: $c-avatar--dark--fg;
    }

    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }

    .iconPayment,
    .iconUser,
    .arrowImg,
    .linkContainer {
      filter: brightness(10);
    }

    .error {
      color: $c-alert--dark--fg;
    }

    .phoneContainer {
      &.hasError {
        input {
          border-color: $c-input--slick--error--bd;
        }
      }
    }

    .input,
    .phoneContainer input {
      border-color: $c-input--dark--bd;
      background-color: $c-input--dark--bg;
      color: $c-input--dark--fg;

      &:focus {
        border-color: $c-input--dark--focus--bd;
        background-color: $c-input--dark--focus--bg;
      }

      &.hasError {
        border-color: $c-input--dark--error--bd;
      }
    }
  }
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: $gap--md;
}

.modalContent {
  text-align: center;
}

.title {
  font-family: $font-family--bold;
  font-size: $font-sz--l3;
  line-height: $line-h--l6;
  padding: $gap--lg 0;
  max-width: 80%;
  margin: 0 auto;
}

.subTitle {
  font-size: $font-sz--md;
  line-height: $line-h--l3;
  font-family: $font-family--semi-bold;
  margin-bottom: $gap--lg;
}
