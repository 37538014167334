// --mn = minimal
// --mx = maximal

// --s3 = extra extra extra small
// --s2 = extra extra small
// --xs = extra small
// --sm = small
// --md = medium
// --lg = large
// --xl = extra large
// --l2 = extra extra large
// --l3 = extra extra extra large

$icon-sz--s2: 6px;
$icon-sz--xs: 12px;
$icon-sz--sm: 16px;
$icon-sz--md: 18px;
$icon-sz--lg: 24px;
$icon-sz--xl: 32px;
$icon-sz--l1: 40px;
$icon-sz--l2: 48px;
$icon-sz--l3: 64px;
