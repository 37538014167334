@import 'src/styles/v2/all.scss';

$font-sz--bubble: 10px;

.component {
  .navigation {
    height: $navbar--h;
    background-color: $c-nav--slick--bg;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    z-index: $z-navigation;
  }

  .navItem {
    flex: 0 0 33.33%;
    text-align: center;
    list-style-type: none;
    display: flex;
    height: 100%;
  }

  .navLink {
    color: $c-nav--slick--fg;
    width: 100%;
    cursor: pointer;
    &.selected {
      border-bottom: $border--q--lg solid $c-nav--slick--focus--bd;
      .iconProfile {
        background-image: url('~assets/images/icons/user-circle-selected.svg');
      }
      .iconBooking {
        background-image: url('~assets/images/icons/calendar-white-selected.svg');
      }
      .iconPound {
        background-image: url('~assets/images/icons/currency-pound-selected.svg');
      }
    }
  }

  .navContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $font-sz--xs;
    font-family: $font-family--semi-bold;
    padding-top: $gap--s2;
  }

  .iconProfile,
  .iconBooking,
  .iconPound {
    margin-top: $gap--xs;
    background-repeat: no-repeat;
    background-position: center;
    @include square($icon-sz--xl);
  }

  .iconProfile {
    background-image: url('~assets/images/icons/user-circle-white.svg');
  }

  .iconBooking {
    background-image: url('~assets/images/icons/calendar-white.svg');
  }

  .iconPound {
    background-image: url('~assets/images/icons/currency-pound.svg');
  }

  .iconContainer {
    position: relative;
  }

  .bubble {
    position: absolute;
    right: 0;
    top: 8px;
    @include square($icon-sz--sm);
    border-radius: $border--r--mx;
    background-color: $red-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-sz--bubble;
  }
  &.neutral {
    .navigation {
      background-color: $c-nav--neutral--bg;
    }
  }

  &.dark {
    .navigation {
      background-color: $c-nav--dark--bg;
    }

    .navLink {
      &.selected {
        border-bottom-color: $c-nav--dark--focus--bd;
      }
    }
  }
}
