@import 'src/styles/v2/all.scss';

.component {
  .locationButtonContainer {
    text-align: center;

    .textUnderline {
      text-decoration: underline;
    }
  }

  .welcomeText {
    text-align: center;
    @include breakpoint(mobile) {
      font-size: $font-sz--sm;
      line-height: $line-h--lg;
    }
  }

  .readMore {
    display: inline;
    color: $c-text--fg;
    padding-left: $gap--xs;
    cursor: pointer;
  }

  &.dark {
    .readMore {
      color: $pink-secondary;
    }
  }
}
