@import '../mixin/breakpoints.scss';
@import '../modules/base.scss';

.dark {
  background-color: $dark-gray;
  .react-add-to-calendar__button {
    color: $c-text--dark--fg;
  }

  &.gray-bgr {
    background-color: $dark-gray;
  }
  .app-footer {
    background-color: $mid-gray;
    box-shadow: 0 -0.5px 0 0 $light-gray;
  }

  .form-input .label-name {
    color: $label;
  }
  .card-number-field {
    background-color: $gray;
    color: $white;
    &:focus {
      background-color: $mid-gray;
    }
  }
  #cardUpdateBtn {
    background-color: transparent;
    border-color: transparent;
    color: $violet-mid;
  }
  .form-input input,
  .search-input {
    background-color: $gray;
    color: $white !important;
    -webkit-box-shadow: 0 0 0px 1000px $gray inset;

    &:focus {
      background-color: $mid-gray;
      color: $white;
    }
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
  .header-notes {
    background-color: $mid-gray;
    color: $light-white;
  }
  .app-content {
    color: $light-white;
    &.color-white {
      .form-wrapper {
        background-color: $dark-gray;
      }
      .header-title {
        color: $white;
      }
    }
    .extra-title {
      color: $white;
    }
  }
  .app-header .header-title,
  & .color-title .app-header .header-title {
    color: $white;
  }
  .app-header {
    .powered-by {
      color: $violet-mid;
      > img {
        filter: invert(21%) sepia(26%) saturate(84%) hue-rotate(390deg) brightness(220%) contrast(125%);
      }
    }
  }

  .input-info,
  .password-match-info,
  .password-match-info > strong {
    color: $light-white;
  }
  .divider > span {
    background-color: $mid-gray;
    color: $white;
  }
  .divider:before {
    background-color: $white;
  }
  // my profile
  #myProfile .sub-tabs .tab {
    background-color: $dark-gray;
    border: 1px solid $violet;
    color: $white;
  }
  #myProfile .sub-tabs .tab.selected {
    background-color: $violet;
  }
  #myProfile .cb-square label > div {
    color: $white;
  }
  #myProfile .payment-info {
    color: $light-white;
  }
  .profile-header {
    color: $white;
  }
  .profile-header img {
    filter: invert(21%) sepia(26%) saturate(84%) hue-rotate(390deg) brightness(220%) contrast(125%);
  }
  //

  .link-more .show-more-text {
    background-color: $gray;
    color: $light-white;
  }
  .salon-picker {
    background-color: $gray;
    color: $light-white;
  }
  .salon-picker-container .h5 {
    color: $white;
  }
  .salon-picker-list .picker-item {
    background-color: $gray;
    color: $light-white;
    border-color: $dark-white;
  }
  #myBook h3,
  #myBook h2,
  #myProfile h3,
  #myProfile h2 {
    color: $white;
  }
  .salon-location-item {
    background-color: $gray;
    color: $white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }
  .salon-location-item .item-image {
    background-color: $light-gray;
  }
  .history-item {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }
  .history-item .item-content > div {
    color: $white;
  }
  .history-item .item-content .service-list .color-dot,
  .history-item .item-content .service-list .service-name {
    color: $light-white;
  }
  .history-item .item-image img {
    filter: invert(0%) sepia(50%) saturate(84%) hue-rotate(390deg) brightness(150%) contrast(170%);
  }
  .ico_arrow_forward_white {
    filter: invert(0%) sepia(50%) saturate(84%) hue-rotate(390deg) brightness(66%) contrast(100%);
  }
  // checkbox,
  input[type='checkbox']:checked + label span {
    filter: invert(0%) sepia(18%) saturate(84%) hue-rotate(390deg) brightness(240%) contrast(67%);
  }
  // slider arrow
  .calendar-slider .slick-next.custom,
  .calendar-slider .slick-prev.custom,
  .wapper-slider .slick-arrow.slick-prev {
    filter: invert(40%) sepia(0%) saturate(84%) hue-rotate(390deg) brightness(150%) contrast(170%);
  }
  .calendar-slider .slick-slide .day-week {
    color: $white;
  }
  .calendar-slider .slick-slide .day-number {
    color: $light-white;
  }
  .banner {
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }
  .banner.upcoming {
    background-color: $light-gray;
  }

  .group-shape.select-category {
    background-color: $gray;
    color: $white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
    .the-service-shape {
      background-color: $dark-gray;
      color: $white;
      box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
    }
    .link-more .show-more-text {
      background-color: $dark-gray;
    }
    .count-selected-services {
      background-color: $violet-mid;
      color: $page-title;
    }
    > img {
      filter: invert(60%) sepia(0%) saturate(0%) hue-rotate(390deg) brightness(240%) contrast(67%);
    }
  }

  .category-wrapper .category-name {
    color: $white;
  }
  // services

  .the-service-shape {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }
  .item-description .show-more-text {
    background-color: $gray;
    color: $white;
  }

  .app-header.small-title .header-title {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }
  .container {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
    & .text-header,
    & .text-subheader {
      color: $light-white;
    }
  }

  // dropdown mounts
  .dropdown-container .dropdown-btn .arr-icon img {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(390deg) brightness(240%) contrast(67%);
  }

  .dropdown-container .dropdown-btn,
  .dropdown-container .dropdown-list {
    background-color: $gray;
    color: $white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }
  .dropdown-container.open {
    border-bottom: none;
    & .dropdown-btn {
      border: 1px solid $violet;
    }
  }
  .dropdown-container .dropdown-list ul li:hover {
    background-color: $mid-gray;
  }
  // time-services under slider
  .the-time-shape {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }

  // my book tab
  #myBook .upcoming-item {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }

  #myBook .upcoming-item .item-container .request-time {
    filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(390deg) brightness(240%) contrast(100%);
  }
  .salon-request-item .item-container .request-time {
    filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(390deg) brightness(114%) contrast(85%);
  }
  .salon-request-item .item-container .request-time > strong,
  .salon-request-item .item-container .request-time > span {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(390deg) brightness(0%) contrast(100%);
  }

  // my feed - in notifications tab
  .salon-request-item {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }

  .request-salon-name {
    background-color: #af6dd1;
    color: white;
    box-shadow: 0 1px 4px 0 #282828;
  }

  .popup {
    background-color: $gray;
    color: $light-white;
    .close-icon:before,
    .close-icon:after {
      background-color: $light-white;
    }
    & h4 {
      color: $light-white;
    }
    & .note {
      color: $white;
    }
    input.checkbox-switch[type='radio'] + label {
      color: $light-white;
    }

    input[type='radio']:checked + label span {
      filter: invert(0%) sepia(18%) saturate(84%) hue-rotate(390deg) brightness(240%) contrast(67%);
    }
  }

  .payment-disclaimer-red,
  .salon-request-item .item-container .btn-decline {
    color: #ed4563;
  }

  .mybook-btn,
  .app-content .put-on-wait-list button {
    background-color: $white;
    color: $brand-blue;
  }

  .btn.btn-border {
    color: $white;
    border: 1px solid $white;
  }
  .btn.btn-border.account {
    color: $white;
    border: 1px solid $violet;
  }
  .app-content .react-add-to-calendar__button {
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
    color: $white;
    background-color: transparent !important;
    border: 1px solid $white !important;
  }
  .app-content .react-add-to-calendar__dropdown {
    background-color: $mid-gray;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }

  .book-again {
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }
  // summary container
  .summary-container .summary-content {
    background-color: $gray;
    color: $light-white;
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 68);
  }

  .summary-container .showMore {
    color: $white;
    background-color: $gray;
  }
  .summary-container .summary-content .flex-item span.red {
    color: $warning--dark;
  }

  #myBook .upcoming-item .item-container .request-time > strong,
  .appointment-details-text,
  .textarea-label,
  .flex-item,
  .summary-container .summary-content .flex-item,
  .order-services ul .item,
  .text-violet,
  .btn.btn-link,
  .booking-info .pay-total,
  .cardInfo .label-name,
  .booking-info .pay-info,
  .the-time-shape .item-text .name,
  .service-select,
  .request p,
  .salon-request-item .item-container h3,
  .salon-request-item .item-container .service-list > li .service-name .text,
  .salon-request-item .item-container .service-list > li .service-price,
  .salon-request-item .item-container .service-list > li .stylist-name,
  .breadcrumb,
  .form-input.password-field .show-hide,
  .form-input.password-field .password-link,
  .sms-password-message,
  .footer-text,
  .app-header .header-breadcrumb,
  .header-title-new-line,
  .the-service-shape .item-text .name,
  .service-title {
    color: $white;
  }

  .area-note.text-dark,
  #myBook .upcoming-item .item-container .request-time > span,
  #myBook .upcoming-item .item-container .service-list > li .service-price,
  #myBook .upcoming-item .item-container .service-list > li .stylist-name,
  .sum,
  .order-services ul .item p,
  .app-content .react-add-to-calendar__dropdown ul li a,
  .booking-info p,
  .payment-disclaimer,
  #myBook .upcoming-item .item-container h3,
  #myBook .upcoming-item .item-container .service-list > li .service-name,
  .app-content .cta,
  .fully-booked-cta,
  .the-service-shape .item-option .price,
  .note-text,
  .item-description.open .note-text,
  .the-service-shape .item-text .time,
  .the-time-shape .item-text .time,
  .history-item .item-content .stylist {
    color: $light-white;
  }
  textarea {
    background-color: $mid-gray;
    border: 1px solid $light-gray;
    color: $white;
    &:focus {
      background-color: $mid-gray;
      border: 1px solid $violet;
    }
  }

  .spinner-container {
    background-color: $dark-gray; // rgba(10, 21, 20, 0.5);
  }
  .load-spinner {
    border-left: 4px solid $white;
    border-right: 4px solid $white;
    border-top: 4px solid $white;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid $violet;
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: 0 0 0px 1000px $gray inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .calendar-slider .slick-item::before,
  .app-header .header-notes a {
    color: $violet-mid;
  }
  .calendar-slider .slick-item.disabled::before {
    color: #ff6470;
  }
  // international phone number input
  .PhoneInputCountrySelect {
    > option {
      background-color: $mid-gray;
      color: $light-white;
    }
  }
}
