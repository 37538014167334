@import 'src/styles/v2/all.scss';

$bottom-padding: $footer--h + $gap--l2;

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;
  min-height: 100vh;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: 0 $gap--md $bottom-padding;
  }

  .subTitle {
    display: flex;
    align-items: center;
    margin-top: $gap--lg;
    margin-bottom: $gap--md;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .icon {
    @include square(20px);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/lock-closed-dark.svg');
    background-repeat: no-repeat;
    background-size: 20px;
  }

  .resetInfo {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    margin-bottom: $gap--lg;
    display: block;
  }

  .resetNote {
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
    margin-top: $gap--md;
    display: block;
  }

  .codeInfo {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    margin-bottom: $gap--md;
    display: block;
  }

  .codeLabel {
    font-family: $font-family--bold;
    font-size: $font-sz--md;
    line-height: $line-h--l2;
  }

  .input,
  .phoneContainer input {
    padding: $gap--md;
    border: $border--q--sm solid $c-input--slick--bd;
    background-color: $c-input--slick--bg;
    width: 100%;
    border-radius: $border--r--md;
    &:focus {
      border-color: $c-input--slick--focus--bd;
      background-color: $c-input--slick--focus--bg;
    }
    &.hasError {
      border-color: $c-input--slick--error--bd;
    }
    &::placeholder {
      font-size: $font-sz--md;
    }
  }

  .phoneContainer {
    margin-top: $gap--md;
    &.hasError {
      input {
        border-color: $c-input--slick--error--bd;
      }
    }
  }

  .error {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin: $gap--sm 0;
    display: inline-block;
  }

  .inputLabel {
    display: block;
    margin-bottom: $gap--sm;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .rulesTitle {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    margin-bottom: $gap--md;
    display: block;
  }

  .passwordRules {
    margin: 0;
    padding: 0 $gap--md;
    font-size: $font-sz--sm;
    > li {
      list-style-type: none;
    }
  }

  &.neutral {
    .input,
    .phoneContainer input {
      &:focus {
        border-color: $c-input--neutral--focus--bd;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .icon {
      filter: brightness(10);
    }

    .error {
      color: $c-alert--dark--fg;
    }

    .phoneContainer {
      &.hasError {
        input {
          border-color: $c-input--slick--error--bd;
        }
      }
    }

    .input,
    .phoneContainer input {
      border-color: $c-input--dark--bd;
      background-color: $c-input--dark--bg;
      color: $c-input--dark--fg;

      &:focus {
        border-color: $c-input--dark--focus--bd;
        background-color: $c-input--dark--focus--bg;
      }

      &.hasError {
        border-color: $c-input--dark--error--bd;
      }
    }
  }
}
