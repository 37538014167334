@import 'src/styles/v2/all.scss';

$data-time--w: 120px;

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;
  min-height: $page--h;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--l3 $gap--md $footer--h;
  }

  .footerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .link {
    width: 100%;
    text-align: center;
  }

  .icon {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/calendar-dark.svg');
    background-repeat: no-repeat;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $gap--md 0;
  }

  .arrowGoBack,
  .arrowGoDetails {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .arrowGoDetails {
    border: none;
    transform: rotate(180deg);
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    margin-top: $gap--lg;
    margin-left: negative($gap--sm);
    margin-right: negative($gap--sm);
  }

  .salonItem {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: $gap--sm;
    flex-basis: 100%;
    border: $border--q--xs solid $c-item--slick--bd;
    border-radius: $border--r--xl;
    padding: $gap--md;
    font-family: $font-family--semi-bold;
  }

  .dateContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 $data-time--w;
    padding-right: $gap--md;
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;

    .day {
      font-size: $font-sz--l7;
      font-family: $font-family--bold;
    }
  }

  .futureContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    cursor: pointer;
  }

  .count {
    @include square($icon-sz--lg);
    border-radius: $border--r--mx;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-count--bg;
    color: $c-count--fg;
    font-size: $icon-sz--xs;
    margin-left: $gap--sm;
    &.isSelected {
      background-color: $c-count--selected--bg;
    }
  }

  .smallText {
    font-size: $font-sz--xs;
    font-family: $font-family--regular;
  }

  // past
  .procedureContainer {
    width: 100%;
  }

  .serviceInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $silver-primary;
    font-size: $font-sz--sm;
  }

  .title {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
    margin-bottom: $gap--xs;
  }

  .subTitle {
    color: $c-text-secondary--slick--fg;
    font-size: $font-sz--sm;
    margin-bottom: $gap--xs;
  }

  .flexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $gap--md;
  }

  // upcoming
  .buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-sz--sm;
  }

  .depositText {
    font-size: $font-sz--sm;
    margin: $gap--sm 0;
  }

  .servicesText {
    font-size: $font-sz--sm;
    margin-bottom: $gap--xs;
  }

  .badgeContainer {
    display: inline-block;
    margin-left: negative($gap--xs);
  }

  .rebookContainer {
    margin-right: negative($gap--xs);
  }
  .space {
    margin-left: $gap--sm;
  }

  .textFocused {
    color: $c-text--fg;
  }

  .emptyState {
    text-align: center;
    width: 100%;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  &.neutral {
    .count {
      &.isSelected {
        background-color: $c-count--neutral--selected--bg;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .icon,
    .arrowImg {
      filter: brightness(10);
    }

    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }

    .textFocused {
      color: $c-text--dark--fg;
    }

    .count {
      &.isSelected {
        background-color: $c-count--dark--selected--bg;
      }
    }
  }
}
