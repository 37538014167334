@import 'src/styles/v2/all.scss';

$image--slick: linear-gradient(101deg, #f5e2fe -48%, #faf5fc 100%);
$image--dark: linear-gradient(101deg, #3e0869 0%, #821bb8 100%);
$image--neutral: linear-gradient(105deg, #505773 0%, #192340 100%);

.component {
  display: block;
  background-image: $image--slick;
  border-radius: $border--r--l2;
  margin: $gap--md 0;
  padding: $gap--md;
  color: $c-page--slick--fg;
  &:hover,
  &:active {
    color: $c-page--slick--fg;
  }
  .conteiner {
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    justify-content: flex-start;
  }

  .text {
    margin-left: $gap--lg;
  }
  .title {
    font-family: $font-family--bold;
    font-size: $font-sz--md;
  }

  .subtitle {
    font-family: $font-family--regular;
    font-size: $font-sz--sm;
  }

  &.dark {
    background-image: $image--dark;
    color: $c-page--dark--fg;
    &:hover,
    &:active {
      color: $c-page--dark--fg;
    }
    .arrow,
    .icon {
      filter: brightness(10);
    }
  }
}
