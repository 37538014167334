@import 'src/styles/v2/all.scss';

.cardInfo {
  margin-top: $gap--md;
}

.logout-btn {
  position: absolute;
  right: $gap--md;
  top: $gap--md;
}

.mybook-btn {
  width: 94px;
  height: 26px;
  font-family: 'ProximaNova-sb';
  font-size: 9px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #192340;
  color: #192340;
  line-height: 1.11;
  text-align: center;
  position: absolute;
  right: $gap--md;
  top: $gap--md;
  padding: 0;

  @include breakpoint(mobile) {
    width: 70px;
  }
}

.location-group-btn {
  width: 70px;
  height: 26px;
  font-family: 'ProximaNova-sb';
  font-size: 9px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #192340;
  color: #192340;
  line-height: 1.11;
  text-align: center;
  position: absolute;
  left: $gap--md;
  top: $gap--md;
  padding: 0;
}

.p-TermsText.TermsText {
  display: none;
}
