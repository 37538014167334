@import 'src/styles/v2/all.scss';

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;
  padding-top: $navbar--h;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--md;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: $gap--md 0;
  }

  .arrowGoBack {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .arrowImg {
      filter: brightness(10);
    }

    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }
  }
}
