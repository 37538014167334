@import 'src/styles/v2/all.scss';

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;
  min-height: $page--h;
  font-family: $font-family--semi-bold;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--l3 $gap--md $footer--h;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $gap--md 0;
  }

  .arrowGoBack {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .badgeContainer {
    display: inline-block;
    margin-left: negative($gap--xs);
  }

  .space {
    margin-left: $gap--sm;
  }

  .pageTitle {
    display: flex;
    align-items: center;
    margin-top: $gap--lg;
    margin-bottom: $gap--md;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
  }

  .icon {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/calendar-dark.svg');
    background-repeat: no-repeat;
  }

  .serviceInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $silver-primary;
    font-size: $font-sz--sm;
  }

  .title {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
    margin-bottom: $gap--xs;
  }

  .flexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $gap--md 0;
    border-bottom: 1px solid $c-page--slick--bd;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $gap--sm 0;
  }

  .textFocused {
    color: $c-text--fg;
  }

  .arrow {
    @include square($icon-sz--l1);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
  }

  .policyContainer {
    margin-top: $gap--md;
  }

  .policyText {
    padding: $gap--md;
    font-size: $font-sz--md;
    line-height: $line-h--l2;
    font-family: $font-family--regular;
    color: $c-item--slick--fg;
  }

  &.neutral {
    .policyText {
      color: $c-item--neutral--fg;
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .policyText {
      color: $c-item--dark--fg;
    }

    .subTitle {
      color: $c-page--dark--fg;
    }

    .icon,
    .arrowImg {
      filter: brightness(10);
    }

    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }

    .flexRow {
      border-color: $c-page--dark--bd;
    }

    .textFocused {
      color: $c-text--dark--fg;
    }
  }
}

.modalContent {
  text-align: center;
}

.modalTitle {
  font-family: $font-family--bold;
  font-size: $font-sz--l3;
  line-height: $line-h--l6;
  padding: $gap--lg 0;
  max-width: 80%;
  margin: 0 auto;
}

.modalSubTitle {
  font-size: $font-sz--md;
  line-height: $line-h--l3;
  font-family: $font-family--regular;
  margin-bottom: $gap--lg;
}

.textBold {
  font-family: $font-family--semi-bold;
}

.modalGap {
  margin: $gap--md;
}
