@import 'src/styles/v2/all.scss';

$item--w: 320px;

.component {
  position: fixed;
  top: $navbar--h;
  background: $c-page--slick--bg;
  bottom: 0;
  left: 0;
  right: 0;
  color: $c-page--slick--fg;
  z-index: $z-patch-modal;
  overflow: auto;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--md;
  }

  .logo {
    font-family: $font-family--semi-bold;
    font-size: $font-sz--xs;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-text--fg;

    @include breakpoint(mobile) {
      margin-top: $gap--sm;
    }
  }
  .slickLogo {
    height: $icon-sz--lg;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: $gap--md 0;
  }
  .arrowGoBack {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .readMore {
    display: inline;
    color: $c-text--fg;
    padding-left: $gap--xs;
    cursor: pointer;
  }

  .title {
    font-size: $font-sz--lg;
    font-family: $font-family--bold;
    margin-bottom: $gap--lg;

    display: flex;
    align-items: center;
  }

  .icon {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/dropper.svg');
    background-repeat: no-repeat;
  }

  .text {
    font-size: $font-sz--md;
    margin-bottom: $gap--lg;
  }

  .focused {
    color: $c-text--fg;
    margin-bottom: $gap--lg;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  .selectItem {
    margin: $gap--sm 0;
    padding: $gap--md;
    border-radius: 8px;
    border: solid $border--q--xs $c-item--slick--bd;
    display: flex;
    justify-content: space-between;
    max-width: $item--w;
    @include breakpoint(mobile) {
      max-width: 100%;
    }

    .name {
      font-size: $font-sz--md;
      font-family: $font-family--bold;
    }

    &.selected {
      border: solid $border--q--sm $c-item--slick--selected--bd;
    }
  }

  &.neutral {
    .selectItem {
      border-color: $c-item--neutral--bd;

      &.selected {
        border-color: $c-item--neutral--selected--bd;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .selectItem {
      border-color: $c-item--dark--bd;

      &.selected {
        border-color: $c-item--dark--selected--bd;
      }
    }

    .focused {
      color: $pink-secondary;
    }
    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }
    .arrowImg,
    .icon {
      filter: brightness(10);
    }

    .readMore {
      color: $pink-secondary;
    }
  }
}
