@import 'src/styles/v2/all.scss';

.component {
  background-color: $c-page--slick--bg;

  > div {
    @include breakpoint(mobile) {
      flex-basis: 100%;
    }
  }

  &.neutral {
    background-color: $c-page--neutral--bg;
  }

  &.dark {
    background-color: $c-page--dark--bg;
  }
}
