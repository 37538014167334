@import 'src/styles/v2/all.scss';

.component {
  .flex {
    display: flex;
    flex-wrap: wrap;
  }

  .title {
    font-family: $font-family--bold;
    font-size: $font-sz--lg;
    line-height: $line-h--l3;
    display: block;
    margin: $gap--md 0;
  }

  .serviceName {
    font-family: $font-family--bold;
    font-size: $font-sz--lg;
    @include breakpoint(mobile) {
      margin-top: $gap--sm;
    }
  }

  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: $gap--sm 0;
    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }

  .buttonSpace {
    margin-right: $gap--sm;
  }

  .gapSm {
    padding: 0 $gap--sm;
    white-space: nowrap;
  }

  &.neutral {
  }

  &.dark {
  }
}
