/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2017 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      FontSite Inc.
 * License URL: https://www.fontspring.com/licenses/fontsite/webfont
 *
 *
 */
@font-face {
  font-family: 'Avalon'; //'Avalon-Book'
  src: url('~assets/fonts/Avalon-Book-webfont.woff2') format('woff2'),
    url('~assets/fonts/Avalon-Book-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon'; //'Avalon-Book'
  src: url('~assets/fonts/Avalon-Book-webfont.woff2') format('woff2'),
    url('~assets/fonts/Avalon-Book-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avalon'; //'Avalon-Book'
  src: url('~assets/fonts/Avalon-Book-webfont.woff2') format('woff2'),
    url('~assets/fonts/Avalon-Book-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon'; //'Avalon-Demi'
  src: url('~assets/fonts/Avalon-Demi-webfont.woff2') format('woff2'),
    url('~assets/fonts/Avalon-Demi-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

// Helvetica for online booking
@font-face {
  font-family: 'Helvetica-l';
  src: url('~assets/fonts/Helvetica-Light.woff2') format('woff2'),
    url('~assets/fonts/Helvetica-Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica-m';
  src: url('~assets/fonts/Helvetica-Medium.woff2') format('woff2'),
    url('~assets/fonts/Helvetica-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica-b';
  src: url('~assets/fonts/Helvetica-Bold.woff2') format('woff2'),
    url('~assets/fonts/Helvetica-Bold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-b';
  src: url('~assets/fonts/proxima-nova-bold.woff2') format('woff2'),
    url('~assets/fonts/proxima-nova-bold.eot') format('embedded-opentype'),
    url('~assets/fonts/proxima-nova-bold.ttf') format('truetype'),
    url('~assets/fonts/proxima-nova-bold.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'ProximaNova-r';
  src: url('~assets/fonts/ProximaNovaA-Regular.woff2') format('woff2'),
    url('~assets/fonts/ProximaNovaA-Regular.eot') format('embedded-opentype'),
    url('~assets/fonts/ProximaNovaA-Regular.ttf') format('truetype'),
    url('~assets/fonts/ProximaNovaA-Regular.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'ProximaNova-sb';
  src: url('~assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('~assets/fonts/ProximaNova-Semibold.eot') format('embedded-opentype'),
    url('~assets/fonts/ProximaNova-Semibold.ttf') format('truetype'),
    url('~assets/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('~assets/fonts/fontawesome-webfont.woff2') format('woff2'),
    url('~assets/fonts/fontawesome-webfont.eot') format('embedded-opentype'),
    url('~assets/fonts/fontawesome-webfont.ttf') format('truetype'),
    url('~assets/fonts/fontawesome-webfont.woff') format('woff');
}

@import 'modules/base.scss';
@import 'partials/icons.scss';
@import 'partials/re-booking.scss';
@import 'partials/spinner.scss';
@import 'vendors/normalize.scss';
@import 'mixin/breakpoints.scss';
@import 'partials/card.scss';
@import 'common/comfirmation-modal.scss';

//online booking css
@import 'partials/layout.scss';

@import 'partials/my-profile.scss';
@import 'partials/salon-picker-group.scss';
// color mode styles
@import 'common/dark-mode.scss';
@import 'common/neutral-mode.scss';
body {
  font-family: 'ProximaNova-r', sans-serif;
  font-style: normal;
  // Font Rendering
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //New background styles - Pink Swoosh
  text-rendering: optimizeLegibility;
}

.pink-bgr {
  padding: 0px 0px 48px;
  background-image: url('~assets/images/onboarding-background-new-violet.svg');

  background-repeat: repeat-x;
  background-position: -2px -204px;
  background-size: 3000px;
}

@media screen and (max-width: 1280px), screen and (max-width: 1366px) {
  body {
    background-position: -3px 0;
    background-size: 145%;
  }
}

@media screen and (max-width: 1024px) {
  body {
    background-position: -205px 0;
    background-size: 200%;
  }
}

@media screen and (max-width: 800px) {
  body {
    background-position: -142px 0;
    background-size: 240%;
  }
}

@media screen and (max-width: 500px) {
  body {
    background-size: 1380px;
  }
}

// Float
.float-left {
  float: left;
}

.float-right {
  float: right;
}

// Label
label {
  cursor: pointer;
}

a {
  text-decoration: none;
  outline: none;
}

// Buttons

.hidden {
  display: none;
}

//-----------------------------------------------------------------
// Clearing
.clearfix:after {
  display: table;
  clear: both;
  content: '';
}

//-----------------------cookie
.banner-btn {
  font-family: 'ProximaNova-sb';
  font-size: 13px;
  line-height: 24px;
  position: absolute;
  bottom: 10px;
  left: calc(50% - 114px);
  height: 24px;
  padding: 0 9px;
  color: #252629;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 1px 3px 0 black;
}

#target-container {
  font-size: 13px;
  position: fixed;
  z-index: 100;
  top: 37%;
  width: 100%;
  color: white;

  & button[aria-label='Close'] {
    font-size: 0;
    line-height: 24px;
    position: absolute;
    top: auto;
    bottom: 10px;
    left: 51%;
    width: 110px;
    height: 24px;
    padding: 0 10px;
    transition: none;
    -webkit-transform: none;
    text-align: center;
    color: #252629;
    border: none;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 1px 3px 0 black;

    &:after {
      font-family: 'ProximaNova-sb';
      font-size: 13px;
      content: 'Accept all';
      color: #252629;
    }
  }

  & .cookie-policy {
    display: block;
    margin-bottom: 25px;

    > a {
      font-size: 13px;
      text-decoration: underline;
      color: white;
    }
  }

  & .inner {
    max-width: 100%;
    margin: 0px auto;

    & .css-17ss3j2 {
      padding: 13px;

      & .css-1d3ybkx {
        max-width: 670px !important;
        margin: 0 auto;
      }
    }
  }
}
