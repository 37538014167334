@import 'src/styles/v2/all.scss';

.container {
  padding-top: $gap--lg;
}

.requestNewLabel {
  color: $c-text--slick--fg;
  background-color: $green--secondary;
  font-size: $font-sz--xs;
  font-family: $font-family--semi-bold;
  border-radius: $border--r--md;
  display: inline-block;
  padding: $gap--sm;
  margin-top: $gap--md;
}

.pageTitle {
  font-family: $font-family--bold;
  font-size: $font-sz--l4;
  line-height: $line-h--l6;
}

.modalContent {
  text-align: center;
}

.title {
  font-family: $font-family--bold;
  font-size: $font-sz--l3;
  line-height: $line-h--l6;
  padding: $gap--lg 0;
  max-width: 80%;
  margin: 0 auto;
}

.subTitle {
  font-size: $font-sz--md;
  line-height: $line-h--l3;
  font-family: $font-family--semi-bold;
  margin-bottom: $gap--lg;
}
