@import 'src/styles/v2/all.scss';

.component {
  position: fixed;
  top: $navbar--h;
  background: $c-page--slick--bg;
  bottom: 0;
  left: 0;
  right: 0;
  color: $c-page--slick--fg;
  z-index: $z-cancel-appointment;
  overflow: auto;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--md;
    @include breakpoint(mobile) {
      min-height: $page--h;
      padding-bottom: $footer--h;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .container {
    text-align: center;
  }

  .title {
    font-family: $font-family--bold;
    font-size: $font-sz--l4;
    line-height: $line-h--l6;
    padding: 0;
    max-width: 80%;
    margin: 0 auto $gap--md;
  }

  .subTitle {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    font-family: $font-family--regular;
    margin-bottom: $gap--lg;
  }

  .textBold {
    font-family: $font-family--semi-bold;
  }

  .gap {
    margin: $gap--md;
  }

  .text {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    font-family: $font-family--regular;
    margin-bottom: $gap--lg;
  }

  .arrowGoBack {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .icon {
    @include square($icon-sz--lg);
    display: block;
    margin-right: $gap--sm;
    background-image: url('~assets/images/icons/calendar-dark.svg');
    background-repeat: no-repeat;
  }

  .text {
    font-size: $font-sz--md;
    margin-bottom: $gap--lg;
  }

  .phoneContainer {
    margin: $gap--md;
  }

  .phoneLink {
    color: $c-text--fg;
    text-decoration: underline;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  .gap {
    margin: $gap--md 0;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .phoneLink {
      color: $pink-secondary;
    }
    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }

    .arrowImg,
    .icon {
      filter: brightness(10);
    }
  }
}
