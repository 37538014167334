// @import 'variables';
// @import 'media-query';
@mixin breakpoint($class) {
  @if $class == xs {
    @media screen and (max-width: 320px) {
      @content;
    }
  } @else if $class == mobile-sm {
    @media screen and (max-width: 480px) {
      @content;
    }
  } @else if $class == mobile {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $class == mobile-large {
    @media screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  } @else if $class == tablet-p {
    @media screen and (max-width: 800px) {
      @content;
    }
  } @else if $class == tablet-l {
    @media screen and (max-width: 1024px) {
      @content;
    }
  } @else if $class == md {
    @media screen and (max-width: 1366px), screen and (max-width: 1280px), screen and (max-width: 1366px) {
      @content;
    }
  } @else if $class == lg {
    @media screen and (max-width: 1280px) {
      @content;
    }
  } @else if $class == mh970 {
    @media screen and (max-height: 970px) {
      @content;
    }
  } @else if $class == mh750 {
    @media screen and (max-height: 750px) {
      @content;
    }
  } @else if $class == mh600 {
    @media screen and (max-height: 600px) {
      @content;
    }
  }
}
.overlay {
  background-color: rgba(0, 0, 0, 0.56);
  position: fixed;
  width: 100%;
  bottom: 0;
  top: 56px;
  left: 0;
  z-index: 3;
}

.pink {
  color: #f47a7a;
}

.modal-advanced {
  // checkoboxes
  .cb {
    margin: 24px 0 0 0;
  }
  .outer-div,
  .cb-name {
    display: block;
    float: left;
    & label.square {
      margin-bottom: 0;
    }
  }
  .cb-name {
    color: #192340;
    font-size: 1rem;
    padding-left: 22px;
    @include breakpoint(mobile) {
      font-size: 0.875rem;
      padding-left: 10px;
    }
  }
}
