@import 'src/styles/v2/all.scss';

$button--h: 60px;

.component {
  border: $border--q--xs solid $white-quaternary;
  border-radius: $border--r--xl;
  margin-bottom: $gap--md;

  &.isOpen {
    border: $border--q--sm solid $c-item--slick--selected--bd;
  }

  .title {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    font-family: $font-family--bold;
    color: $c-item--slick--fg;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .iconContainer {
    margin-right: $gap--md;
  }

  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $gap--md;
    min-height: $button--h;
    cursor: pointer;
  }

  .arrowContainer {
    display: flex;
    justify-content: space-between;
  }

  .arrow {
    &.isOpen {
      transform: rotate(90deg);
    }
  }

  &.neutral {
    &.isOpen {
      border: $border--q--sm solid $c-item--neutral--selected--bd;
    }
  }

  &.dark {
    border: $border--q--xs solid $c-item--dark--bd;

    .title {
      color: $c-item--dark--fg;
    }

    &.isOpen {
      border: $border--q--sm solid $c-item--dark--selected--bd;
    }

    .arrow {
      filter: brightness(10);
    }
  }
}
