@import 'src/styles/v2/all.scss';

.component {
  display: flex;
  border-bottom: 1px solid $c-page--slick--bd;
  background-color: $c-page--slick--bg;

  &.dark {
    border-bottom-color: $c-page--dark--bd;
    background-color: $c-page--dark--bg;
  }
}
