@import 'src/styles/v2/all.scss';

$image--slick: linear-gradient(101deg, #f5e2fe -48%, #faf5fc 100%);
$image--dark: linear-gradient(101deg, #3e0869 0%, #821bb8 100%);

.component {
  .statusIcon {
    @include square($icon-sz--l3);
    background: $silver;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border--r--mx;
    margin: $gap--xl auto $gap--lg;
  }

  .title {
    color: $c-page--slick--fg;
    font-size: $font-sz--lg;
    font-family: $font-family--bold;
    text-align: center;
  }

  .text {
    text-align: center;
    margin-top: $gap--md;
    color: $c-page--slick--fg;
    font-size: $font-sz--md;
    font-family: $font-family--regular;
    padding-bottom: $gap--md;
  }

  .phone {
    display: block;
    color: $c-text--fg;
    margin-top: $gap--md;
  }

  &.neutral {
    .statusIcon {
      background: $silver;
      img {
        filter: brightness(0.7);
      }
    }
  }

  &.dark {
    .phone {
      color: $c-text--dark--fg;
    }
    .title,
    .text {
      color: $c-page--dark--fg;
    }
    .statusIcon {
      background: $purple;
      img {
        filter: brightness(10);
      }
    }
  }
}
