//
$white: #ffffff;
$white-primary: #e7e7e7;
$white-secondary: #f6f6f6;
$white-tertiary: #afafaf;
$white-quaternary: #d2d1d1;

//
$gray: #4a4a4a;
$gray-primary: #656565;
$gray-secondary: #323232;
$gray-tertiary: #26113b;
$gray-quaternary: #252629; // dark bg
$gray-quinary: #494949;

//
$silver: #f4f3f3;
$silver-primary: #979797;
$silver-secondary: #494949;
$silver-tertiary: #e8e8e8;
$silver-quaternary: #f3f3f3;
//
$pink-primary: #f6e5fe;
$pink-secondary: #e3bff5; // dark fg
//
$purple: #821bb8;
$purple-primary: #933cc1;
$purple-secondary: #8e06bf;
$purple-tertiary: #3e0b6c;
$purple-quinary: #af6dd1;
//
$blue-primary: #370861; // nav
$blue-secondary: #26113b;
$blue-tertiary: #192340; // neutral
//
$red-primary: #e32e45;
$red-secondary: #d2166d;
$red-tertiary: #e50000;
$red-quaternary: #ff7070; // dark mode
$red-quinary: #ffe2e2;

$yellow--primary: #fff3ca;

$green--primary: #cef9ee;
$green--secondary: #54ddb4;
