@import '../mixin/breakpoints.scss';
@import '../modules/base.scss';

input.checkbox-switch[type='checkbox'],
input.checkbox-switch[type='radio'] {
  display: none;
}

input.checkbox-switch[type='checkbox'] + label {
  color: #f2f2f2;
}

input.checkbox-switch[type='radio'] + label {
  color: #333;
}

input.checkbox-switch[type='checkbox'] + label span,
input.checkbox-switch[type='radio'] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -2px 10px 0 0;
  cursor: pointer;
  vertical-align: middle;
  border: 2px solid #dedede;
  border-radius: 50%;
}

input.checkbox-switch[type='checkbox']:checked + label span,
input.checkbox-switch[type='radio']:checked + label span {
  position: relative;
  border: 2px solid $violet;
  background-image: url('~assets/images/check-box-violet.svg');
  background-repeat: no-repeat;
  background-position: 0px;
  background-size: 18px;
}

.cb-square {
  margin: 14pt 0;

  input[type='checkbox'] + label span {
    margin: 0px 10px 0 0;
    border-radius: 3px;
  }

  & input[type='checkbox'] + label {
    color: rgb(25, 35, 64);
  }

  & label {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;

    @include breakpoint(mobile) {
      font-size: 10pt;
      line-height: 15px;
    }

    > div {
      display: inline-block;
      width: calc(100% - 100px);
      padding-left: 8px;
    }
  }
}

%_btn {
  font-family: 'ProximaNova-sb';
  font-size: 18px;
  position: relative;
  min-width: 200px;
  padding: 13px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: 0 none;
  background: none;

  @include breakpoint(mobile) {
    display: block;
    width: auto;
    min-width: 100%;
  }
}

.btn {
  @extend %_btn;

  &.btn-primary {
    @extend %_btn;
    color: white;
    background-color: $brand-secondary;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

    &:hover,
    &:active {
      background-color: rgba(147, 60, 193, 0.8);
    }

    &:disabled,
    &.disabled {
      cursor: default;
      opacity: 0.2;
      color: white;
      background-color: $violet;
    }

    @include breakpoint(mobile) {
      width: auto;
    }
  }

  &.btn-apple-pay {
    @extend %_btn;
    font-family: 'ProximaNova-r';
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    color: white;
    background-color: black;

    & .ico-apple {
      width: 25px;
      margin: 0 4px;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(390deg) brightness(100%) contrast(100%);
    }

    > span {
      font-family: 'ProximaNova-r';
    }

    > strong {
      font-family: 'ProximaNova-b';
      font-size: 20pt;
    }
  }

  &.btn-secondary {
    @extend %_btn;
    background-color: #fff;
  }

  &.btn-link {
    @extend %_btn;
    color: $violet;
    background-color: transparent;
  }

  &.btn-border {
    @extend %_btn;
    color: $violet;
    border: 1px solid $violet;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(77, 161, 155, 0.1);
    }

    a {
      color: $brand-secondary;
    }

    &.account {
      font-size: 16px;
      display: block;
      min-width: 200px;
      margin: 0 auto;
      padding: 12px 6px;
      color: white;
      border: solid 1px #933cc1;
      border-radius: 4px;
      background-color: $violet;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    }
  }

  &.btn-sm {
    @extend %_btn;
    font-size: 0.875rem;
    padding: 12px 36px 12px 36px;
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'ProximaNova-r';
  font-weight: 500;
  // padding-bottom: 6rem;
  position: relative;
  min-height: 100vh;
  margin: 0;

  div,
  span {
    outline-width: 0px;
    outline-color: rgba(255, 255, 255, 0);
  }
}

.cfix:after,
.cfix:before {
  display: block;
  visibility: hidden;
  height: 0;
  content: ' ';
}

.cfix:after {
  clear: both;
}

.cfix {
  zoom: 1;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-pink {
  color: rgb(237, 69, 99);

  &.error {
    font-family: 'ProximaNova-r';
    font-size: 12pt;
    position: relative;
    z-index: 1;

    @include breakpoint(mobile) {
      font-size: 10pt;
    }
  }
}

.text-center {
  text-align: center;
}

.text-upper {
  text-transform: uppercase;
}

.text-note {
  display: block;
  padding: 10px 0 0 0;
  text-align: center;
  color: $brand-primary;
}

.app-wrap {
  max-width: 740px;
  margin: 0 auto 0;

  @include breakpoint(mobile) {
    max-width: 100%;
  }
}

.app-content {
  min-height: 100vh;
  margin-top: 64px;
  padding-bottom: 120px;

  .fully-booked-cta {
    display: none;
  }

  //booking confirmed
  .header-top {
    margin: 0 -20px 0 -20px;
    //new on 27th Nov
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    background-color: white;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
      0 1px 1px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

    &.header-top {
      margin: 0;
    }
  }

  @include breakpoint(mobile) {
    margin: 64px 16px;
  }

  @include breakpoint(xs2) {
    margin: 64px 0;
  }

  &.no-wraper {
    @include breakpoint(mobile) {
      margin: 0;
    }
  }

  .cta {
    padding-top: 50px;
    text-align: center;
  }

  .react-add-to-calendar {
    position: relative;
    width: calc(100% - 5px);
    min-width: 150px;
    margin-right: 5px;
  }

  .react-add-to-calendar__button {
    font-family: 'ProximaNova-sb';
    font-size: 13px;
    line-height: 40px;
    display: block;
    min-width: 150px;
    height: 40px;
    padding: 0;
    text-transform: uppercase;
    color: $violet;
    border: 1px solid $violet !important;
    border-radius: 4px;
    background-color: #ffffff !important;
    box-shadow: 0 1px 4px 0 rgba(201, 201, 201, 0.6);

    span {
      display: block;
      text-align: center;
    }

    .react-add-to-calendar__icon--right {
      display: none;
    }
  }

  .react-add-to-calendar__dropdown {
    position: absolute;
    z-index: 3; // Fix for overlaping 'Summary' text
    top: 40px !important;
    left: 0 !important;
    width: 100% !important;
    border: 1px solid transparent !important;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.6);
  }

  .react-add-to-calendar__dropdown ul {
    padding: 0;
  }

  .react-add-to-calendar__dropdown ul li {
    padding: 8px 12px;
    list-style-type: none;
  }

  .react-add-to-calendar__dropdown ul li a {
    font-family: 'ProximaNova-sb';
    display: block;
    color: $brand-blue;
  }

  ::-moz-selection {
    background: rgb(230, 230, 230);
  }

  ::selection {
    background: rgb(230, 230, 230);
  }
}

.app-content-verify {
  min-height: 25vh;
}

.app-content.color-title .app-header .header-title {
  color: $brand-secondary;
}

.app-content.color-title {
  .summary-container {
    margin: 0px -15pt 0 -15pt;

    @include breakpoint(xs) {
      padding-bottom: 100px;
    }
  }

  .summary-sub-header {
    display: none;
  }
}

.header-title-new-line {
  font-family: 'ProximaNova-b';
  font-size: 2.125rem; // same as calendar
  font-weight: 500;
  line-height: 1.36;
  color: $violet;

  @include breakpoint(mobile) {
    font-size: 24px;
    line-height: 28px;
    max-width: 330px;
    margin: 0 auto 10px;
  }
}

.breadcrumb {
  font-size: 12px;
  display: block;
  margin-bottom: 14px;
  text-align: center;
  color: $violet;
}

.app-content.black-title .special-design-idea span {
  color: $brand-primary;
}

.app-content.less-title .app-header .header-title {
  font-size: 1.8rem; // same as calendar

  @include breakpoint(mobile) {
    font-size: 20px;
    line-height: 24px;
  }
}

.app-footer {
  position: fixed;
  z-index: 2;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 -0.5px 0 0 #c8c7cc;

  &.complicated {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.invalid-form {
  & .app-footer {
    .btn.btn-primary {
      pointer-events: none !important;
      opacity: 0.2;
    }
  }
}

.app-content {
  &.no-margin {
    & .logout-btn {
      right: 20px;
    }

    .github-banner {
      @include breakpoint(mobile) {
        width: calc(100% - 40px);
      }
    }
  }

  &.color-white {
    & .form-wrapper {
      margin-top: 18px;
      padding: 25px 28px 20px;
      background-color: white;

      @include breakpoint(mobile) {
        margin-top: 0;
      }

      &.has-hight-footer {
        padding-bottom: 125px;
      }
    }

    & .salon-list {
      & .form-wrapper {
        margin-top: 0;
      }
    }
  }
}

.divider {
  font-size: 10pt;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 8px 0;
  text-align: center;
  text-transform: uppercase;
  color: $violet;

  @include breakpoint(mobile) {
    width: 100%;
    max-width: 100%;
  }

  &:before {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    opacity: 0.3;
    background-color: $violet;
  }

  &.has-margin {
    margin: 14px auto 10px auto;
  }

  > span {
    position: relative;
    display: inline-block;
    padding: 0 6px;
    background-color: white;
  }
}

.form-wrapper {
  & input:focus,
  & textarea:focus {
    border: 2px solid $violet;
    outline: none !important;
    background-color: white;
  }
}

.policy-info {
  font-family: 'ProximaNova-sb';
  font-size: 14px;
  line-height: 18px;
  color: $warning;

  &.top {
    margin: 0px -15pt 0 -15pt;
    padding: 15px;
    background-color: white;
    box-shadow: 0 2px 3px 0px #c8c7cc;
  }

  &.has-padding {
    padding: 20px 15px 15px 15px;
  }
}

// header
.app-header {
  position: relative;

  span {
    display: block;
  }

  .header-breadcrumb {
    font-size: 12pt;
    margin-bottom: 14px;
    color: $violet;

    @include breakpoint(mobile) {
      font-size: 10pt;
    }
  }

  .logo-container {
    padding-top: 25px;
  }

  .salon-logo {
    display: block;
    max-width: 75%;
    height: 120px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include breakpoint(mobile) {
      max-width: 50%;
      height: 100px;
    }
  }

  .powered-by {
    font-family: 'ProximaNova-sb';
    font-size: 10px;
    position: relative;
    width: 113px;
    margin: 0 auto;
    padding: 28px 0 0 0;
    text-align: center;
    color: #630396;

    > span {
      position: absolute;
      top: 31px;
      width: 65px;
      text-align: right;
    }

    > img {
      position: absolute;
      width: 36px;
      margin-left: 8px;
    }
  }

  .extra-title {
    font-family: 'ProximaNova-b';
    font-size: 2.125rem;
    line-height: 1.36;
    margin: 12px auto 14px;
    color: $violet;

    @include breakpoint(mobile) {
      font-size: 24px;
      line-height: 28px;
      max-width: 330px;
    }
  }

  .header-title {
    font-family: 'ProximaNova-b';
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.36;
    margin-bottom: 20px;
    color: $page-title;

    @include breakpoint(mobile) {
      font-size: 20px;
      line-height: 26px;
      max-width: 330px;
      margin: 0 auto 14px;
    }
  }

  .header-notes {
    font-family: 'ProximaNova-sb';
    font-size: 12px;
    font-weight: 500;
    line-height: 1.36;
    margin-bottom: 20px;
    padding: 6px;
    color: #192340;
    background-color: white;

    > p {
      margin: 4px;

      @include breakpoint(mobile) {
        line-height: 14px;
        margin: 6px;
      }
    }

    a {
      color: #933cc1;

      &:visited {
        color: #933cc1;
      }
    }

    @include breakpoint(mobile) {
      max-width: 330px;
      margin: 0 auto 14px;
    }
  }

  .no-title {
    min-height: 1px;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  &.left-title {
    & .header-title {
      font-size: 1.125rem;
      padding: 10px 0;
      text-align: left;
      color: $brand-blue;

      @include breakpoint(mobile) {
        font-size: 1.125rem;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  &.small-title {
    & .header-title {
      font-size: 0.8125rem;
      line-height: 16px;
      min-height: 36px;
      padding: 10px 0;
      color: $brand-blue;
      border-radius: 4px;
      background-color: white;

      @include breakpoint(mobile) {
        font-size: 0.8125rem;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.text-sb {
  font-family: 'ProximaNova-sb';
}

.booking-info {
  font-size: 20pt;

  @include breakpoint(mobile) {
    font-size: 16pt;
  }

  & .pay-info {
    font-family: 'ProximaNova-b';
    font-size: 20px;
    display: block;
    color: $brand-blue;
  }

  & .pay-total {
    font-family: 'ProximaNova-b';
    font-size: 20px;
    display: block;
    padding-top: 10px;
    color: $violet;
  }

  p {
    font-family: 'ProximaNova-sb';
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: normal;
    color: $brand-blue;
  }
}

// background
.gray-bgr {
  // min-height: calc(100vh + 160px);
  .form-input input,
  label.form-input input {
    font-weight: 100;
  }
}

.select-time,
.service-container,
.stylist-container {
  margin-top: 6px;
}

.service-container,
.stylist-container {
  padding-bottom: $footer--h; // when click on last category drop-downs and need scroll to top
}

.group-shape {
  margin-top: 12px;

  &.hide-stylist {
    display: none;
  }
}

.category-wrapper {
  position: relative;

  &.has-selected-services {
    @include breakpoint(mobile) {
      .service-title {
        max-width: calc(100% - 135px);
      }
    }
  }

  .close-category-label {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    cursor: pointer;
  }

  .category-name {
    font-family: 'ProximaNova-b';
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    max-width: calc(100% - 135px);
    height: 60px;
    padding-left: 23px;
    color: $brand-blue;
  }
}

.group-shape.select-category {
  padding: 5px 8px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(201, 201, 201, 0.6);

  > img {
    position: absolute;
    top: 25px;
    right: 20px;

    &.arr-down {
      transform: rotate(90deg);
    }
  }

  .count-selected-services {
    font-size: 14px;
    position: absolute;
    top: 18px;
    right: 50px;
    padding: 4px 8px;
    color: $white;
    border-radius: 4px;
    background-color: $brand-primary;
  }

  .category-dropdown {
    &.is-closed {
      display: none;
    }

    &.is-open {
      display: block;
    }
  }

  &.overlay {
    position: relative;
    opacity: 1;
  }

  .service-title {
    display: flex;
    align-items: center;
    min-height: 60px;
    margin: 0;
    padding: 0 0 0 15px;
    cursor: pointer;
  }
}

// the selected services outside dropdowns
#selected-services-sticky {
  position: sticky;
  z-index: 3;
  top: 64px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 42vh;
  justify-content: space-between;
  padding-top: $gap--md;
  &.highlight-box {
    padding: 8px 8px 6px 8px;
    border-radius: 4px;
  }

  & .isHidden,
  .time {
    display: none;
  }

  .the-service-shape {
    flex-basis: calc(50% - 4px);
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 10px;
    margin-bottom: 4px;

    @include breakpoint(mobile) {
      flex-basis: 100%;
      margin: 0 0 6px 0;
    }

    .item-option .price {
      font-size: 14px;

      @include breakpoint(mobile) {
        letter-spacing: -1px;
      }
    }

    .item-option label {
      margin-top: -9px;
    }
  }

  .group-shape.select-category {
    margin: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .item-description,
  .link-more {
    display: none;
  }
}

// end test
.service-title {
  font-family: 'ProximaNova-b';
  font-size: 1.125rem;
  display: block;
  margin-top: 12px;
  padding-top: 4px;
  padding-bottom: 12px;
  color: $brand-blue;
}

.role-button {
  display: block;
  min-height: 100%;
}

.flexible-container {
  display: flex;
}

.the-service-shape {
  font-family: 'ProximaNova-b';
  position: relative;
  width: calc(50% - 8px);
  min-height: 70px;
  margin: 0 2px 12px 2px;
  margin-bottom: 14px;
  padding: 12px 12px 10px 12px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(201, 201, 201, 0.6);

  &.service-box {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
  }

  &.stylist {
    cursor: pointer;

    .item-option label {
      margin-top: -9px;
    }
  }

  &.recommended {
    width: 345px;

    &:nth-child(even),
    &:nth-child(odd) {
      margin: 10px auto;
    }

    @include breakpoint(mobile) {
      width: calc(100% - 0px);
    }
  }

  &.noPrice {
    min-height: 56px;
  }

  @include breakpoint(mobile) {
    width: 100%;
  }

  .item-text {
    float: left;
    margin-bottom: 3px;
    word-wrap: break-word;

    .name {
      font-family: 'ProximaNova-r';
      font-size: 15px;
      font-weight: 500;
      display: block;
      min-height: 18px;
      text-transform: capitalize;
      color: #080b0f;

      @include breakpoint(xs) {
        font-size: 13px;
      }

      @include breakpoint(xs2) {
        font-size: 12px;
      }
    }

    .time {
      font-family: 'ProximaNova-r';
      font-size: 13px;
      font-weight: 500;
      display: block;
      color: #979797;
    }
  }

  .item-option {
    position: relative;
    flex-grow: 2;
    text-align: right;

    .price {
      font-family: 'ProximaNova-r';
      font-size: 0.9375rem;
      display: block;
      min-height: 16px;
      margin: 0px 22px 0 0;
      color: #979797;

      @include breakpoint(mobile-sm) {
        font-size: 14px;
      }

      @include breakpoint(xs2) {
        font-size: 12px;
      }
    }

    label {
      position: absolute;
      top: 50%;
      right: -12px;
      margin-top: -20px;
    }
  }

  @include breakpoint(xs2) {
    padding: 12px 8px 10px 8px;
  }
}

.item-description {
  font-family: 'ProximaNova-r';
  font-size: 14px;
  position: relative;
  right: 0;
  bottom: 0;
  display: block;
  clear: both;
  width: 100%;

  &.open {
    & .note-text {
      color: #979797;

      &:after {
        display: inline-block;
        width: 127px;
        margin-left: 6px;
        content: ' ';
        white-space: nowrap;
        word-break: break-all;
        color: #933cc1;
        background-color: orange;
      }
    }

    & .show-more-text {
      width: 100%;
      height: 20px;
      background: transparent;
    }
  }
}

.link-more {
  font-family: 'ProximaNova-r';
  font-size: 14px;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  display: block;
  clear: both;
  width: 92px;

  &.open {
    width: 100%;

    // view less when open
    & .show-more-text {
      bottom: 8px;
      width: 80px;
      height: 18px;
      padding-left: 8px;
      text-align: right;
      background: transparent;
    }
  }

  // view more when is closed
  & .show-more-text {
    position: absolute;
    z-index: 1;
    right: 16px;
    bottom: 9px;
    cursor: pointer;
    color: #933cc1;
    background-color: white;
  }
}

.note-text {
  display: block;
  word-break: break-word;
  color: #979797;

  &.clip {
    overflow: hidden;
    max-height: 36px;
    padding-top: 2px;
    text-overflow: clip;
  }
}

//

.group-shape-4-column {
  width: 640px;
  margin-top: 23px;
  margin-left: 22px;

  @include breakpoint(mobile) {
    width: 100%;
    margin-left: 0;
  }
}

.the-time-shape {
  font-family: 'ProximaNova-r';
  font-weight: 500;
  float: left;
  width: 140px;
  min-height: 54px;
  margin-bottom: 19px;
  margin-left: 19px;
  padding: 10px 14px 10px 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(201, 201, 201, 0.6);
  box-shadow: 0 1px 4px 0 rgba(201, 201, 201, 0.6);

  @include breakpoint(mobile) {
    width: 48%;
    margin-left: 0;

    &:nth-child(even) {
      margin-left: 3%;
    }
  }

  @include breakpoint(mobile-sm) {
    &:nth-child(even) {
      margin-left: 4%;
    }
  }

  @include breakpoint(xs) {
    width: 100%;

    &:nth-child(even) {
      margin-left: 0;
    }
  }

  .item-text {
    float: left;
    width: 65%;
    margin-top: 8px;

    .name {
      font-family: 'ProximaNova-r';
      font-size: 15px;
      font-weight: 500;
      display: block;
      color: #080b0f;
    }

    .time {
      font-size: 13px;
      font-weight: 500;
      display: block;
      color: #979797;
    }
  }

  .item-option {
    position: relative;
    float: right;
    width: 35%;
    text-align: right;

    label {
      position: absolute;
      top: 50%;
      right: -12px;
      margin-top: 8px;
    }
  }
}

.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #231f20;
}

#mydiv {
  position: absolute;
  z-index: 9;
  text-align: center;
  border: 1px solid #d3d3d3;
  background-color: #f1f1f1;
}

#mydivheader {
  z-index: 10;
  padding: 10px;
  cursor: move;
  color: #fff;
  background-color: #2196f3;
}

.popup {
  position: absolute;
  z-index: 400;
  top: 60px;
  left: 50%;
  overflow-y: auto;
  width: 460px;
  max-height: 80vh;
  margin-left: -230px;
  padding: 34px 30px 20px;
  border-radius: 4px;
  background-color: white;
  // overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(201, 201, 201, 0.6);

  @include breakpoint(tablet-p) {
    overflow-y: auto;
    max-height: 84vh;
  }

  @include breakpoint(mobile) {
    // width: 93%;
    // margin-left: -46.5%;
    left: 0;
    width: 96%;
    margin: 0 2%;
    padding: 15px 22px 20px;
  }

  &.draggable {
    cursor: move;
  }

  h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.17;
    margin-top: 4px;
    margin-bottom: 10px;
    text-align: center;
    color: $brand-primary;
  }

  .hr {
    display: block;
    width: 20px;
    height: 2px;
    margin: 10px auto;
    border-radius: 100px;
    background-color: $brand-primary;
  }

  h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.17;
    margin: 4px 30px;
    text-align: center;
    color: #080b0f;
  }

  p {
    font-size: 15px;
    font-weight: normal;
    line-height: 16px;
    color: #979797;
  }

  .btn {
    min-width: 100%;
  }

  .close-icon {
    position: absolute;
    top: 12px;
    right: 14px;
    width: 14px;
    height: 14px;
    padding: 15px;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 13px;
      left: 8px;
      width: 14px;
      height: 1.5px;
      content: '';
      background-color: #333;
    }

    &:before {
      transform: rotate(135deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  .note {
    margin: 16px 0;
  }
}

.note {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  display: block;
  margin: 38px 0 16px 0;
  color: $brand-primary;
}

//animate
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

// fadeInDown
@keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  animation-delay: 3s;
}

//

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  animation-delay: 1.5s;
}

.error-popup {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 30px;
  padding: 15px 30px;
  text-align: center;
  color: #fff;
  border: 1px solid #f47a7a;
  border-radius: 4px;
  background-color: #f47a7a;
}

.succes-popup {
  font-size: 13px;
  font-weight: 500;
  padding: 8px 30px;
  text-align: center;
  color: #0c5460;
  border: solid 1px #bee5eb;
  border-radius: 4px;
  background-color: #d1ecf1;

  @include breakpoint(mobile) {
    margin: 0 16px;
  }
}

.wapper-slider {
  position: relative;
  width: 529px;
  margin: 11px auto;

  @include breakpoint(mobile) {
    width: 90%;
  }

  .slick-list {
    @include breakpoint(mobile) {
      cursor: move;
    }

    .slick-track {
      height: 64px;
      padding-bottom: 10px;
      transition-timing-function: ease-in-out;
      transition-duration: 1s;
      transition-property: left, transform, -webkit-transform;
    }
  }

  .slick-arrow {
    position: absolute;
    text-indent: -9999px;
    border: none;
    outline: 0 none;
    background-color: transparent;

    @include breakpoint(mobile) {
      display: none !important;
    }

    &.slick-prev {
      top: 5px;
      left: -54px;
      display: none;
      overflow: hidden;
      width: 48px;
      padding: 20px 0;
      background-image: url('~assets/images/LastWeek.png');
      background-repeat: no-repeat;
      background-position: center 7px;
      background-size: 36px;

      &.slick-disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }

  .slick-next.custom {
    position: absolute;
    top: 16px;
    right: -54px;
    width: 48px;
    height: 48px;
    padding: 20px 0;
    cursor: pointer;
    opacity: 1;
    background-image: url('~assets/images/icons/next.svg');
    background-repeat: no-repeat;
    background-position: center -3px;
    background-size: 37px;

    @include breakpoint(mobile) {
      right: -20px;
      width: 24px;
    }

    &.slick-disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .slick-prev.custom {
    position: absolute;
    top: 16px;
    left: -54px;
    width: 48px;
    height: 48px;
    padding: 20px 0;
    cursor: pointer;
    opacity: 1;
    background-image: url('~assets/images/icons/prev.svg');
    background-repeat: no-repeat;
    background-position: center -3px;
    background-size: 37px;

    @include breakpoint(mobile) {
      width: 24px;
      left: -20px;
    }

    &.slick-disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}

.calendar-slider {
  overflow: hidden;

  .slick-item {
    position: relative;
    float: left;
  }

  .slick-slide {
    float: left;
    padding: 0 8px;
    cursor: pointer;
    @include breakpoint(mobile) {
      padding: 0 4px;
    }
  }

  img {
    display: block;
    clear: both;
    max-width: 190px;
    margin: 7px auto 18px;
  }
}

// spinner
.spinner-container {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);

  &.in-header {
    position: absolute;
    bottom: auto;
    height: 60px;
    background: #fbfbfb;
  }
}

.load-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  -moz-animation: spinner-rotation 1s infinite linear;
  -webkit-animation: spinner-rotation 1s infinite linear;
  animation: spinner-rotation 1s infinite linear;
  border-top: 4px solid #933cc1;
  border-right: 4px solid #933cc1;
  border-bottom: 4px solid rgba(147, 60, 193, 0.2);
  border-left: 4px solid #933cc1;
  border-radius: 100%;
}

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.dropdown-container {
  position: relative;
  width: 300px;
  margin: 0 auto 14px;
  border-bottom: 3px solid transparent;
  @include breakpoint(mobile) {
    width: 100%;
  }

  &.open {
    border-bottom: 3px solid $violet;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    .dropdown-btn {
      outline: 0 none;

      .arr-icon {
        outline: 0 none;

        img {
          transition-duration: 1s;
          transition-property: transform;
          transform: rotate(90deg);
        }
      }
    }
  }

  &.close {
    .dropdown-btn {
      .arr-icon {
        img {
          transition-duration: 1s;
          transition-property: transform;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .close-dropdown {
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }

  .dropdown-list {
    position: absolute;
    z-index: 5;
    top: 60px;
    overflow-y: auto;
    width: 100%;
    max-height: 308px;
    -webkit-transition: height 1s;
    transition: height 1s;
    border-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    background-color: #fff;

    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08), 0 0 8px 0 rgba(0, 0, 0, 0.12) !important;

    ul {
      margin: 0;
      padding: 15px 0;

      li {
        padding: 5px 15px;
        list-style-type: none;
        cursor: pointer;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }

    &.invisible {
      display: block;
      height: 0px;
    }

    &.visible {
      display: block;
      height: 210px;
    }
  }

  .dropdown-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 48px;
    padding: 0 15px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #f3f3f3;
    @include breakpoint(mobile) {
      width: 100%;
    }
    .arr-icon {
      width: 8px;
      height: 14px;

      img {
        width: 100%;
        transform: rotate(-90deg);
      }
    }
  }
}

.summary-header {
  font-family: 'ProximaNova-b';
  font-size: 1.5rem;
  text-align: center;
  color: $brand-primary;

  span {
    @include breakpoint(mobile) {
      display: block;
    }
  }
}

.summary-container {
  .summary-content {
    margin-bottom: 60px;
    padding-bottom: 16px;
    border-radius: 0 0 4px 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

    & .flex-parent {
      display: flex;
      flex-wrap: wrap;

      @include breakpoint(mobile) {
        padding-top: 0;
      }
    }

    & .flex-item {
      font-family: 'ProximaNova-b';
      font-size: 0.9375rem;
      width: 100%;
      padding: 15px 28px;
      color: $brand-blue;
      box-shadow: 0 0.5px 0 0 #c8c7cc;

      @include breakpoint(xs) {
        padding: 15px 20px;
      }

      span.purple {
        color: $violet;
      }

      span.red {
        color: #e32e45;

        @include breakpoint(xs) {
          font-size: 14px;
        }

        > p {
          margin: 8px 0;
        }
      }

      .in-left {
        font-family: 'ProximaNova-r';
        width: 48%;
        text-align: left;

        & .text-bold {
          font-family: 'ProximaNova-b';
          display: block;
          clear: both;
        }

        @include breakpoint(xs) {
          font-size: 14px;
          width: 50%;
        }
      }

      .in-right {
        font-family: 'ProximaNova-r';
        width: 48%;
        text-align: right;

        & .text-bold {
          font-family: 'ProximaNova-b';
          display: block;
          clear: both;
        }

        span {
          text-align: right !important;
        }

        @include breakpoint(xs) {
          font-size: 14px;
        }
      }

      span.pull-right {
        font-family: 'ProximaNova-r';
        width: 50%;
        text-align: right;
      }

      span,
      span.pull-right {
        @include breakpoint(mobile) {
          display: block;
          float: none;
          width: 100%;
          text-align: left;
        }
      }
    }

    .relative {
      position: relative;
    }

    // input in label
    .form-container {
      width: 345px;
      margin: 0 auto;
      padding-top: 27px;

      @include breakpoint(mobile) {
        width: 90%;
      }
    }

    .form-input,
    label.form-input {
      display: block;
      margin-bottom: 17px;

      .text-pink {
        font-weight: 400;
        display: block;
        margin-top: 4px;
      }

      .label-name {
        font-family: 'ProximaNova-r';
        font-size: 0.875rem;
        display: block;
        margin-bottom: 4px;
        color: #979797;
      }

      input {
        font-size: 1rem;
        width: 100%;
        min-height: 44px;
        padding: 0 16px;
        color: $brand-blue;
        border: solid 1px transparent;
        border-radius: 4px;
        background-color: #f3f3f3;
      }
    }
  }
}

.policy-text {
  font-size: 14px;
  font-family: 'ProximaNova-sb';
  margin-top: 6px;
}

.sum {
  font-family: 'ProximaNova-b';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  padding: 0px 26px 12px 28px;
  text-align: left;
  color: $brand-blue;
  box-shadow: 0 0.5px 0 0 #c8c7cc;

  &.border-t {
    padding-top: 16px;
    border-top: 1px solid #e1e1e1;
  }

  strong {
    font-family: 'ProximaNova-b';
  }

  @include breakpoint(xs) {
    padding: 0px 20px 12px 20px;
  }
}

.order-services {
  padding: 15px 28px 0 28px;

  @include breakpoint(xs) {
    padding: 15px 14px 0 20px;
  }

  ul {
    margin: 0;
    padding: 0;

    .item {
      font-family: 'ProximaNova-b';
      font-size: 15px;
      margin-bottom: 28px;
      list-style-type: none;
      text-align: left;
      color: $brand-blue;

      &:last-child {
        margin-bottom: 12px;
      }

      .pull-right {
        @include breakpoint(xs) {
          float: none;
        }
      }

      p {
        font-family: 'ProximaNova-r';
        margin: 0;
        color: #979797;
      }
    }
  }
}

.total-from {
  .d-inline {
    display: none;

    &:first-child {
      display: inline-block;
    }
  }
}

// INPUT FORM

.form-input {
  display: block;
  margin-bottom: 17px;

  .text-pink {
    font-weight: 400;
    display: block;
    margin-top: 4px;
  }

  &.password-field {
    position: relative;

    > input {
      padding-right: 55px;
    }

    .show-hide {
      font-family: 'ProximaNova-sb';
      font-size: 12px;
      position: absolute;
      top: 36px;
      right: 15px;
      cursor: pointer;
      text-transform: uppercase;
      color: #933cc1;

      &.purple {
        color: $violet;
      }
    }

    .password-link {
      font-size: 9pt;
      float: right;
      padding-top: 6px;
      cursor: pointer;
      color: $violet;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .label-name {
    font-family: 'ProximaNova-r';
    font-size: 0.875rem;
    display: block;
    margin-bottom: 4px;
    color: #979797;
  }

  input {
    font-size: 1rem;
    width: 100%;
    min-height: 44px;
    padding: 0 16px;
    color: $brand-blue;
    border: solid 1px transparent;
    border-radius: 4px;
    background-color: #f3f3f3;
  }
}

::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  color: #b7b7b7;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 400;
  color: #b7b7b7;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400;
  color: #b7b7b7;
}

textarea {
  font-size: 15px;
  width: 100%;
  height: 75px;
  padding: 12px 16px;
  resize: none;
  text-align: left;
  color: #192340;
  border: solid 1px transparent;
  border-radius: 4px;
  outline: 0 none;
  background-color: #f3f3f3;
}

.value-textarea {
  font-size: 13px;
  line-height: 1.31;
  padding: 24px;
  color: #192340;
  /*border-top: solid 1px #efefef;
border-bottom: solid 1px #efefef; */
}

input:focus,
textarea:focus {
  border-color: rgb(211, 211, 211);
  outline: none !important;
}

.area {
  margin: 0 24px;

  @include breakpoint(xs) {
    margin: 0 20px;
  }
}

.area-note {
  font-family: 'ProximaNova-r';
  font-size: 12px;
  margin-bottom: 12px;
  padding: 0 4px;
  color: #933cc1;

  &.text-dark {
    color: #192340;
  }
}

.textarea-label {
  font-family: 'ProximaNova-b';
  font-size: 12px;
  margin-bottom: 6px;
  padding: 0 4px;
  text-transform: uppercase;
  color: #933cc1;
}

.patch-test-div {
  display: flex;
  flex-flow: column;
  margin: 0 -20px 0 -20px;
  margin-bottom: 0px;
  padding-bottom: 16px;
  border-radius: 4px 4px 0 0;
  background-color: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  p {
    font-size: 13px;
    padding-top: 18px;
    text-align: center;
    color: #979797;
  }

  .btn {
    align-self: center;
    max-width: 300px;

    @include breakpoint(mobile) {
      width: 100%;
      min-width: 300px;
      max-width: calc(100% - 32px);
      margin: 0 16px;
    }
  }
}

.summary-payment {
  display: flex;
  flex-direction: column;
  margin-top: 18pt;
  background-color: white;

  @include breakpoint(mobile) {
    margin-top: 16pt;
  }

  & .row-line {
    padding: 12px 15px;
    border-top: 1px solid rgb(211, 211, 211);

    & .space-between {
      font-family: 'ProximaNova-b';
      display: flex;
      justify-content: space-between;
    }

    & .gray {
      font-size: 12pt;
      opacity: 0.6;

      > span {
        display: inline-block;
        padding-right: 6px;
      }
    }
  }

  & .row-column {
    font-family: 'ProximaNova-b';
    display: flex;
    justify-content: space-between;
    padding: 9px 15px;
    border-top: 1px solid rgb(211, 211, 211);

    &.b-bottom {
      border-bottom: 1px solid rgb(211, 211, 211);
    }

    > span {
      font-size: 13pt;
    }

    > strong {
      font-size: 14pt;
    }
  }
}

.content.login label {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .field-name {
    font-size: 24pt;
    align-self: center;
    margin-top: 30px;
    color: #933cc1;
  }
}

.verify-code {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  // margin-top: 20px;
  .utl-full-width {
    width: 36px;
    margin-right: 18px;
    padding: 6px 0;
    text-align: center;

    @media screen and (max-width: 500px) {
      width: 34px;
      margin-right: 3%;
    }

    @media screen and (max-width: 320px) {
      width: 15%;
      margin-right: 2%;
    }
  }

  .utl-full-width:last-child {
    margin-right: 0;
  }
}

.form-horizontal .input-prompt-show {
  text-align: center;
}

.input-prompt-show {
  margin-top: 6px;
}

.login-error-1 {
  font-family: 'ProximaNova-sb';
  font-size: 22px;
  line-height: 26px;
  display: block;
  width: calc(100% - 50px);
  max-width: 360px;
  margin: 20px auto 0;
  text-align: center;
  color: rgba(147, 60, 193, 1);
}

.login-error-2,
.login-message {
  font-family: 'ProximaNova-sb';
  font-size: 16px;
  line-height: 22px;
  display: block;
  width: calc(100% - 50px);
  max-width: 360px;
  margin: 20px auto 0;
  text-align: center;
  color: #f73746;
}

.login-message {
  color: #192340;
}

.login-error-3 {
  font-family: 'ProximaNova-sb';
  font-size: 12px;
  line-height: 22px;
  max-width: 330px;
  margin: 3px auto 0;
  text-align: center;
  color: #192340;
}

.login-error-4 {
  font-family: 'ProximaNova-sb';
  font-size: 14px;
  line-height: 1.36;
  text-align: left;
  color: #e32e45;
}

.try-again {
  font-size: 16px !important;
  min-width: 150px !important;
  margin: 10px auto 15px;
}

.help-message {
  font-family: 'ProximaNova-sb';
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 0;
}

.payment-disclaimer {
  font-family: 'ProximaNova-r';
  font-size: 12px;
  line-height: 14px;
}

.booking-info p.payment-disclaimer-red,
.payment-disclaimer-red {
  font-family: 'ProximaNova-sb';
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: #ff0000;
}

.sms-password-note {
  font-family: 'ProximaNova-sb';
  font-size: 15px;
  line-height: 20px;
  display: block;
  margin-bottom: 15px;
  color: #e32e45;
}

.sms-password-message {
  font-family: 'ProximaNova-r';
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 15px;
  color: $violet;
}

.input-info {
  font-size: 12pt;
  color: $brand-blue;

  @include breakpoint(mobile) {
    font-size: 10pt;
  }
}

.verify-container {
  width: 320px;
  margin: 0 auto;

  & .label-name {
    margin: 4px 0 18px 0;
  }

  @include breakpoint(xs) {
    width: 280px;
  }
}

.verify-code {
  display: flex;
  justify-content: space-between;

  & .input-code {
    width: 46px;
    margin: 0 4px;
    padding: 0;
    text-align: center;

    @include breakpoint(xs) {
      width: 40px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.has-error {
      border: 1px solid $warning;
    }
  }
}

.reset-code {
  font-size: 16pt;
  display: block;
  margin: 20px auto 0;
  text-align: center;
  color: $violet;

  @include breakpoint(mobile) {
    font-size: 13pt;
  }
}

.summary-text {
  font-family: 'ProximaNova-sb';
  font-size: 0.9375rem;
  line-height: 18px;
  padding: 25px;
  color: #e32e45;
}

.small-title {
  font-family: 'ProximaNova-sb';
  font-size: 16px;
  line-height: 1.19;
  display: block;
  color: $violet;

  &.hidden-title {
    & .header-title {
      display: none;
    }
  }
}

.hidden-logo {
  & .salon-logo img {
    visibility: hidden;
  }
}

#cardUpdateBtn {
  display: block;
  margin: 0 auto;
}

.cardInfo {
  & .label-name {
    font-family: 'ProximaNova-r';
    font-size: 0.875rem;
    display: block;
    margin-bottom: 4px;
    color: #979797;
  }

  & .StripeElement {
    // simulate input
    padding: 12px;
    border-radius: 2px;
    background-color: #f5f5f5;
  }
}

.stripe-info {
  font-family: 'ProximaNova-r';
  font-size: 12px;
  line-height: 14px;
  display: block;
  color: #192340;
}

.card-number {
  color: #192340;
}

.card-number-field {
  font-family: 'ProximaNova-r';
  font-size: 14px;
  padding: 12px;
  letter-spacing: 2px;
  border-radius: 2px;
  background-color: #f5f5f5;
}

* {
  outline: 0 none;
}

.appointment-details-text {
  font-family: 'ProximaNova-r';
  font-size: 12px;
  line-height: 18px;
  display: block !important;
  max-width: 330px;
  margin: 0 auto;
  text-align: center !important;
  color: #192340;

  > span {
    display: inline !important;
    margin-left: 3px;
  }

  > strong {
    font-family: 'ProximaNova-b';
  }
}

.inline {
  display: inline-block;
  margin: 5px;
}

.search-container {
  position: relative;
  margin-top: 25px;
}

.search-input {
  font-size: 1rem;
  width: 100%;
  min-height: 44px;
  padding: 0 16px;
  color: $brand-blue;
  border: solid 1px transparent;
  border: 1px solid $violet;
  border-radius: 4px;
  outline: none;
  background-color: white;
  background-image: url('~assets/images/search-gray.svg');
  background-repeat: no-repeat;
  background-position: 97% 10px;

  &::placeholder {
    font-size: 17px;
  }

  &:focus {
    border: 1px solid $violet;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }
}

.btn-search {
  font-family: 'ProximaNova-r';
  font-size: 15px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 13px 13px;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 6px;
  outline: 0 none;
  background-color: $brand-secondary;

  @include breakpoint(mobile) {
    width: auto;
  }
}

.book-again {
  font-family: 'ProximaNova-sb';
  font-size: 13px;
  line-height: 40px;
  position: relative;
  width: calc(100% - 5px);
  min-width: 150px;
  height: 40px;
  margin-left: 5px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: 0 none;
  background: none;
  background-color: rgba(147, 60, 193, 0.8);
  box-shadow: 0 1px 4px 0 rgba(201, 201, 201, 0.6);

  &:hover,
  &:active {
    background-color: rgba(147, 60, 193, 0.8);
  }

  &:disabled,
  &.disabled {
    cursor: default;
    opacity: 0.2;
    color: white;
    background-color: $violet;
  }
}

.add-to-calendar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 50px);
  margin: 0 auto 16px;

  .add-to-calendar {
    width: 100%;
  }

  &.wait-list {
    justify-content: center;
    width: calc(50% - 50px);

    .add-to-calendar {
      display: none;
    }
  }
}

.overlay-bgr {
  position: fixed;
  z-index: 3;
  top: 56px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.56);
}

.github-banner {
  font-family: 'ProximaNova-sb';
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  line-height: 28px;
  position: absolute;
  z-index: 2;
  top: 195px;
  left: 50%;
  width: 100%;
  max-width: 620px;
  padding: 22px 20px 10px 20px;
  transform: translate(-50%, 0%);
  text-align: center;
  color: white;
  border-radius: 10px;
  background-image: linear-gradient(139deg, #821bb8, #d6177f 100%);
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.4);

  & .close-icon {
    position: absolute;
    top: 12px;
    right: 14px;
    width: 14px;
    height: 14px;
    padding: 15px;

    &:before,
    &:after {
      position: absolute;
      top: 13px;
      left: 8px;
      width: 14px;
      height: 1.5px;
      content: '';
      background-color: white;
    }

    &:before {
      transform: rotate(135deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  span {
    font-family: 'ProximaNova-r';
    font-size: 14px;
    line-height: 20px;
    display: block;
    max-width: 208px;
    margin: 20px auto;

    > a {
      display: inline-block;
      margin: 0 4px;
      text-decoration: underline;
      color: white;
    }
  }

  @include breakpoint(tablet-l) {
    top: 185px;

    width: 100%;
  }

  @include breakpoint(mobile) {
    top: 170px;
    width: calc(100% - 40px);
    max-width: 375px;
  }

  @include breakpoint(xs) {
    font-size: 20px;
  }
}

// international phone number input
.PhoneInputCountrySelect {
  > option {
    font-size: 18px;
    color: $page-title;
    background-color: white;
  }

  > option:before {
    font-size: 20px;
    display: none;
    padding-right: 10px;
    padding-left: 5px;
    content: '>';
  }

  > option:hover:before {
    display: inline;
  }
}
