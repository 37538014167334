@import '../mixin/breakpoints.scss';
@import '../modules/base.scss';

.salon-picker {
  padding: 9px 16px 58px 17px;
  background-color: #ffffff;
}
.salon-picker-container {
  .h5 {
    font-size: 14px;
    line-height: 1.43;
    font-family: 'ProximaNova-r';
    color: #192340;
    text-align: center;
    margin: 0;
    @include breakpoint(mobile) {
      text-align: left;
    }
  }
}
.salon-picker-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  & .picker-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    color: #192340;
    font-size: 14px;
    line-height: 23px;
    font-family: 'ProximaNova-sb';
    width: 100%;
    min-height: 32px;
    margin-top: 12px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #192340;
    background-color: #ffffff;
    text-align: center;
    padding: 4px 15px;
  }
}

.release__version {
  font-size: 10px;
  font-family: 'ProximaNova-sb';
  text-align: center;
  color: #630396;
  margin: 20px auto;
}
