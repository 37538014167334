$font-family--regular: 'ProximaNova-r';
$font-family--bold: 'ProximaNova-b';
$font-family--semi-bold: 'ProximaNova-sb';

$font-family--normal: $font-family--semi-bold;

// general font size constants
$font-sz--s3: 7px;
$font-sz--s2: 8px;
$font-sz--xs: 12px;
$font-sz--sm: 14px;
$font-sz--md: 16px;
$font-sz--lg: 18px;
$font-sz--l2: 20px;
$font-sz--l3: 22px;
$font-sz--l4: 24px;
$font-sz--l5: 32px;
$font-sz--l6: 38px;
$font-sz--l7: 40px;

//line-height
$line-h--normal: normal;
$line-h--s2: 10px;
$line-h--xs: 12px;
$line-h--sm: 14px;
$line-h--md: 16px;
$line-h--lg: 18px;
$line-h--l2: 20px;
$line-h--l3: 22px;
$line-h--l4: 24px;
$line-h--l5: 28px;
$line-h--l6: 32px;
$line-h--l7: 45px;
