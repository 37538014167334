@import 'src/styles/v2/all.scss';

.component {
  width: 100%;
  max-width: $modal--w--sm;
  color: $c-modal--fg;
  background-color: $c-modal--bg;
  border-radius: $border--r--xl;
  box-shadow: $box-shadow--panel;
  overflow: hidden;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .header {
    padding: $gap--sm $gap--md;
  }

  .body {
    padding: $gap--sm $gap--md;
  }

  .footer {
    position: relative;
    padding: $gap--sm $gap--md;
    display: flex;
    justify-content: flex-end;
    border-top: $border--q--xs solid $c-modal--bd;
    @include breakpoint(mobile) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .largeVariant {
    &.dialog {
      max-width: $modal--w--lg;
    }
  }

  .smallVariant {
    &.dialog {
      max-width: $modal--w--s2;
    }
  }
}
