@import 'src/styles/v2/all.scss';

.modalContent {
  text-align: center;
}

.title {
  font-family: $font-family--bold;
  font-size: $font-sz--l3;
  line-height: $line-h--l6;
  padding: $gap--lg 0;
  max-width: 80%;
  margin: 0 auto;
}

.subTitle {
  font-size: $font-sz--md;
  line-height: $line-h--l3;
  font-family: $font-family--semi-bold;
  margin-bottom: $gap--lg;
}
