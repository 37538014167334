@import 'src/styles/v2/all.scss';

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: $gap--md;
}

.modalContent {
  text-align: center;
}

.title {
  font-family: $font-family--bold;
  font-size: $font-sz--l3;
  line-height: $line-h--l6;
  padding: $gap--lg 0;
  max-width: 80%;
  margin: 0 auto;
}
