@import 'src/styles/v2/all.scss';

.component {
  background-repeat: no-repeat;
  margin-right: $gap--xs;
  background-position: center;

  &.alertVariant,
  &.arrowDownVariant,
  &.arrowNextVariant,
  &.arrowPrevVariant,
  &.confirmVariant,
  &.checkVariant,
  &.calendarInactiveVariant,
  &.calendarFillVariant,
  &.userCirclePurpleVariant,
  &.policyVariant,
  &.poundInactiveVariant,
  &.pinVariant,
  &.warningVariant,
  &.buildingVariant {
    display: block;
    background-size: 100%;
    margin: 0;
  }

  .arrowPrevVariant {
    background-image: url('~assets/images/icons/prev.svg');
    &.dark {
      filter: brightness(100);
    }
  }

  .arrowNextVariant {
    background-image: url('~assets/images/icons/next.svg');
    &.dark {
      filter: brightness(100);
    }
  }
  &.arrowDownVariant {
    background-image: url('~assets/images/icons/down.svg');
  }

  &.confirmVariant {
    background-image: url('~assets/images/icons/check-circle-green.svg');
  }

  &.alertVariant {
    background-image: url('~assets/images/icons/yellow-triangle.svg');
  }

  &.calendarInactiveVariant {
    background-image: url('~assets/images/icons/calendar-gray.svg');
  }

  &.poundInactiveVariant {
    background-image: url('~assets/images/icons/currency-pound.svg');
    filter: brightness(0.6);
  }

  &.warningVariant {
    background-image: url('~assets/images/icons/warning-red.png');
  }

  &.policyVariant {
    background-image: url('~assets/images/icons/policy-dark.svg');
    &.dark {
      filter: brightness(100);
    }
  }

  &.pinVariant {
    background-image: url('~assets/images/icons/map-pin-black.svg');
    &.dark {
      filter: brightness(100);
    }
  }

  &.buildingVariant {
    background-image: url('~assets/images/icons/building.svg');
    &.dark {
      filter: brightness(100);
    }
  }

  &.calendarVariant {
    @include square($icon-sz--md);
    display: block;
    background-size: 100%;
    background-image: url('~assets/images/icons/calendar-white.svg');
  }

  &.calendarFillVariant {
    background-image: url('~assets/images/icons/calendar-white-selected.svg');
  }

  &.closeVariant {
    @include square($icon-sz--lg);
    background-size: $icon-sz--lg;
    background-image: url('~assets/images/icons/x-close-white.svg');
    margin: 0;

    &.variantInvert {
      filter: brightness(0%);
    }
  }

  &.checkVariant {
    background-image: url('~assets/images/icons/check-circle-dark.svg');
  }

  &.userCirclePurpleVariant {
    background-image: url('~assets/images/icons/user-circle-purple.svg');
    &.dark {
      filter: brightness(100);
    }
  }

  //
  &.smallWidth {
    @include square($icon-sz--md);
  }

  &.mediumWidth {
    @include square($icon-sz--lg);
  }
}

$icon-sizes: (
  s2: $icon-sz--s2,
  xs: $icon-sz--xs,
  sm: $icon-sz--sm,
  md: $icon-sz--md,
  lg: $icon-sz--lg,
  xl: $icon-sz--xl,
  l1: $icon-sz--l1,
  l2: $icon-sz--l2,
  l3: $icon-sz--l3,
);

@each $size-name, $size-value in $icon-sizes {
  .#{$size-name}Width {
    @include square($size-value);
    min-width: $size-value;
  }
}
