@import 'src/styles/v2/all.scss';

$input--w: 100%;

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .inputContainer {
    flex: 1;
    margin: 0 $gap--sm;
    text-align: center;

    &.first {
      margin-left: 0;
    }

    &.last {
      margin-right: 0;
    }
  }

  .input {
    padding: $gap--md $gap--xs;
    text-align: center;
    border: $border--q--sm solid $c-input--slick--bd;
    background-color: $c-input--slick--bg;
    border-radius: $border--r--md;
    width: $input--w;

    @include breakpoint(mobile) {
      max-width: $input--w;
    }
    &:focus {
      border-color: $c-input--slick--focus--bd;
      background-color: $c-input--slick--focus--bg;
    }
  }

  // Hide Arrows From Input Number
  .input::-webkit-outer-spin-button,
  .input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin: $gap--sm 0;
    display: inline-block;
  }

  &.neutral {
    .input {
      &:focus {
        border-color: $c-input--neutral--focus--bd;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .input {
      border-color: $c-input--dark--bd;
      background-color: $c-input--dark--bg;
      color: $c-input--dark--fg;

      &:focus {
        border-color: $c-input--dark--focus--bd;
        background-color: $c-input--dark--focus--bg;
      }
    }

    .error {
      color: $c-alert--dark--fg;
    }
  }
}
