@import 'src/styles/v2/all.scss';

$image--slick: linear-gradient(109deg, #f5e2fe -48%, #faf5fc 99%);
$image--dark: linear-gradient(108deg, #3e0869 0%, #821bb8 99%);

.component {
  margin-top: $gap--md;
  padding-bottom: $footer--h;

  .buttonsContainer {
    display: flex;
    flex-direction: column;
  }

  .waitListButtonContainer {
    display: flex;
    justify-content: center;
    margin: $gap--sm;
  }

  .containerTimeSlot {
    display: flex;
    flex-wrap: wrap;
    margin-top: $gap--lg;
  }

  .title {
    font-size: $font-sz--sm;
    margin-top: $gap--lg;
    text-align: center;
    display: block;
  }

  .availableDaysContainer {
    padding: $gap--md;
    border-radius: $border--r--xl;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
    font-family: $font-family--semi-bold;
    background-image: $image--slick;
    margin-top: $gap--lg;
    text-align: center;

    .text {
      display: inline;
    }
    .textInstructions {
      margin-top: $gap--md;
      display: block;
    }

    .phone {
      color: $c-text--slick--fg;
      text-decoration: underline;
    }
  }

  &.dark {
    .phone {
      color: $c-page--dark--fg;
    }

    .availableDaysContainer {
      background-image: $image--dark;
      color: $c-page--dark--fg;
    }
  }
}
