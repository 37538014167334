@import 'src/styles/v2/all.scss';

$image--slick: linear-gradient(110deg, #af6dd1 -37%, #821bb8 100%);
$image--dark: linear-gradient(110deg, #3e0869 0%, #821bb8 99%);
$image--neutral: linear-gradient(112deg, #505773 0%, #192340 100%);

.component {
  padding-top: $gap--xl;

  .statusIcon {
    @include square($icon-sz--l3);
    background: $pink-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border--r--mx;
    margin: $gap--xl auto $gap--lg;
  }

  .title {
    color: $c-page--slick--fg;
    font-size: $font-sz--l4;
    font-family: $font-family--bold;
    text-align: center;
  }

  .card {
    font-size: $font-sz--md;
    border-radius: $border--r--l3;
    background-image: $image--slick;
    min-height: $card--h--min;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: $c-card--slick--fg;
    max-width: $card--w--max;
    margin: $gap--lg auto;
    &::before,
    &::after {
      content: '';
      position: absolute;
      @include square($icon-sz--l1);
      background: $c-card-shape--slick--bg;
      border-radius: $border--r--mx;
    }
    &::before {
      left: 0;
      margin-left: negative($icon-sz--l1 / 2);
    }
    &::after {
      right: 0;
      margin-right: negative($icon-sz--l1 / 2);
    }
  }

  .code {
    font-family: $font-family--bold;
    font-size: $font-sz--l4;
    margin-top: $gap--sm;
  }

  .sparkleTop {
    position: absolute;
    top: $gap--md;
    left: $gap--md;
  }

  .sparkleBottom {
    position: absolute;
    bottom: $gap--md;
    right: $gap--md;
  }
  .details {
    margin: $gap--md;
  }

  .icon {
    margin-right: $gap--md;
  }

  .subtitle {
    display: flex;
    align-items: center;
    color: $c-page--slick--fg;
    font-size: $font-sz--lg;
    font-family: $font-family--semi-bold;
    padding-bottom: $gap--md;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: $gap--md 0;
    color: $c-page--slick--fg;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
    border-bottom: $border--q--xs solid $white-tertiary;
    &:last-child {
      border-bottom: none;
    }
  }

  .value {
    color: $c-text--fg;
  }

  .block {
    display: block;
    text-align: right;
    text-decoration: underline;
    margin-bottom: $gap--s2;
  }

  .link {
    @include breakpoint(mobile) {
      min-width: 100%;
    }
  }

  &.dark {
    .card {
      background-image: $image--dark;
      &::before,
      &::after {
        background: $c-card-shape--dark--bg;
      }
    }
    .title,
    .subtitle,
    .row {
      color: $c-page--dark--fg;
    }
    .icon,
    .statusIcon img {
      filter: brightness(10);
    }
    .value {
      color: $c-text--dark--fg;
    }
    .statusIcon {
      background: $purple;
    }
  }

  &.neutral {
    .card {
      background-image: $image--neutral;
    }
    .value {
      color: $c-text--neutral--fg;
    }
    .statusIcon {
      background: $silver;
      img {
        filter: brightness(0.7);
      }
    }
  }
}
