// --h = height
// --r = radius
// --w = width
// --q = square, both width and height

// --mn = minimal
// --mx = maximal

// --s3 = extra extra extra small
// --s2 = extra extra small
// --xs = extra small
// --sm = small
// --md = medium
// --lg = large
// --xl = extra large
// --l2 = extra extra large
// --l3 = extra extra extra large

$border--r--mn: 0px;
$border--r--xs: 2px;
$border--r--sm: 3px;
$border--r--md: 4px;
$border--r--lg: 6px;
$border--r--xl: 8px;
$border--r--l2: 10px;
$border--r--l3: 16px;
$border--r--l4: 24px;
$border--r--l5: 32px;
$border--r--l6: 48px;
$border--r--mx: 50%;

$border--q--mn: 0;
$border--q--xs: 1px;
$border--q--sm: 2px;
$border--q--md: 3px;
$border--q--lg: 4px;
$border--q--xl: 5px;
$border--q--l2: 6px;
$border--q--l3: 7px;
$border--q--mx: 8px;
