@import 'src/styles/v2/all.scss';

.component {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family--bold;
  @include square($icon-sz--lg);
  background: $c-toggler--slick--bg;
  border: $border--q--xs solid $c-toggler--slick--bd;
  border-radius: $border--r--md;
  min-width: $icon-sz--lg;
  cursor: pointer;

  &.isSelected {
    background: $c-toggler--slick--selected--bg;
    border-color: $c-toggler--slick--selected--bd;

    &:before {
      content: '';
      @include square(16px);
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('~assets/images/icons/ic_check_white.svg');
    }
  }
  &.circleVariant {
    @include square(28px);
    &.isSelected {
      border: $border--q--sm solid $white;
      border-radius: $border--r--mx;
    }
  }

  &.isDisabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.neutral {
    background: $c-toggler--neutral--bg;
    border-color: $c-toggler--neutral--bd;
    &.isSelected {
      background: $c-toggler--neutral--selected--bg;
      border-color: $c-toggler--neutral--selected--bd;
      &:before {
        color: $c-toggler--neutral--selected--fg;
      }
    }

    &.circleVariant {
      &.isSelected {
        background: transparent;
        border-color: $white;
      }
    }
  }

  &.dark {
    background: $c-toggler--dark--bg;
    border-color: $c-toggler--dark--bd;
    &.isSelected {
      background: transparent;
    }
  }
}
