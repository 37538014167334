@import 'src/styles/v2/all.scss';

.component {
  border-radius: $border--r--xl;
  padding: $gap--sm;
  font-size: $font-sz--xs;
  font-family: $font-family--semi-bold;
  margin: $gap--xs;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.confirmVariant {
    background-color: $c-badge--confirm--bg;
    color: $c-badge--confirm--fg;
  }

  &.defaultVariant {
    background-color: $c-badge--default--bg;
    color: $c-badge--default--fg;
  }
}
