@import 'src/styles/v2/all.scss';

.component {
  position: fixed;
  z-index: $z-footer;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $gap--md;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: $c-footer--slick--bg;
  box-shadow: $box-shadow--footer;
  @include breakpoint(mobile) {
    flex-direction: column;
  }

  &.dark {
    background-color: $c-footer--slick--dark--bg;
    box-shadow: $box-shadow--footer--dark;
  }
}
