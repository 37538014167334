@import 'src/styles/v2/all.scss';

$time--w: 200px;

.component {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: $gap--md 0;
  border: $border--q--xs solid $c-item--slick--bd;
  background-color: $c-item--slick--bg;
  border-radius: $border--r--xl;
  padding: $gap--md;
  font-family: $font-family--semi-bold;

  &.selected {
    border-left: $border--q--lg solid $green--secondary;
  }

  .salonName {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    font-family: $font-family--bold;
  }

  .time {
    color: $c-text-secondary--slick--fg;
    font-family: $font-family--semi-bold;
    font-size: $font-sz--sm;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: $time--w;
  }

  .date {
    margin-bottom: $gap--xs;
  }

  .servicesTotal {
    font-family: $font-family--semi-bold;
    font-size: $font-sz--sm;
    margin: $gap--xs 0 $gap--sm 0;
  }

  .focused {
    color: $c-text--fg;
    margin-bottom: $gap--lg;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .rowButtons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: negative($gap--xs);
    margin-top: $gap--sm;
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .dayTime {
    padding: $gap--sm 0;
  }

  .startTime {
    color: $c-text-secondary--slick--fg;
    font-family: $font-family--semi-bold;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
  }

  .service {
    font-family: $font-family--semi-bold;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
  }
  .stylist {
    color: $c-text-secondary--slick--fg;
    font-family: $font-family--semi-bold;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
  }

  .price {
    color: $c-text--fg;
    font-family: $font-family--semi-bold;
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
  }
  .buttonFont {
    font-size: $font-sz--sm;
  }

  .underline {
    text-decoration: underline;
  }

  &.neutral {
    //
  }

  &.dark {
    background-color: $c-item--dark--bg;

    .focused,
    .price {
      color: $c-text--dark--fg;
    }
  }
}

.modalContent {
  text-align: center;
}

.title {
  font-family: $font-family--bold;
  font-size: $font-sz--l3;
  line-height: $line-h--l6;
  padding: $gap--lg 0;
  max-width: 80%;
  margin: 0 auto;
}

.subTitle {
  font-size: $font-sz--md;
  line-height: $line-h--l3;
  font-family: $font-family--regular;
  margin-bottom: $gap--lg;
}

.textBold {
  font-family: $font-family--semi-bold;
}
