@import 'src/styles/v2/all.scss';

$svg-size--sm: 24px;
$svg-size--md: 32px;

.component {
  display: grid;
  justify-content: center;
  align-items: center;

  &.overlayVariant {
    background-color: $c-spiner--slick--bg;
    position: fixed;
    @include square(100%);
    left: 0;
    bottom: 0;
    z-index: 5;
  }

  .svg {
    animation: rotate 2s linear infinite;
    width: $svg-size--md;
    height: $svg-size--md;
  }

  .circle {
    stroke: $c-spiner--slick--bd;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  &.neutral {
    .circle {
      stroke: $c-spiner--neutral--bd;
    }
    &.overlayVariant {
      background-color: $c-spiner--neutral--bg;
      .circle {
        stroke: $c-spiner--neutral--bd;
      }
    }
  }
  &.dark {
    .circle {
      stroke: $c-spiner--dark--bd;
    }

    &.overlayVariant {
      background-color: $c-spiner--dark--bg;
      .circle {
        stroke: $c-spiner--dark--bd;
      }
    }
  }
}
