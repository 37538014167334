@import 'src/styles/v2/all.scss';

.component {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .arrowGoBack {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    border: $border--q--xs solid $c-item--slick--bd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &.dark {
    .arrowImg {
      filter: brightness(10);
    }

    .arrowGoBack {
      border: $border--q--xs solid $c-item--dark--bd;
    }
  }
}
