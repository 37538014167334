@import 'src/styles/v2/all.scss';

.component {
  font-family: 'ProximaNova-b';
  position: relative;
  width: calc(50% - #{$gap--md});
  min-height: $ncob-item--w;
  margin: $gap--sm;
  padding: $gap--sm;
  background-color: $c-page--slick--bg;
  border-bottom: 1px solid $c-page--slick--bd;

  @include breakpoint(mobile) {
    flex-basis: 100%;
    margin: $gap--sm 0;
    padding-bottom: $gap--md;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .avatar {
    @include square($icon-sz--l3);
    min-width: $icon-sz--l3;
    background: $pink-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border--r--mx;
    margin-right: $gap--md;
    box-shadow: inset 0 0 0 $border--q--sm $c-page--slick--bg;
    border: $border--q--sm solid $pink-primary;
    color: $c-text--slick--fg;
  }

  .iconUser {
    filter: brightness(0.7);
  }

  .info {
    display: flex;
    flex-direction: column;
    font-size: $font-sz--sm;
    font-family: $font-family--semi-bold;
    color: $c-text--slick--fg;
    padding-right: $gap--sm;
  }

  .name {
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .role {
    color: $white-tertiary;
    margin-top: $gap--xs;
  }

  .price {
    margin-top: $gap--xs;
    color: $c-text--fg;
  }

  .status {
    display: flex;
    align-items: flex-end;
    margin-bottom: $gap--xs;
  }

  &.neutral {
    .avatar {
      background: $silver;
      box-shadow: inset 0 0 0 $border--q--sm $c-page--neutral--bg;
      border: $border--q--sm solid $silver;
    }

    .price {
      color: $c-text--neutral--fg;
    }
  }

  &.dark {
    background-color: $c-page--dark--bg;
    border-color: $c-page--dark--bd;
    .info {
      color: $c-page--dark--fg;
    }
    .price {
      color: $c-text--dark--fg;
    }

    .avatar {
      background: $pink-primary;
      box-shadow: inset 0 0 0 $border--q--sm $c-page--dark--bg;
      border: $border--q--sm solid $pink-primary;
    }
    .iconUser {
      filter: none;
    }
  }
}
