@import 'src/styles/v2/functions/gap.scss';
@import 'src/styles/v2/size/border.scss';

// NOTE: the single letter shortcuts are
// --h = height
// --r = radius
// --w = width
// --q = square, both width and height

$app-wrap--w: 740px;
$page--h: 100vh;
$navbar--h: 64px;

$header-gradient--h: 88px;

$header-logo--h: 100px;
$header-logo--h--min: 90px;

$button--h--xs: 30px;
$button--h--sm: 36px;
$button--h--md: 40px;
$button--h--lg: 48px;

$button--w--xs: 16px;
$button--w--sm: 40px;
$button--w--min: 240px;

//
$card--w--max: 340px;
$card--h--min: 140px;

$ncob-item--w: 74px;

$textarea--h--min: 48px;
$textarea--h--max: 180px;

$modal--w--s2: 320px;
$modal--w--xs: 480px;
$modal--w--sm: 540px; // default
$modal--w--md: 770px;
$modal--w--lg: 880px;

$footer--h: 124px;
