@mixin breakpoint($class) {
  @if $class == xs2 {
    @media screen and (max-width: 280px) {
      @content;
    }
  } @else if $class == xs {
    @media screen and (max-width: 320px) {
      @content;
    }
  } @else if $class == mobile-sm {
    @media screen and (max-width: 480px) {
      @content;
    }
  } @else if $class == mobile {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $class == tablet-p {
    @media screen and (max-width: 800px) {
      @content;
    }
  } @else if $class == tablet-l {
    @media screen and (max-width: 1024px) {
      @content;
    }
  } @else if $class == md {
    @media screen and (max-width: 1366px),
    screen and (max-width: 1280px),
    screen and (max-width: 1366px) {
      @content;
    }
  } @else if $class == lg {
    @media screen and (max-width: 1280px) {
      @content;
    }
  }
    /* iphone 6+, 6s+, 7+, 8+  */
  @else if $class == ip8Plus {
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
      @content;
    }
  }
}

.mq {
  font-family: 'Avalon';
  position: fixed;
  z-index: 999;
  top: 120px;
  display: none;
  background: #fff;

  @include breakpoint(lg) {
    background: rgb(209, 59, 209);
  }

  @include breakpoint(md) {
    background: orange;
  }

  @include breakpoint(tablet-l) {
    background: green;
  }

  @include breakpoint(tablet-p) {
    background: #333;
  }
  @include breakpoint(ip8Plus) {
    background: orangered;
  }
  @include breakpoint(mobile) {
    background: red;
  }
  @include breakpoint(mobile-sm) {
    background: black;
  }
  @include breakpoint(xs) {
    background: rgb(231, 255, 144);
  }
  @include breakpoint(xs2) {
    background: rgb(223, 20, 155);
  }
}

.hide-on-mobile {
  display: block;
  @include breakpoint(mobile) {
    display: none;
  }
}

.show-on-mobile {
  display: none;
  @include breakpoint(mobile) {
    display: block;
  }
}
