@import 'src/styles/v2/all.scss';

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;

  .input {
    padding: $gap--md;
    border: $border--q--sm solid $c-input--slick--bd;
    background-color: $c-input--slick--bg;
    width: 100%;
    border-radius: $border--r--md;

    &:focus {
      border-color: $c-input--slick--focus--bd;
      background-color: $c-input--slick--focus--bg;
    }
    &.hasError {
      border-color: $c-input--slick--error--bd;
    }
    &::placeholder {
      font-size: $font-sz--md;
    }
  }

  .inputContainer {
    margin-top: $gap--md;
  }

  .error {
    color: $c-alert--slick--fg;
    font-size: $line-h--sm;
    margin: $gap--sm 0;
    display: inline-block;
  }

  .inputLabel {
    display: block;
    margin-bottom: $gap--sm;
    font-size: $font-sz--md;
    font-family: $font-family--bold;
  }

  .passwordContainer {
    position: relative;
  }

  .eyeContainer {
    position: absolute;
    right: $gap--md;
    top: $gap--md;
    margin-top: $gap--s2;
  }

  .eyeOpen,
  .eyeClose {
    @include square(20px);
    display: block;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  .eyeOpen {
    background-image: url('~assets/images/icons/eye-open.svg');
  }

  .eyeClose {
    background-image: url('~assets/images/icons/eye-close.svg');
  }

  &.neutral {
    input {
      &:focus {
        border-color: $c-input--neutral--focus--bd;
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .eyeOpen,
    .eyeClose {
      filter: invert(1);
    }

    .error {
      color: $c-alert--dark--fg;
    }

    input {
      border-color: $c-input--dark--bd;
      background-color: $c-input--dark--bg;
      color: $c-input--dark--fg;

      &:focus {
        border-color: $c-input--dark--focus--bd;
        background-color: $c-input--dark--focus--bg;
      }

      &.hasError {
        border-color: $c-input--dark--error--bd;
      }
    }
  }
}
