@import 'src/styles/v2/color/raw.scss';

// PREFIX:
// $c- = color

// MODIFIERS:
// --bd = border
// --bg = background
// --fg = foreground

// alert colors
$c-text--fg: $purple-secondary;
$c-text--slick--fg: $blue-tertiary;
$c-text--neutral--fg: $blue-tertiary;
$c-text--dark--fg: $pink-secondary;
$c-title--fg: $blue-tertiary;

$c-text-secondary--slick--fg: $silver-primary;
$c-text-secondary--neutral--fg: $silver-primary;
$c-text-secondary--dark--fg: $silver-primary;

$c-text-tertiary--slick--fg: $gray-quinary;
$c-page-focused-fg: $purple;

// THE THEME COLOR MODE ARE : slick | dark | neutral
// main colors
$c-page--slick--bg: $white;
$c-page--slick--bd: $white-quaternary;
$c-page--slick--fg: $blue-tertiary;

$c-page--dark--bg: $gray-quaternary;
$c-page--dark--bd: $silver-primary;
$c-page--dark--fg: $white;

$c-page--neutral--bg: $white;
$c-page--neutral--bd: $white-quaternary;
$c-page--neutral--fg: $blue-tertiary;

// alert
$c-alert--slick--fg: $red-primary;
$c-alert--dark--fg: $red-quaternary;
$c-alert--neutral--fg: $red-tertiary;

// navigation
$c-nav--slick--bg: $blue-primary;
$c-nav--slick--bd: $blue-primary;
$c-nav--slick--fg: $white;
$c-nav--slick--focus--bd: $white;

$c-nav--neutral--bg: $blue-tertiary;
$c-nav--neutral--bd: $blue-tertiary;

$c-nav--dark--bg: $blue-secondary;
$c-nav--dark--bd: $blue-secondary;
$c-nav--dark--fg: $white;
$c-nav--dark--focus--bd: $white;

// input
$c-input--slick--bd: $white-secondary;
$c-input--slick--bg: $white-secondary;
$c-input--slick--fg: $blue-tertiary;
$c-input--slick--focus--bd: $purple-primary;
$c-input--slick--focus--bg: $white;
$c-input--slick--error--bd: $red-primary;

$c-input--neutral--bd: $white-secondary;
$c-input--neutral--bg: $white-secondary;
$c-input--neutral--fg: $blue-tertiary;
$c-input--neutral--focus--bd: $blue-tertiary;
$c-input--neutral--focus--bg: $white;
$c-input--neutral--error--bd: $red-primary;

$c-input--dark--bd: $gray-secondary;
$c-input--dark--bg: $gray-secondary;
$c-input--dark--fg: $white;
$c-input--dark--focus--bd: $pink-secondary;
$c-input--dark--focus--bg: $gray-quinary;
$c-input--dark--error--bd: $red-primary;

// pannel
$c-pannel--slick--bd: $white;
$c-pannel--slick--bg: $white;
$c-pannel--slick--fg: $blue-tertiary;

$c-pannel--neutral--bd: $white;
$c-pannel--neutral--bg: $white;
$c-pannel--neutral--fg: $blue-tertiary;

$c-pannel--dark--bd: $gray-quaternary;
$c-pannel--dark--bg: $gray-quaternary;
$c-pannel--dark--fg: $white;

// header
$c-header--slick--bd: $white;
$c-header--neutral--bd: $white;
$c-header--dark--bd: $gray-quinary;
// button
$c-button--primary--bd: $purple;
$c-button--primary--bg: $purple;
$c-button--primary--fg: $white;

$c-button--primary--disabled--bd: $silver-tertiary;
$c-button--primary--disabled--bg: $silver-tertiary;
$c-button--primary--disabled--fg: $white;

$c-button--primary--disabled--dark--bd: $gray;
$c-button--primary--disabled--dark--bg: $gray;
$c-button--primary--disabled--dark--fg: $silver-primary;

$c-button--secondary--bd: $purple;
$c-button--secondary--bg: transparent;
$c-button--secondary--fg: $purple;

$c-button--secondary--disabled--bd: $white-tertiary;
$c-button--secondary--disabled--bg: $white-tertiary;
$c-button--secondary--disabled--fg: $white;

$c-button--tertiary--bd: transparent;
$c-button--tertiary--bg: transparent;
$c-button--tertiary--fg: $purple;

$c-button--tertiary--disabled--bd: transparent;
$c-button--tertiary--disabled--bg: transparent;
$c-button--tertiary--disabled--fg: $white-tertiary;

$c-button--quaternary--bd: $white-quaternary;
$c-button--quaternary--bg: $white;
$c-button--quaternary--fg: $blue-tertiary;

$c-button--quaternary--dark--bd: $silver-primary;
$c-button--quaternary--dark--bg: transparent;
$c-button--quaternary--dark--fg: $white;

$c-button--warning--fg: $red-primary;

$c-button--error--bg: $red-tertiary;
$c-button--error--bd: $red-tertiary;
$c-button--error--fg: $white;

// button theme mode
$c-button--primary--neutral--bd: $blue-tertiary;
$c-button--primary--neutral--bg: $blue-tertiary;
$c-button--primary--neutral--fg: $white;

$c-button--secondary--neutral--bd: $blue-tertiary; // outline
$c-button--secondary--neutral--bg: transparent;
$c-button--secondary--neutral--fg: $blue-tertiary;

$c-button--secondary--dark--bd: $pink-secondary; // outline // primary is same as defauls slick mode
$c-button--secondary--dark--bg: transparent;
$c-button--secondary--dark--fg: $pink-secondary;

$c-button--tertiary--dark--bd: transparent;
$c-button--tertiary--dark--bg: transparent;
$c-button--tertiary--dark--fg: $pink-secondary;

$c-button--warning--dark--fg: $red-quaternary;

// footer
$c-footer--slick--bd: $white-tertiary;
$c-footer--slick--bg: $white;
$c-footer--slick--fg: $blue-tertiary;

$c-footer--slick--dark--bg: $gray-quaternary;
// voucher card
$c-card--slick--fg: $white;
$c-card-shape--slick--bg: $white;
$c-card-shape--neutral--bg: $white;
$c-card-shape--dark--bg: $gray-quaternary;

//toggle, checkbox

$c-toggler--slick--bd: $purple;
$c-toggler--slick--bg: $white;
$c-toggler--slick--fg: $purple;

$c-toggler--slick--selected--bd: $purple;
$c-toggler--slick--selected--bg: $purple;
$c-toggler--slick--selected--fg: $white;

$c-toggler--neutral--bd: $blue-tertiary;
$c-toggler--neutral--bg: $white;
$c-toggler--neutral--fg: $blue-tertiary;

$c-toggler--neutral--selected--bd: $blue-tertiary;
$c-toggler--neutral--selected--bg: $blue-tertiary;
$c-toggler--neutral--selected--fg: $white;

$c-toggler--dark--bd: $pink-secondary;
$c-toggler--dark--bg: transparent;
$c-toggler--dark--fg: $pink-secondary;

$c-toggler--dark--selected--bd: $pink-secondary;
$c-toggler--dark--selected--bg: $pink-secondary;
$c-toggler--dark--selected--fg: $gray-quaternary;

// clickable div  button
$c-item--slick--bd: $silver-primary;
$c-item--slick--bg: $white;
$c-item--slick--fg: $blue-tertiary;

$c-item--slick--selected--bd: $purple;
$c-item--slick--selected--bg: $white;
$c-item--slick--selected--fg: $blue-tertiary;

$c-item--neutral--bd: $silver-primary;
$c-item--neutral--bg: $white;
$c-item--neutral--fg: $blue-tertiary;

$c-item--neutral--selected--bd: $blue-tertiary;
$c-item--neutral--selected--bg: $white;
$c-item--neutral--selected--fg: $blue-tertiary;

$c-item--dark--bd: $silver-primary;
$c-item--dark--bg: $gray-quaternary;
$c-item--dark--fg: $white;

$c-item--dark--selected--bd: $pink-secondary;
$c-item--dark--selected--bg: $gray-quaternary;
$c-item--dark--selected--fg: $white;

// count label
$c-label--slick--bg: $purple;
$c-label--slick--fg: $white;

$c-label--neutral--bg: $blue-tertiary;
$c-label--neutral--fg: $white;

$c-label--dark--bg: $silver-secondary;
$c-label--dark--fg: $white;

$c-label--dark--selected--bg: $pink-secondary;
$c-label--dark--selected--fg: $gray-quaternary;

// spiner
$c-spiner--slick--bg: $white;
$c-spiner--slick--bd: $purple;

$c-spiner--neutral--bg: $white;
$c-spiner--neutral--bd: $blue-tertiary;

$c-spiner--dark--bg: $gray-quaternary;
$c-spiner--dark--bd: $pink-secondary;

$c-avatar--slick--bd: $purple;
$c-avatar--slick--bg: linear-gradient(132deg, #af6dd1 -32%, #821bb8 100%);
$c-avatar--slick--fg: $white;

$c-avatar--neutral--bd: $blue-tertiary;
$c-avatar--neutral--bg: linear-gradient(132deg, #af6dd1 -32%, #192340 100%);
$c-avatar--neutral--fg: $white;

$c-avatar--dark--bd: $pink-primary;
$c-avatar--dark--bg: linear-gradient(132deg, #e3bff5 -32%, #e3bff5 100%);
$c-avatar--dark--fg: $blue-tertiary;

// tab buttons
$c-tab--slick--bd: $white;
$c-tab--slick--bg: $white;
$c-tab--slick--fg: $blue-tertiary;

$c-tab--slick--selected--bd: $purple;
$c-tab--slick--selected--bg: $pink-primary;
$c-tab--slick--selected--fg: $blue-tertiary;

$c-tab--neutral--bd: $white;
$c-tab--neutral--bg: $white;
$c-tab--neutral--fg: $blue-tertiary;

$c-tab--neutral--selected--bd: $blue-tertiary;
$c-tab--neutral--selected--bg: $silver-quaternary;
$c-tab--neutral--selected--fg: $blue-tertiary;

$c-tab--dark--bd: $gray-quaternary;
$c-tab--dark--bg: $gray-quaternary;
$c-tab--dark--fg: $white;

$c-tab--dark--selected--bd: $purple;
$c-tab--dark--selected--bg: $purple;
$c-tab--dark--selected--fg: $white;
// modal
$c-modal--bg: $white;
$c-modal--bd: $white-quaternary;
$c-modal--fg: $blue-tertiary;

$c-modal-alert-icon--bg: $yellow--primary;
$c-modal-success-icon--bg: $green--primary;
$c-modal-info-icon--bg: $purple-quinary;
$c-modal-warning-icon--bg: $red-quinary;
$c-modal-inactive-icon--bg: $silver;
$c-modal-inactive-icon--dark--bg: $silver-secondary;

// count label
$c-count--bg: $silver-primary;
$c-count--bd: $silver-primary;
$c-count--fg: $white;
$c-count--selected--bg: $purple;
$c-count--selected--bd: $purple;
$c-count--neutral--selected--bg: $blue-tertiary;
$c-count--dark--selected--bg: $blue-secondary;

// badge
$c-badge--confirm--bg: $green--secondary;
$c-badge--confirm--bd: $green--secondary;
$c-badge--confirm--fg: $blue-tertiary;

$c-badge--default--bg: $pink-secondary;
$c-badge--default--bd: $pink-secondary;
$c-badge--default--fg: $blue-tertiary;

// time slider
$c-slider--slick--bd: $white-quaternary;
$c-slider--slick--bg: $white;
$c-slider--slick--fg: $blue-tertiary;

$c-slider--slick--selected--bd: $purple;
$c-slider--slick--selected--bg: $purple;
$c-slider--slick--selected--fg: $white;

$c-slider--neutral--bd: $white-quaternary;
$c-slider--neutral--bg: $white;
$c-slider--neutral--fg: $blue-tertiary;

$c-slider--neutral--selected--bd: $blue-tertiary;
$c-slider--neutral--selected--bg: $blue-tertiary;
$c-slider--neutral--selected--fg: $white;

$c-slider--dark--bd: $silver-primary;
$c-slider--dark--bg: $gray-quaternary;
$c-slider--dark--fg: $white;

$c-slider--dark--selected--bd: $pink-secondary;
$c-slider--dark--selected--bg: $pink-secondary;
$c-slider--dark--selected--fg: $gray-quaternary;
