@import 'src/styles/v2/all.scss';

.component {
  background: $c-page--slick--bg;
  color: $c-page--slick--fg;

  .slickItem {
    text-align: center;

    &.selected {
      .circle {
        background-color: $c-slider--slick--selected--bg;
        border-color: $c-slider--slick--selected--bd;
        color: $c-slider--slick--selected--fg;
      }
      .dayText {
        color: $purple;
        font-family: $font-family--semi-bold;
      }
    }

    &.disabled {
      .circle,
      .dayNumber,
      .dayText {
        opacity: 0.4;
      }
    }

    .circle {
      margin: 0 auto $gap--xs;
      @include square(48px);
      border-radius: $border--r--mx;
      display: flex;
      justify-content: center;
      align-items: center;
      border: $border--q--xs solid $c-slider--slick--bd;
      color: $c-slider--slick--fg;
    }

    .dayNumber {
      font-size: $font-sz--lg;
      line-height: $line-h--l4;
      font-family: $font-family--bold;
    }

    .dayText {
      font-size: $font-sz--xs;
      line-height: $line-h--md;
      font-family: $font-family--regular;
      color: $c-slider--slick--fg;
    }

    .availability {
      font-size: $font-sz--s2;
      line-height: $line-h--sm;
      font-family: $font-family--regular;
      white-space: nowrap;
      &.disabled {
        color: $red-tertiary;
      }
    }
  }

  &.neutral {
    .slickItem {
      &.selected {
        .circle {
          background-color: $c-slider--neutral--selected--bg;
          border-color: $c-slider--neutral--selected--bd;
          color: $c-slider--neutral--selected--fg;
        }

        .dayText {
          color: $c-page--neutral--fg;
        }
      }
    }
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;

    .slickItem {
      .circle {
        color: $c-slider--dark--fg;
      }
      .dayText {
        color: $c-page--dark--fg;
      }

      &.selected {
        .circle {
          background-color: $c-slider--dark--selected--bg;
          border-color: $c-slider--dark--selected--bd;
          color: $c-slider--dark--selected--fg;
        }

        .dayText,
        .availability {
          color: $pink-secondary;
        }
      }
      .availability {
        &.disabled {
          color: $red-quaternary;
        }
      }
    }
  }
}
