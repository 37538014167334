@import 'modules/base.scss';

.conditional {
  padding: 20px 0px 0px; //Top padding
}

.authenticate .conditional {
  padding: 0px 0px 0px; //Authenticate Top padding
}

.conditional-ico {
  width: 64px;
  height: 64px;
  margin: 20px auto 20px;
}

.conditional-img {
  width: 132px;
  height: 132px;
  margin: 16px auto 16px;
}

.section {
  font-size: 24px;
  font-weight: 500;
  margin: 0px 15px;
  padding: 36px 0px;
  text-align: center;
  color: white;
}

@media screen and (min-width: 510px) {
  .section {
    font-size: 30px;
    margin: 0px auto;
    padding: 72px 0px;
  }
}

.container {
  box-sizing: border-box;
  max-width: 480px;
  margin: 0px 15px;
  padding: 35px 24px 44px;
  //  background-image: url('src/assets/images/footer-wave.png');
  // background-size: 100%;
  // background-position: bottom left;
  border-radius: 6px;
  background-color: white;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 510px) {
  .container {
    margin: 0px auto;
    padding: 48px 40px 44px;
  }
}

.header-wrap {
  width: 100%;
  height: 48px;
  background-color: white;
}

.text-salon {
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  overflow: hidden;
  padding: 0px 56px 0px 16px;
  text-align: left;
  white-space: nowrap;
  /* Required for text-overflow to do anything */
  text-overflow: ellipsis;
  color: black; //$header-text;
}

//Header - Call (phone) icon
.call::before {
  font-family: $icon-font;
  font-size: 24px;
  color: $brand-secondary;
}

.call {
  line-height: 48px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  width: 48px;
  height: 48px;
  text-align: center;
  text-decoration: none;
}

//Text heading

.info-text {
  margin: 0px 0px 20px 0px;
}

.text-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0px 0px 8px 0px;
  text-align: center;
  color: $info-text-head;
}

//Generic text subheading
.text-subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  color: $info-text-sub;
}

.conditional .text-subheader {
  color: $brand-secondary;
}

//Forms and simple buttons - need to add hover styles etc

.simple-button,
form .simple-button {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  display: block;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0px auto 0px;
  padding: 15px 32px 15px 32px;
  text-align: center;
  color: $button-text;
  border-width: 1px;
  border-style: solid;
  border-color: #933cc1;
  border-radius: 28px;
  background-color: #933cc1; // $button-bg;
}

//login specific
.login .simple-button,
.login form .simple-button {
  width: 60px;
  height: 60px;
  padding: 0px 16px;
}

.ico-sml {
  display: block;
  width: 24px;
  height: 24px;
  text-align: center;
}

//Salon-list specific
.salon-list .simple-button,
.salon-list form .simple-button {
  width: 100%;
  margin: 0px 0px 12px;
  border-radius: 6px;
}

//Appointment-time specific
.appointment-time .simple-button,
.appointment-time form .simple-button {
  width: 100%;
  margin: 0px;
}

a.simple-button,
form a.simple-button {
  padding: 9px 16px 7px 16px; //Sorts padding on <a> link buttons
}

.simple-button .text-header {
  color: $button-text;
}

.simple-button .text-subheader {
  text-align: center;
  color: $button-text;
}

.lesser-button,
form .lesser-button {
  background-color: $lesser-button-bg;
}

.block-input-text,
.block-input-textarea {
  font-size: 16px;
  font-weight: 400;
  line-height: 56px;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  margin: 0px 0px 20px 0px;
  padding: 0px 12px;
  color: $input-text;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background-color: #f3f3f3;
}

.block-input-textarea {
  line-height: 1.3;
  height: 80px;
  max-height: 80px;
  margin: 0px 0px 20px 0px !important;
  padding: 8px 12px !important;
}

.block-input-text::-webkit-input-placeholder,
.block-input-textarea::-webkit-input-placeholder {
  color: rgba(10, 21, 20, 0.4);
}

//List items/buttons - need to add hover styles etc
.service-list-button {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0px 0px 12px;
  padding: 8px 12px 12px 12px;
  border-width: 1px;
  border-style: solid;
  border-color: $button-bdr;
  border-radius: 6px;
  background-color: $service-button-bg;
}

//Stylist name and price text
.text-stylist,
.text-price {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-transform: capitalize;
  color: $service-button-text;
}

//Stylist name can wrap onto multiline
.text-stylist {
  padding: 0px 48px 0px 0px;
  text-align: left;
}

//Price locked to top right
.text-price {
  position: absolute;
  top: 8px;
  right: 12px;
  padding: 0px;
  text-align: right;
}

//Service text
.text-service {
  font-size: 16px;
  font-weight: 500;

  line-height: 19px;
  padding-top: 4px;
  text-align: left;
  color: $service-button-text;
}

//date text - no longer required
.text-date {
  font-size: 12px;
  font-weight: 300;
  display: none;
  padding-top: 4px;
  text-align: right;
  color: $service-button-text-date;
}

//Service summary - full width with arrow

.service-list-full {
  position: relative;
  box-sizing: border-box;
  margin: -35px -24px 20px;
  padding: 16px 20px;
  border-radius: 6px 6px 0px 0px;
  background-color: $service-summary-bg;
}

@media screen and (min-width: 510px) {
  .service-list-full {
    margin: -48px -40px 20px;
    padding: 24px 40px;
  }
}

.service-list-full .text-service {
  color: $service-summary-text;
}

.service-list-full .text-stylist {
  color: $service-summary-text;
}

.service-list-full .text-price {
  //Re-posotion for increased padding on full width
  top: 16px;
  right: 20px;
  color: $service-summary-text;
}

@media screen and (min-width: 510px) {
  .service-list-full .text-price {
    //Re-posotion for increased padding on full width
    top: 24px;
    right: 40px;
  }
}

//Info well - full width background

.info-text-full {
  padding: 12px 16px 12px 16px;
  background-color: $info-well-full-bg;
}

//Time picker - full width background

.time-picker-full {
  position: relative;
  margin: 0px -20px !important;
  padding: 0px 48px;
}

//Arrows icon
.arrow-button::before {
  font-family: $icon-font;
  font-size: 32px;
  box-sizing: border-box;
  color: $arrow-button-icon;
  border-width: 0px;
  border-style: solid;
  border-color: $arrow-button-bdr;
  border-radius: 16px;
  background-color: $arrow-button-bg;
}

.arrow-button {
  line-height: 56px;
  position: absolute;
  top: 0px;
  display: block;
  width: 48px;
  height: 56px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.time-picker-full .ico-chevron-left {
  left: 0px;
}

.time-picker-full .ico-chevron-right {
  right: 0px;
}

.time-picker-columns {
  position: relative;
  padding: 0px 0px 0px 0px;
}

//Left, center, right columns
.time-picker-columns .left-column,
.time-picker-columns .center-column,
.time-picker-columns .right-column {
  padding: 8px 0px 16px 0px;
  text-align: center;
}

.left-column {
  float: left;
  width: 33%;
}

.center-column {
  float: left;
  width: 34%;
}

.right-column {
  float: left;
  width: 33%;
}

.date-header {
  font-size: 16px;
  font-weight: 500;

  height: 40px;
  color: $date-text;
}

.date-header span {
  font-weight: 500;
  display: block;
}

.left-column .date-header {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $date-text-divider;
}

.center-column .date-header {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $date-text-divider;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: $date-text-divider;
}

.right-column .date-header {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: $date-text-divider;
}

.time-picker-full .button-wrap {
  display: block;
  padding: 0px 4px;
}

.time-picker-full .simple-button {
  font-size: 16px;
  line-height: 32px;
  box-sizing: border-box;
  min-height: 32px;
  margin: 8px 0px 0px 0px;
  padding: 0px 4px;
}

//Fully booked day message in mini calendar
.date-full {
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  min-height: 32px;
  margin: 8px 0px;
  color: $date-full;
}

//summary - full width background

.summary-text-full {
  box-sizing: border-box;
  margin: -35px -24px 20px;
  padding: 16px 20px;
  background-color: $summary-confirm-bg;
}

@media screen and (min-width: 510px) {
  .summary-text-full {
    margin: -48px -40px 20px;
    padding: 24px 40px;
  }
}

.text-summary {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: $summary-confirm-text;
}

.text-summary span {
  font-weight: 500;
  color: $summary-confirm-hi-text;
}

//Inline supplemental text
.text-supplemental {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  display: block;

  padding: 0px 16px 0px 16px;
  text-align: center;
  color: $supplemental-text;
}

.text-supplemental a {
  font-weight: 500;
  text-decoration: underline;
  //
  color: $supplemental-text-link;
}

//Footer
.footer-wrap {
  max-width: 480px;
  margin: 32px 15px 0px;
  text-align: center;
}

@media screen and (min-width: 510px) {
  .footer-wrap {
    margin: 32px auto 0px;
  }
}

.footer-wrap {
}

//Confirm and success specific
.confirm .footer-wrap,
.success .footer-wrap {
  bottom: 0px !important;
}

//Footer text - need to add hover styles etc

.footer-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0px 24px;
  text-align: center;
  text-decoration: underline;
  color: $footer-info-text;
}

.footer-text:first-child {
  float: left;
}

.footer-text:last-child {
  float: right;
}

//Two button - make sure they are in their own .margin-width-wrap of .full-width-wrap to clear floats.

.left-button,
.right-button {
  float: left;
  width: 50%;
}

.left-button .button-wrap {
  display: block;
  padding: 0px 8px 0px 0px;
}

.right-button .button-wrap {
  display: block;
  padding: 0px 0px 0px 8px;
}

//Loading/Confirming

.loading-anim {
  position: relative;
  width: 88px;
  height: 88px;
  margin: 20px auto 20px auto;
}

.loading-anim::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background: url(~assets/images/loading-anim.gif) no-repeat;
  background-size: 88px 88px; //100
}

//Success

.success-badge {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0px auto 20px;
  text-align: center;
}

//New styles for  summary screen
.summary-icon {
  position: absolute;
  top: 12px;
  left: 24px;
  display: block;
  width: 24px;
  height: 24px;
}

.summary-wrap {
  position: relative;
  box-sizing: border-box;
  width: 242px;
  min-height: 48px;
  margin: 0px auto;
  padding: 14px 12px 0px 64px;
}

.summary-wrap span {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

//Add screen specific padding
.summary-wrap:last-child {
  margin: 0px auto 20px;
}

//Low Availability Contact toast
//
.toast {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  z-index: 100;
  top: 48px;
  left: 0px;
  box-sizing: border-box;
  width: 100%;
  padding: 44px 56px;
  animation: 10s ease-in-out 0s 1 forwards slide-in-out; //Trigger animation
  color: white;
  background-color: $brand-secondary;
}

.toast .text-header,
.toast .text-subheader {
  color: white; //Override text colour
}

//Arrow - pointing to phone icon
.toast .arrow-up {
  position: absolute;
  z-index: 101;
  top: 0px;
  right: 16px;
  width: 0;
  height: 0;
  margin: -6px 0px 0px -6px;
  border-right: 6px solid transparent;
  border-bottom-width: 6px;
  border-bottom-style: solid;
  border-bottom-color: $brand-secondary;
  border-left: 6px solid transparent;
}

// Animation - Contact toast
@keyframes slide-in-out {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  6% {
    transform: translateY(-15px);
    opacity: 0;
  }
  12% {
    transform: translateY(0px);
    opacity: 1;
  }
  88% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 0;
  }
}
