// --mn = minimal
// --mx = maximal

// --s3 = extra extra extra small
// --s2 = extra extra small
// --xs = extra small
// --sm = small
// --md = medium
// --lg = large
// --xl = extra large
// --l2 = extra extra large
// --l3 = extra extra extra large

$gap--mn: 0;
$gap--s2: 2px;
$gap--xs: 4px;
$gap--sm: 8px;
$gap--md: 16px;
$gap--lg: 24px;
$gap--xl: 32px;
$gap--l2: 48px;
$gap--l3: 64px;
