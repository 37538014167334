@import 'src/styles/v2/all.scss';

.component {
  position: fixed;
  top: $navbar--h;
  background: $c-page--slick--bg;
  bottom: 0;
  left: 0;
  right: 0;
  color: $c-page--slick--fg;
  z-index: $z-cancel-appointment;
  overflow: auto;

  .wrapper {
    max-width: $app-wrap--w;
    text-align: left;
    margin: 0 auto;
    padding: $gap--md;
    @include breakpoint(mobile) {
      min-height: $page--h;
      padding-bottom: $footer--h;
    }
  }

  .header {
    font-family: $font-family--bold;
    font-size: $font-sz--l4;
    line-height: $line-h--l6;
  }

  .container {
    text-align: center;
  }

  .title {
    font-family: $font-family--bold;
    font-size: $font-sz--lg;
    line-height: $line-h--l4;
    padding: 0;
    margin: 0 auto $gap--md;
  }

  .footerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .link {
    width: 100%;
    text-align: center;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;
  }
}
