@import 'src/styles/v2/all.scss';

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $gap--sm;

  border: $border--q--xs solid $c-item--slick--bd;
  background-color: $c-item--slick--bg;
  border-radius: $border--r--xl;
  padding: $gap--md;
  font-family: $font-family--semi-bold;
  color: $c-item--slick--fg;
  cursor: pointer;

  .flex {
    display: flex;
    align-items: center;
  }

  .circle {
    @include square($icon-sz--l1);
    border-radius: $border--r--mx;
    background-color: $c-modal-inactive-icon--bg;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow {
    @include square($icon-sz--l1);

    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
  }

  .buttonTextContainer {
    margin: 0 $gap--md;
  }

  .buttonText {
    font-size: $font-sz--md;
    line-height: $line-h--l3;
    font-family: $font-family--bold;
  }

  .buttonSubText {
    font-size: $font-sz--sm;
    line-height: $line-h--l2;
    font-family: $font-family--semi-bold;
    color: $silver-primary;
  }

  &.dark {
    background: $c-page--dark--bg;
    color: $c-page--dark--fg;
    .arrowImg {
      filter: brightness(10);
    }
    .circle {
      background-color: $c-modal-inactive-icon--dark--bg;
    }
  }
}
